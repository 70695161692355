import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'joinBy'
})
export class JoinByPipe implements PipeTransform {

    transform(array: any[], separator?: string): any {
        if (!array || !array.length) {
            return null
        }
        separator = separator || ', ';

        return array.join(separator);
    }

}
