import { Component, OnInit } from '@angular/core';
import { FileService } from 'core/file.service';
import { TranslateService } from '@ngx-translate/core';
declare var swal: any;

@Component({
  selector: 'app-regulation-modal',
  templateUrl: './regulation-modal.component.html',
  styleUrls: ['./regulation-modal.component.scss']
})
export class RegulationModalComponent implements OnInit {

  constructor(
    private fileService: FileService,
    private translateService: TranslateService) { }

  ngOnInit(): void {
  }

  handleFileUpload(data) {

    if (this.isValidFile(data.file)) {
      swal
        .fire({
          title: this.translateService.instant('dashboard.summary.rules.question'),
          icon: 'question',
          showCancelButton: true,
          confirmButtonColor: '#d33',
          cancelButtonColor: '#3085d6',
          confirmButtonText: this.translateService.instant('general.yes'),
          cancelButtonText: this.translateService.instant('general.no')
        })
        .then((result: any) => {
          if (result.value) {
            this.fileService.upload(data.file, '/pdf/regulation', data.name).subscribe(res => {

            }, error => {
              swal.fire({
                title: this.translateService.instant('general.error.title'),
                text: 'Errore di file',
                type: 'error',
                confirmButtonText: this.translateService.instant('general.ok'),
                buttonsStyling: false,
                confirmButtonClass: 'm-button m.button--ko',
                timer: 4000,
              });
            });
          }
        });
    } else {
      swal.fire({
        title: this.translateService.instant('general.error.title'),
        text: 'Errore di file',
        type: 'error',
        confirmButtonText: this.translateService.instant('general.ok'),
        buttonsStyling: false,
        confirmButtonClass: 'm-button m.button--ko',
        timer: 4000,
      });
    }

  }

  isValidFile(file: File): boolean {
    return file.type === 'application/pdf';
  }
}
