import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from './core/authentication.service';

@Component({
  selector: 'app-root',
  template: `
    <!--<div toastrContainer></div>-->
    <ngx-spinner
      type="ball-spin-clockwise"
      bdColor="rgba(0,0,0,0.3)"
      size="medium"
      color="#7B2F3F"
      [fullScreen]="true"
    >
    </ngx-spinner>
    <router-outlet></router-outlet>
  `
})
export class AppComponent implements OnInit {
  constructor(translate: TranslateService, private authService: AuthenticationService) {
    translate.setDefaultLang('it');
    translate.use('it');
  }

  ngOnInit() {
    if (!location.href.match(/change-password/i) && !location.href.match(/login/i)) {
      this.authService.setAllowedRouting();
    }
  }
}
