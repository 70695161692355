import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class SettingsService {

    constructor(private http: HttpClient) {
    }

    getCalendarStartDate() {
        return this.http.get(environment.server + "/app/calendarStartDate")
    }

    setCalendarStartDate(newDate) {
        return this.http.put(environment.server + "/app/calendarStartDate", {obj: newDate})
    }
}
