import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss']
})
export class DatepickerComponent implements OnInit, ControlValueAccessor, OnChanges {
  @Input() options;
  @Input() innerClass: string = '';
  @Input() date: any;
  @Input() hideInput: boolean = false;
  @Input() withClear = false;
  @Output() dateChange = new EventEmitter<Date>();

  @ViewChild('dp') bsDatepicker;

  constructor(private bsLocaleService: BsLocaleService) {}

  ngOnInit() {
    this.bsLocaleService.use('it');
  }

  ngOnChanges() {
    if (this.date && typeof this.date === 'string') {
      this.date = new Date(this.date);
    }
  }
  public show() {
    this.bsDatepicker.show();
  }

  changeDate(newDate) {
    this.date = newDate;
    this.date.setHours(0, 0, 0);
    this.dateChange.emit(this.date);
  }

  clearDate() {
    this.dateChange.emit(null);
  }

  onTouched = () => {};

  onChange(event) {
    this.date = event;
  }

  writeValue(value: any): void {
    this.date = value;
    this.onChange(this.date);
  }

  registerOnChange(fn: (rating: number) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }
}
