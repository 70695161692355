import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {ModalService} from "../../../core/modal.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
    selector: 'app-registration-step-logo',
    templateUrl: './registration-step-logo.component.html',
    styleUrls: ['./registration-step-logo.component.scss']
})
export class RegistrationStepLogoComponent implements OnInit {
    @Input() user: any = {};
    @ViewChild('form') form;

    constructor(private modalService: ModalService,
                private translateService: TranslateService) {
    }

    ngOnInit() {
    }

    ngOnChanges() {
        if (this.user) {
            if (!this.user.referent) {
                this.user.referent = <any>{}
            }
        }
    }

    isValid() {
        return this.form.valid;
    }

    validate() {
        this.form.submitted = true;
        if (!this.isValid()) {
            this.modalService.error(this.translateService.instant("general.form.error"));
            return false;
        }
        return true
    }

    getUser() {
        return this.user;
    }

    addUserImage($event: any) {
        this.user.image = $event;
    }
}
