import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-autocomplete',
    templateUrl: './autocomplete.component.html',
    styleUrls: ['./autocomplete.component.scss']
})
export class AutocompleteComponent implements OnInit {

    @Input() searchToken: string;
    @Input() inputPlaceholder: string;
    @Input() items: any[] = [];
    @Output() inputChanged: EventEmitter<any> = new EventEmitter();
    @Output() itemSelected: EventEmitter<any> = new EventEmitter();
    companyToken: any;
    companyList: any;
    invitationData: any;

    constructor() {
    }

    ngOnInit() {
    }

    searchCompanyByName(companyToken: any) {
        
    }

    selectItem(company: any) {
        
    }
}
