import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class NotificationService {

    constructor(private http: HttpClient) {
    }

    getAll(page: number, limit: number) {
        return this.search(page, limit, {})
    }

    search(page, limit, searchObj) {
        return this.http.post(environment.server + "/notification/search?page=" + page + "&limit=" + limit, searchObj);
    }

    modify(id, notification) {
        return this.http.put(environment.server + "/notification/" + id, notification)
    }
}
