import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { StoreService } from './store.service';
import { CommonService } from './common.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EventRegistrationService {
  constructor(
    private http: HttpClient,
    private storeService: StoreService,
    private commonService: CommonService
  ) { }

  getAll(userId?: string) {
    return this.http.get(`${environment.server}/event-registration`, { params: { userId } });
  }

  get(id: string) {
    return this.http.get(`${environment.server}/event-registration/${id}`);
  }

  getUsersByEvent(eventId: string) {
    return this.http.get(`${environment.server}/event-registration/${eventId}/users`);
  }

  modify(id: string, data: any) {
    return this.http.put(`${environment.server}/event-registration/${id}`, data);
  }

  getRegistrationByUserEvent(idUser: string, idEvent: string) {
    return this.http.get(
      `${environment.server}/event-registration/user/${idUser}/event/${idEvent}`
    );
  }

  getRegistrationByUser(idUser: string) {
    return this.http.get(`${environment.server}/event-registration/user/${idUser}`);
  }

  addData(data: any) {
    return this.http.post(`${environment.server}/event-registration`, data);
  }

  addDataTicketsOnly(data: any) {
    return this.http.post(`${environment.server}/event-registration/tickets-only`, data);
  }

  search(page, limit, searchObject) {
    page = page || 1;
    limit = limit || 10;
    return this.http.post(
      `${environment.server}/event-registration/search?page=${page}&limit=${limit}`,
      searchObject
    );
  }

  remove(id: string) {
    return this.http.delete(`${environment.server}/event-registration/${id}`);
  }

  countByEvent(eventId: string) {
    return this.http.get(`${environment.server}/event-registration/count-by-event/${eventId}`);
  }

  sendReminderOrganizationEmail(eventId: string) {
    return this.http.post(
      `${environment.server}/event-registration/sendReminderOrganization/${eventId}`,
      {}
    );
  }

  print(registrationId: string, queryString: string) {
    const query = queryString || '';
    return `${environment.server}/pdf/event-registration/${registrationId}${query}`;
  }

  printAll(event: string) {
    return `${environment.server}/pdf/event-registrations?event=${event}`;
  }

  requestPayment(idEvent: string, data: any): Observable<any> {
    return this.http.post(
      `${environment.server}/event-registration/${idEvent}/request-payment`,
      data
    );
  }
}
