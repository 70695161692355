import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-form-progress',
  templateUrl: './form-progress.component.html',
  styleUrls: ['./form-progress.component.scss']
})
export class FormProgressComponent implements OnInit {
  @Input() steps: string[] = [];
  @Input() currentStep = 0;
  @Input() size: string;
  @Input() hideLabels = false;

  constructor() {}

  ngOnInit() {}
}
