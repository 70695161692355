<div class="m-progress" [class.small]="size == 'small'">
  <span class="step" *ngFor="let step of steps; let index = index">
    <div
      class="circle"
      [ngClass]="{ complete: currentStep >= index, large: size == 'large', small: size == 'small' }"
    >
      <span class="label">{{ index + 1 }}</span>
      <span class="title" *ngIf="steps[index] && !hideLabels">{{ steps[index] }}</span>
    </div>

    <span
      class="bar"
      [ngClass]="{
        complete: currentStep >= index + 1,
        large: size == 'large',
        small: size == 'small'
      }"
    >
    </span>
  </span>
</div>
