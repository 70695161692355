<div class="modal-header">
    {{ 'dashboard.summary.rules' | translate }} Files
</div>

<div class="modal-body">
  <div class="box-regulation">
    <img class="flag" src="./assets/img/icons8-italy-50.png" alt="Italy" />
    <div class="row">
      <div class="col-sm">
       
        <app-file-upload (fileUpload)="handleFileUpload($event)" [placeHolder]="('dashboard.summary.rules' | translate)  + ' ' + ('category.wine' | translate)" [fileName]="'regolamentoTheWineHunterAward_wine_it.pdf'"></app-file-upload>
      </div>
      <div class="col-sm">
        <app-file-upload (fileUpload)="handleFileUpload($event)" [placeHolder]="('dashboard.summary.rules' | translate) + ' ' + ('category.food' | translate)"  [fileName]="'regolamentoTheWineHunterAward_food_it.pdf'"></app-file-upload>
      </div>
    </div>
  </div>

  <div class="box-regulation">
    <img class="flag" src="./assets/img/icons8-great-britain-50.png" alt="Great Britain" />
    <div class="row">
      <div class="col-sm">
        <app-file-upload (fileUpload)="handleFileUpload($event)" [placeHolder]="('dashboard.summary.rules' | translate)  + ' ' + ('category.wine' | translate)" [fileName]="'regolamentoTheWineHunterAward_wine_en.pdf'"></app-file-upload>
      </div>
      <div class="col-sm">
        <app-file-upload (fileUpload)="handleFileUpload($event)" [placeHolder]="('dashboard.summary.rules' | translate) + ' ' + ('category.food' | translate)" [fileName]="'regolamentoTheWineHunterAward_food_en.pdf'"></app-file-upload>
      </div>
    </div>
  </div>

  <div class="box-regulation">
    <img class="flag" src="./assets/img/icons8-germany-50.png" alt="Germany" />
    <div class="row">
      <div class="col-sm">
        <app-file-upload (fileUpload)="handleFileUpload($event)" [placeHolder]="('dashboard.summary.rules' | translate)  + ' ' + ('category.wine' | translate)" [fileName]="'regolamentoTheWineHunterAward_wine_de.pdf'"></app-file-upload>
      </div>
      <div class="col-sm">
        <app-file-upload (fileUpload)="handleFileUpload($event)" [placeHolder]="('dashboard.summary.rules' | translate) + ' ' + ('category.food' | translate)" [fileName]="'regolamentoTheWineHunterAward_food_de.pdf'"></app-file-upload>
      </div>
    </div>
  </div>
</div>
