import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { CommonService } from './common.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class EvaluationService {
  constructor(private commonService: CommonService, private http: HttpClient) {}

  addEvaluation(data: any, hideSpinner: boolean = false) {
    if (typeof data.evaluation === 'string') {
      data.evaluation = this.commonService.stringToFloat(data.evaluation);
    }
    if (typeof data.finalEvaluation === 'string') {
      data.finalEvaluation = this.commonService.stringToFloat(data.finalEvaluation);
    }
    return this.http.post(environment.server + '/board', data, {
      params: { hideSpinner: hideSpinner.toString() }
    });
  }
}
