import { Component, OnInit, Output, EventEmitter, Input, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit {

  constructor() { }

  @Input() fileName: string;
  @Input() placeHolder: string;
  @Output() fileUpload: EventEmitter<any> = new EventEmitter();

  @ViewChild('fileInput') fileInput: ElementRef;


  ngOnInit(): void {
  }


  onFileChange(event: any) {
    if (event.target.files.length > 0) {
      const file: File = event.target.files[0];
      this.fileUpload.emit({ file, name: this.fileName });
      this.fileInput.nativeElement.value = '';
    }
  }

}
