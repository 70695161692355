import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { UserService } from './user.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommissionService {
  constructor(private http: HttpClient, private userService: UserService) {}

  getEvaluationsHistoryByCommission(productId: string, commissionId: string) {
    return this.http.get(
      `${environment.server}/boards/history/${productId}/commission/${commissionId}`
    );
  }

  getProductEvaluationsHistory(productId: string) {
    return this.http.get(environment.server + '/boards/history/' + productId);
  }

  getAll(page: number, limit: number): Observable<any> {
    return this.userService.search(page, limit, {
      filterBy: { userType: 'board' }
    });
  }

  getBoardStatsByCommission(id: any, filterBy: any) {
    return this.http.post(environment.server + '/boards/commission/' + id + '/stats', { filterBy });
  }

  addEvaluation(data) {
    return this.http.post(environment.server + '/board', data);
  }

  getAssociatedProducts(id) {
    return this.http.get(environment.server + '/commission/' + id + '/products');
  }

  getMembers() {
    return this.http.get(environment.server + '/user/commission/members');
  }
}
