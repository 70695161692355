<form #form="ngForm" [ngClass]="{ 'submitted': form.submitted }">
    <div class="uk-grid uk-grid-small">
        <div class="uk-width-small-1-2">
            <label class="m-label">{{"ragione.sociale" | translate}} *</label>
            <input type="text"
                   name="businessName"
                   class="m-input"
                   [(ngModel)]="user.society.businessName"
                   (input)="user.societyChanged = true; user.society.businessNameChanged = true"
                   required>
        </div>
        <div class="uk-width-small-1-2">
            <label class="m-label">{{"indirizzo" | translate}} *</label>
            <input type="text"
                   name="address"
                   class="m-input"
                   [(ngModel)]="user.society.address"
                   (input)="user.societyChanged = true; user.society.addressChanged = true"
                   required>
        </div>
        <div class="uk-width-small-1-2">
            <label class="m-label">{{"cap" | translate}} *</label>
            <input type="text"
                   name="postalCode"
                   class="m-input"
                   [(ngModel)]="user.society.postalCode"
                   (input)="user.societyChanged = true; user.society.postalCodeChanged = true"
                   required>
        </div>
        <div class="uk-width-small-1-2">
            <label class="m-label">{{"citta" | translate}} *</label>
            <input type="text"
                   name="city"
                   class="m-input"
                   [(ngModel)]="user.society.city"
                   (input)="user.societyChanged = true; user.society.cityChanged = true"
                   required>
        </div>
        <div class="uk-width-small-1-2">
            <label class="m-label">{{'stato' | translate}} *</label>
            <div class="m-select" [ngClass]="{'ng-invalid': form.submitted && !user.society.country}">
                <select name="country" [(ngModel)]="user.society.country" (change)="selectCountry()" required>
                    <option *ngFor="let country of countries" [value]="country.code">
                        {{ country.name }}
                    </option>
                </select>
            </div>
        </div>
        <div class="uk-width-small-1-2">
            <label class="m-label">{{"regione" | translate}}
                <span *ngIf="districts.length > 0">*</span></label>
            <div class="m-select" *ngIf="districts.length > 0"
                 [ngClass]="{'ng-invalid': form.submitted && !user.society.district}">
                <select name="district" [(ngModel)]="user.society.district" (change)="selectDistrict()" required>
                    <option *ngFor="let district of districts" [value]="district.name">
                        {{ district.name }}
                    </option>
                </select>
            </div>
            <!--Per paesi non mappati sul database con le proprie regioni-->
            <input type="text" class="m-input" name="district"
                   [(ngModel)]="user.society.district"
                   (input)="user.societyChanged = true; user.society.districtChanged = true" *ngIf="user.society.country !== 'IT' || districts.length == 0">
        </div>
        <div class="uk-width-small-1-2">
            <label class="m-label">{{"provincia" | translate}}
                <span *ngIf="provinces.length > 0">*</span></label>
            <div class="m-select" *ngIf="provinces.length > 0"
                 [ngClass]="{'ng-invalid': form.submitted && !user.society.province}">
                <select name="province" [(ngModel)]="user.society.province" required>
                    <option *ngFor="let province of provinces" [value]="province.name">
                        {{ province.name }}
                    </option>
                </select>
            </div>
            <input type="text" class="m-input" name="province"
                   [(ngModel)]="user.society.province"
                   (input)="user.societyChanged = true; user.society.provinceChanged = true" *ngIf="user.society.country !== 'IT' || provinces.length == 0">
        </div>
        <div class="uk-width-small-1-2">
            <label class="m-label">{{"pec" | translate}} *</label>
            <input type="text"
                   class="m-input"
                   name="certifiedEmail"
                   [ngClass]="{'readonly': certifiedEmailDisable}"
                   [(ngModel)]="user.society.certifiedEmail"
                   [readonly]="!!certifiedEmailDisable"
                   (input)="user.societyChanged = true; user.society.certifiedEmailChanged = true"
                   required
                   [email]="!certifiedEmailDisable">

            <label class="m-label m-label__statement">
                <input type="checkbox"
                       (change)="certifiedEmailDisable ? (user.society.certifiedEmail = 'NON PRESENTE') : (user.society.certifiedEmail = '')"
                       name="certifiedEmailDisable"
                       [(ngModel)]="certifiedEmailDisable"
                       (input)="user.societyChanged = true; user.society.certifiedEmailChanged = true">
                {{"pec.inesistente" | translate}}
            </label>
        </div>
        <div class="uk-width-small-1-2">
            <label class="m-label">{{"partita.iva" | translate}} *</label>
            <input type="text"
                   class="m-input"
                   name="vatNumber"
                   [(ngModel)]="user.society.vatNumber"
                   (input)="user.societyChanged = true; user.society.vatNumberChanged = true"
                   [required]="!user.society.fiscalCode">
        </div>
        <div class="uk-width-small-1-2">
            <label class="m-label">{{"codice.fiscale" | translate}}</label>
            <input type="text"
                   class="m-input"
                   name="fiscalCode"
                   [(ngModel)]="user.society.fiscalCode"
                   (input)="user.societyChanged = true; user.society.fiscalCodeChanged = true"
                   [required]="!user.society.vatNumber">
        </div>
        <div class="uk-width-small-1-2">
            <label class="m-label">{{"codice.fattura.elettronica" | translate}} *</label>
            <input type="text"
                   class="m-input"
                   name="electronicInvoiceCode"
                   [ngClass]="{'readonly': electronicInvoiceCodeDisable}"
                   [(ngModel)]="user.society.electronicInvoiceCode"
                   (input)="user.societyChanged = true; user.society.electronicInvoiceCodeChanged = true"
                   [readonly]="!!electronicInvoiceCodeDisable"
                   required>
            <label class="m-label m-label__statement">
                <input type="checkbox"
                       (change)="electronicInvoiceCodeDisable ? (user.society.electronicInvoiceCode = 'NON PRESENTE') : (user.society.electronicInvoiceCode = '')"
                       name="electronicInvoiceCodeDisable"
                       [(ngModel)]="electronicInvoiceCodeDisable"
                       (input)="user.societyChanged = true; user.society.electronicInvoiceCodeChanged = true">
                {{"codice.fattura.elettronica.inesistente" | translate}}
            </label>
        </div>
        <div class="uk-width-small-1-2">
            <label class="m-label">
                {{"email.amministrazione" | translate}} * <span class="ti-info-alt text-danger"
                                                                [tooltip]="'email.amministrazione.help' | translate"></span>
            </label>
            <input type="email"
                   class="m-input"
                   name="administrativeEmail"
                   [(ngModel)]="user.society.administrativeEmail"
                   (input)="user.societyChanged = true; user.society.administrativeEmailChanged = true"
                   required
                   email>
        </div>
    </div>
    <div class="fields-required">
        {{ "campi.obbligatori" | translate }}
    </div>
</form>
