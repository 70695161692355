import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import * as _ from 'lodash';

@Component({
    selector: 'app-pagination',
    templateUrl: './pagination.component.html',
    styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {

    @Input() paginationModel: any = {};
    @Output() paginationModelEmitter: EventEmitter<any> = new EventEmitter();
    @Input() pagesOnly: boolean = false;
    @Output() refetch: EventEmitter<boolean> = new EventEmitter<boolean>();

    pageLimits: number[] = [5, 10, 25, 50];

    constructor() {
    }

    ngOnInit() {
    }

    next() {
        if (this.paginationModel.page < this.paginationModel.pages) {
            this.paginationModel.page++;
            this.sendRefetch();
        }
    }

    prev() {
        if (this.paginationModel.page > 1) {
            this.paginationModel.page--;
            this.sendRefetch()
        }
    }

    changeLimit() {
        this.paginationModel.page = 1; //Riporta alla pagina iniziale
        this.sendRefetch();
    }

    pageChanged() {
        if (this.paginationModel.page < 1 || /^[0-9]*$/.test(this.paginationModel.page + '') == false) {
            this.paginationModel.page = 1;
        }
        this.sendRefetch()
    }

    sendRefetch() {
        this.paginationModelEmitter.emit(this.paginationModel);
        this.refetch.emit(true)
    }

}
