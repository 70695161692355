<div class="Card">
    <div class="Card__title">
      {{"cancel.title" | translate}}
    </div>
  
    <div class="uk-grid">
      <div class="uk-width-1-1">
        <p>
            {{respApi}}
        </p>
        <button class="m-button" (click)="undo()">{{"button.undo" | translate}}</button>
      </div>
    </div>
  
  </div>
