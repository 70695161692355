import {Directive, ElementRef, HostListener} from '@angular/core';
import {FormControl, NG_VALIDATORS, ValidationErrors, Validator} from "@angular/forms";

@Directive({
    selector: '[numbersOnly]',
    providers: [
        {provide: NG_VALIDATORS, useExisting: NumbersOnlyDirective, multi: true}
    ]
})
export class NumbersOnlyDirective implements Validator {

    constructor(private _el: ElementRef) {
    }

    @HostListener('input', ['$event'])
    onInputChange(event) {
        const initialValue = this._el.nativeElement.value;
        ///^[0-9]+([,.][0-9]+)?$/
        // Ammetti solo numeri, virgole e punti
        this._el.nativeElement.value = initialValue.replace(/[^0-9,.]*/g, '');
        if (initialValue !== this._el.nativeElement.value) {
            event.stopPropagation();
        }
    }

    @HostListener('blur', ['$event'])
    onBlur(event: KeyboardEvent) {
        console.log("Input blur!", this._el.nativeElement.value)
    }

    // This method is the one required by the Validator interface
    validate(c: FormControl): ValidationErrors | null {
        // Here we call our static validator function
        return NumbersOnlyDirective.validateNumber(c);
    }

    static validateNumber(control: FormControl): ValidationErrors {
        if (/^[0-9]+([,.][0-9]+)?$/.test(control.value) == false) {
            return {numbersOnly: 'Numero non valido'};
        }
        // If no error, return null
        return null;
    }
}
