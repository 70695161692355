import {TranslateService} from "@ngx-translate/core";
import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, RouterStateSnapshot} from "@angular/router";
import {Observable} from "rxjs/Rx";

@Injectable()
export class TranslationLoaderResolver {

    constructor(private translate: TranslateService) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
        return this.translate.get("general.avanti")
    }

}