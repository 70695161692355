import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  constructor(private http: HttpClient) { }

  get(id: string) {
    return this.http.get(`${environment.server}/payment/${id}`);
  }

  add(data: any) {
    return this.http.post(`${environment.server}/payment`, data);
  }

  modify(id: string, data: any) {
    return this.http.put(`${environment.server}/payment/${id}`, data);
  }

  createPayment(eventRegistration, voucherQuantitySelected, transactions) {
    return this.http.post(`${environment.server}/payment/session/create`, {
      transactions,
      eventRegistration,
      voucherQuantitySelected
    });
  }

  preConfirm(id: string, body: any) {
    return this.http.put(`${environment.server}/payment/${id}/preconfirm`, body).pipe(
      tap(
        data => console.log(data),
        error => console.log(error)
      )
    );
  }

  cancel(body) {
    return this.http.post(`${environment.server}/payment/cancel`, body);
  }
}
