import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { has, get } from 'lodash';
import { EnumService } from './enum.service';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from './common.service';
import { DatePipe } from '@angular/common';
import { environment } from 'src/environments/environment';
import { take, tap } from 'rxjs/operators';

declare var XLSX: any;

@Injectable({
  providedIn: 'root'
})
export class ExportService {
  constructor(
    private http: HttpClient,
    private enumService: EnumService,
    private translateService: TranslateService,
    private commonService: CommonService
  ) {}

  exportCsv(items, fileTitle) {
    const convertToCSV = objArray => {
      const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
      let str = '';

      // HEADERS
      let line = '';
      for (const key of array[0]) {
        line += '"' + key + '",';
      }
      line = line.substr(0, line.length - 1);
      str += line + '\r\n';

      array.forEach(obj => {
        line = '';
        for (const key of obj) {
          line += '"' + obj[key] + '",';
        }
        line = line.substr(0, line.length - 1);
        str += line + '\r\n';
      });
      return str;
    };
    const pad = numberToPad => {
      if (numberToPad <= 99) {
        numberToPad = ('0' + numberToPad).slice(-2);
      }
      return numberToPad;
    };
    const jsonObject = JSON.stringify(items);
    const csv = convertToCSV(jsonObject);
    const exportedFilenmae =
      fileTitle +
        '_' +
        new Date().getFullYear() +
        pad(new Date().getMonth() + 1) +
        pad(new Date().getDate()) +
        '.csv' || 'export.csv';
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(blob, exportedFilenmae);
    } else {
      const link = document.createElement('a');
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', exportedFilenmae);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }

  buildUserRow(item, hasProducts = false) {
    let user: any = {};

    if (item) {
      const userCategory = this.enumService
        .getUserCategories()
        .find(category => category.code === item.category);
      const userType = this.enumService.getUserTypes().find(type => type.code === item.userType);

      const years: Map<number, { products: number; inserted: number }> = new Map();

      if (hasProducts && item.products) {
        const initialYear = 2019;
        let currentYear = initialYear;
        const finalYear = new Date().getFullYear();

        while (currentYear <= finalYear) {
          const products = item.products.filter(
            product => new Date(product.creationDate).getFullYear() === currentYear
          ).length;
          const inserted = item.products.filter(
            product =>
              new Date(product.creationDate).getFullYear() === currentYear && product.codeImport
          ).length;

          years.set(currentYear, { products, inserted });
          currentYear++;
        }
      }

      user = {
        'Codice utente': ((item.progressiveId || '') + (item.progressiveCode || '-')).toUpperCase(),
        'Categoria azienda': userCategory?.name ? userCategory.name : '',
        'Tipo account': userType?.name ? userType.name : '',
        'Account verificato': item.verified ? 'sì' : 'no',
        Email: get(item, 'email', '').toLowerCase(),
        'Azienda (catalogo)': this.commonService.capitalize(get(item, 'canteen.businessName', '')),
        'Gruppo (catalogo)': this.commonService.capitalize(get(item, 'canteen.group', '')),
        'Indirizzo (catalogo)': this.commonService.capitalize(get(item, 'canteen.address', '')),
        'CAP (catalogo)': this.commonService.capitalize(get(item, 'canteen.postalCode', '')),
        'Città (catalogo)': this.commonService.capitalize(get(item, 'canteen.city', '')),
        'Stato (catalogo)': this.commonService.capitalize(get(item, 'canteen.country', '')),
        'Regione (catalogo)': get(item, 'canteen.district', ''),
        'Provincia (catalogo)': this.commonService.capitalize(get(item, 'canteen.province', '')),
        'Telefono (catalogo)':
          get(item, 'canteen.prefixPhone', '') + get(item, 'canteen.phone', ''),
        'Fax (catalogo)': get(item, 'canteen.prefixFax', '') + get(item, 'canteen.fax', ''),
        'Email aziendale (catalogo)': get(item, 'canteen.corporateEmail', '').toLowerCase(),
        'Sito web (catalogo)': item.canteen && item.canteen.webSite ? item.canteen.webSite : '',
        'Tipo agricoltura':
          (item.canteen && item.canteen.agricultureType && item.canteen.agricultureType.length
            ? item.canteen.agricultureType
                .map(a => this.translateService.instant('agriculture.' + a))
                .join(';')
            : '') || '',
        'Microzona/Zona vitivinicola': this.commonService.capitalize(
          get(item, 'canteen.microzone', '')
        ),
        'Microzona/Zona vitivinicola Manuale': this.commonService.capitalize(
          item.canteen?.manualMicrozone
            ? item.canteen.manualMicrozone
            : item.wineDetails?.zone
            ? item.wineDetails.zone
            : ''
        ),

        'Ettari vitati': get(item, 'wineDetails.area', ''),
        'Totale produzione bottiglie': get(item, 'wineDetails.productionBottles', ''),
        Winemaker: this.commonService.capitalize(get(item, 'wineDetails.winemaker', '')),

        'Quantità produzione birra (in hl)': get(item, 'beerDetails.productionQuantity', ''),

        'Azienda (fiscale)': this.commonService.capitalize(get(item, 'society.businessName', '')),
        'Indirizzo (fiscale)': this.commonService.capitalize(get(item, 'society.address', '')),
        'CAP (fiscale)': get(item, 'society.postalCode', ''),
        'Città (fiscale)': this.commonService.capitalize(get(item, 'society.city', '')),
        'Stato (fiscale)': get(item, 'society.country', ''),
        'Regione (fiscale)': get(item, 'society.district', ''),
        'Provincia (fiscale)': this.commonService.capitalize(get(item, 'society.province', '')),
        'P. IVA': get(item, 'society.vatNumber', ''),
        'C. Fiscale': get(item, 'society.fiscalCode', ''),
        PEC: get(item, 'society.certifiedEmail', ''),
        'Codice destinatario fattura elettronica': get(item, 'society.electronicInvoiceCode', ''),
        'Nome referente': this.commonService.capitalize(get(item, 'referent.name', '')),
        'Cognome referente': this.commonService.capitalize(get(item, 'referent.surname', '')),
        'Ruolo referente': this.commonService.capitalize(get(item, 'referent.role', '')),
        'Tel. referente': get(item, 'referent.prefixPhone', '') + get(item, 'referent.phone', ''),
        'Email referente': get(item, 'referent.email', '').toLowerCase(),

        'Data iscrizione': formatDate(item.inscriptionDate) || '',
        'Privacy accettata': item.privacy ? 'sì' : 'no',
        'Data accettazione privacy': item.privacyDate ? formatDate(item.privacyDate) : '',
        Newsletter: item.newsletterFeed ? 'sì' : 'no',
        'Account disabilitato': item.disabled ? 'sì' : 'no'
      };

      if (hasProducts) {
        years.forEach((value, year) => {
          const productsRowName = `Prodotti ${year}`;
          const insertedRowName = `Registrati ${year}`;

          user[productsRowName] = value.products;
          user[insertedRowName] = value.inserted;
        });
      }
    }

    function formatDate(date) {
      const newDate = new Date(date);
      return newDate.getDate() + '-' + (newDate.getMonth() + 1) + '-' + newDate.getFullYear();
    }

    return item ? user : null;
  }

  buildUserOneLineRow(products: any[], category) {
    const exportItems = [];
    products.forEach(item => {
      const rowAzienda = {
        Azienda: this.commonService.capitalize(get(item, 'user.canteen.businessName', '')),
        'Codice utente': get(item, 'user.progressiveId', '') + get(item, 'user.progressiveCode', '')
      };

      const rowDatiReferente = {
        'Nome Referente': this.commonService.capitalize(get(item, 'user.referent.name', '')),
        'Cognome Referente': this.commonService.capitalize(get(item, 'user.referent.surname', '')),
        'Email Referente': get(item, 'user.referent.email', '').toLowerCase(),
        'Ruolo Referente': this.commonService.capitalize(get(item, 'user.referent.role', '')),
        'Telefono Referente': get(item, 'user.referent.phone', ''),
        'ID Referente': get(item, 'user.referent._id', ''),
        'Nome winemaker / enologo': get(item, 'user.wineDetails.winemaker')
      };

      const rowDatiCatalogo = {
        'Nome (catalogo)': this.commonService.capitalize(
          get(item, 'user.canteen.businessName', '')
        ),
        'Indirizzo (catalogo)': this.commonService.capitalize(
          get(item, 'user.canteen.address', '')
        ),
        'Codice postale (catalogo)': get(item, 'user.canteen.postalCode', ''),
        'Città (catalogo)': this.commonService.capitalize(get(item, 'user.canteen.city', '')),
        'Paese (catalogo)': get(item, 'user.canteen.country', ''),
        'Regione (catalogo)': get(item, 'user.canteen.district', ''),
        'Provincia (catalogo)': this.commonService.capitalize(
          get(item, 'user.canteen.province', '')
        ),
        'Microzona/Zona vitivinicola': this.commonService.capitalize(
          get(item, 'user.canteen.microzone', '')
        ),
        'Microzona/Zona vitivinicola Manuale': this.commonService.capitalize(
          item.user?.canteen?.manualMicrozone
            ? item.user.canteen.manualMicrozone
            : item.user?.wineDetails?.zone
            ? item.user?.wineDetails.zone
            : ''
        ),
        'Telefono (catalogo)': get(item, 'user.canteen.phone', ''),
        'Fax (catalogo)': get(item, 'user.canteen.fax', ''),
        'Email (catalogo)': get(item, 'user.canteen.corporateEmail', '').toLowerCase(),
        'Sito web (catalogo)': item?.user?.canteen?.webSite ? item.user.canteen.webSite : '',
        'ID (catalogo)': get(item, 'user.canteen._id', '')
      };

      const agrtypearr = (item.user && item.user.canteen
        ? item.user.canteen.agricultureType
        : ['']) || [''];
      let agrtypes = '';

      agrtypearr.forEach(agrt => {
        agrtypes += agrt + ', ';
      });

      // Rimuovo gli ultimi caratteri ', ' se agrtypes è diverso da ''
      agrtypes = agrtypes !== '' ? agrtypes.slice(0, -2) : '';

      rowDatiCatalogo['Tipo agricoltura (catalogo)'] = agrtypes;
      rowDatiCatalogo['Distretto (catalogo)'] = this.commonService.capitalize(
        get(item, 'user.canteen.district', '')
      );

      const idAzienda = {
        'ID Società (catalogo)': get(item, 'user.society._id', '')
      };

      const aziendaCompleteRow = Object.assign(
        {},
        idAzienda,
        rowAzienda,
        rowDatiReferente,
        rowDatiCatalogo
      );

      const row: any = [];
      row['Nome (prodotto)'] = this.commonService.capitalize(get(item, 'name', ''));
      row['Codice interno (prodotto)'] = get(item, 'codeImport', '');
      row['Regione (prodotto)'] = get(item, 'region', '');

      if (category === 'wine') {
        row['Tipologia (prodotto)'] = has(item, 'wineTechnical.wineType')
          ? this.translateService.instant('product.wineType.' + item.wineTechnical.wineType)
          : '';
        row['Tipologia spumante (prodotto)'] =
          (item.wineTechnical?.wineType === 'sparkling' ||
            item.wineTechnical?.wineType === 'sparklingRose') &&
          has(item, 'wineTechnical.sparklingType')
            ? this.translateService.instant(
                `category.sparkling.${item.wineTechnical.sparklingType}`
              )
            : '';
        row['Classificazione (prodotto)'] = (
          (item.wineTechnical
            ? item.wineTechnical.denomination
            : item.foodTechnical
            ? item.foodTechnical.denomination
            : '') || ''
        ).toUpperCase();
        row['Denominazione (prodotto)'] = this.commonService.capitalize(
          get(item, 'wineTechnical.areaDenomination ', '')
        );
        row['Annata (prodotto)'] =
          item.wineTechnical && item.wineTechnical.years
            ? item.wineTechnical.years.map(year => year.year).join('; ')
            : '';
        row['Microzona (prodotto)'] = this.commonService.capitalize(
          get(item, 'wineTechnical.microzone')
        );
        row['Affinamento (prodotto)'] =
          has(item, 'wineTechnical.aging') && item.wineTechnical.aging.length
            ? item.wineTechnical.aging
                .map(a => this.translateService.instant('product.aging.' + a))
                .join('; ')
            : '';
        row['Varietà (prodotto)'] =
          has(item, 'wineTechnical.variety') && item.wineTechnical.variety.length
            ? item.wineTechnical.variety
                .map(v => {
                  if (v.name && v.name !== '') {
                    return v.perc + '%' + ' ' + this.commonService.capitalize(v.name);
                  }
                  return '';
                })
                .join('; ')
            : '';
        row['Varietà manuale (prodotto)'] = item.wineTechnical?.variety?.some(
          variety => variety.manualName && variety.manualName !== ''
        )
          ? item.wineTechnical.variety
              .map(v => {
                if (v.manualName && v.manualName !== '') {
                  return v.perc + '%' + ' ' + this.commonService.capitalize(v.manualName);
                }
                return '';
              })
              .join('; ')
          : '';
        row['Tipo agricoltura (prodotto)'] = has(item, 'wineTechnical.agricultureType')
          ? this.translateService.instant('agriculture.' + item.wineTechnical.agricultureType)
          : '';

        row['Specificazione (prodotto)'] = item.wineTechnical.specification;
        row['Altra specificazione (prodotto)'] = item.wineTechnical.otherSpecification;
        row['Denominazione (prodotto)'] = this.commonService.capitalize(
          item.wineTechnical.areaDenomination
        );
        row['Denominazione manuale (prodotto)'] = item.wineTechnical.manualAreaDenomination;
        row['Microzona (prodotto)'] = this.commonService.capitalize(item.wineTechnical.microzone);
        row['Microzona manuale (prodotto)'] = item.wineTechnical.manualMicrozone;
      } else if (category === 'spirit') {
        row['Denominazione (prodotto)'] = this.commonService.capitalize(
          get(item, 'spiritTechnical.denomination', '')
        );
        row['Tipologia (prodotto)'] =
          has(item, 'spiritTechnical.spiritCategory') && item.spiritTechnical.spiritCategory
            ? this.translateService.instant(
                'product.spiritCategory.' + item.spiritTechnical.spiritCategory
              )
            : '';
        row['Prodotto biologico (prodotto)'] =
          item.spiritTechnical.biological === true ? 'sì' : 'no';
        row['Senza glutine (prodotto)'] = item.spiritTechnical.glutenFree === true ? 'sì' : 'no';
        row['Vegano (prodotto)'] = item.spiritTechnical.vegan === true ? 'sì' : 'no';
        row['Prodotto biodinamico (prodotto)'] =
          item.spiritTechnical.biodinamic === true ? 'sì' : 'no';
        row['Percentuale alcolica (prodotto)'] = item.spiritTechnical.degree;
      } else if (category === 'food') {
        row['Classificazione (prodotto)'] = has(item, 'foodTechnical.denomination')
          ? this.translateService.instant(
              'product.beerDenomination.' + item.foodTechnical.denomination
            )
          : '';
        row['Tipologia (prodotto)'] = has(item, 'foodTechnical.categoryFood')
          ? this.translateService.instant('category.food.' + item.foodTechnical.categoryFood)
          : '';
        row['Prodotto biologico (prodotto)'] = item.foodTechnical.biological === true ? 'sì' : 'no';
        row['Senza glutine (prodotto)'] = item.foodTechnical.glutenFree === true ? 'sì' : 'no';
        row['Vegano (prodotto)'] = item.foodTechnical.vegan === true ? 'sì' : 'no';
        row['Prodotto biodinamico (prodotto)'] =
          item.foodTechnical.biodinamic === true ? 'sì' : 'no';
      } else if (category === 'beer') {
        row['Tipologia / stile (prodotto)'] = this.commonService.capitalize(
          get(item, 'beerTechnical.type', '')
        );
        row['Colore (prodotto)'] = this.commonService.capitalize(
          get(item, 'beerTechnical.color', '')
        );
        row['Prodotto biologico (prodotto)'] = item.beerTechnical.biological === true ? 'sì' : 'no';
        row['Senza glutine (prodotto)'] = item.beerTechnical.glutenFree === true ? 'sì' : 'no';
        row['Vegano (prodotto)'] = item.beerTechnical.vegan === true ? 'sì' : 'no';
        row['Prodotto biodinamico (prodotto)'] =
          item.beerTechnical.biodinamic === true ? 'sì' : 'no';
        row['Percentuale alcolica (prodotto)'] = item.beerTechnical.degree;
      }

      row['Commissione (prodotto)'] = this.commonService.capitalize(
        get(item, 'commission.commissionDetails.username', get(item, 'commission.email', ''))
      );
      row['Voto commissione (prodotto)'] = get(item, 'board.evaluation', '');
      row['Voto finale (prodotto)'] = get(item, 'board.finalEvaluation', '');
      row['Difetto? (prodotto)'] = item.board ? (item.board.faulty === true ? 'sì' : 'no') : '';

      const completeRow = Object.assign({}, aziendaCompleteRow, row);

      exportItems.push(completeRow);
    });

    const _map = this.groupBy(exportItems, 'ID Società (catalogo)');
    const totalItems = [];
    Object.keys(_map).forEach(key => {
      const item = _map[key];
      const aziendaInfos = {
        Azienda: item[0].Azienda,
        'Codice utente': item[0]['Codice utente'],
        'Nome Referente': item[0]['Nome Referente'],
        'Cognome Referente': item[0]['Cognome Referente'],
        'Email Referente': item[0]['Email Referente'],
        'Ruolo Referente': item[0]['Ruolo Referente'],
        'Telefono Referente': item[0]['Telefono Referente'],
        'ID Referente': item[0]['ID Referente'],
        'Nome winemaker / enologo': item[0]['Nome winemaker / enologo'],
        'Nome (catalogo)': item[0]['Nome (catalogo)'],
        'Indirizzo (catalogo)': item[0]['Indirizzo (catalogo)'],
        'Codice postale (catalogo)': item[0]['Codice postale (catalogo)'],
        'Città (catalogo)': item[0]['Città (catalogo)'],
        'Regione (catalogo)': item[0]['Regione (catalogo)'],
        'Paese (catalogo)': item[0]['Paese (catalogo)'],
        'Provincia (catalogo)': item[0]['Provincia (catalogo)'],
        'Microzona/Zona vitivinicola': item[0]['Microzona/Zona vitivinicola'],
        'Microzona/Zona vitivinicola Manuale': item[0]['Microzona/Zona vitivinicola Manuale'],
        'Telefono (catalogo)': item[0]['Telefono (catalogo)'],
        'Fax (catalogo)': item[0]['Fax (catalogo)'],
        'Email (catalogo)': item[0]['Email (catalogo)'],
        'Sito web (catalogo)': item[0]['Sito web (catalogo)'],
        'ID (catalogo)': item[0]['ID (catalogo)']
      };

      if (category === 'wine') {
        _map[key].forEach((wineItem, i) => {
          aziendaInfos['Nome (prodotto) ' + i] = wineItem['Nome (prodotto)'];
          aziendaInfos['Codice interno (prodotto) ' + i] = wineItem['Codice interno (prodotto)'];
          aziendaInfos['Regione (prodotto) ' + i] = wineItem['Regione (prodotto)'];
          aziendaInfos['Tipologia (prodotto) ' + i] = wineItem['Tipologia (prodotto)'];
          aziendaInfos['Tipologia spumante (prodotto) ' + i] =
            wineItem['Tipologia spumante (prodotto)'];
          aziendaInfos['Classificazione (prodotto) ' + i] = wineItem['Classificazione (prodotto)'];
          aziendaInfos['Specificazione (prodotto) ' + i] = wineItem['Specificazione (prodotto)'];
          aziendaInfos['Altra specificazione (prodotto) ' + i] =
            wineItem['Altra specificazione (prodotto)'];
          aziendaInfos['Denominazione (prodotto) ' + i] = wineItem['Denominazione (prodotto)'];
          aziendaInfos['Denominazione manuale (prodotto) ' + i] =
            wineItem['Denominazione manuale (prodotto) '];
          aziendaInfos['Microzona (prodotto) ' + i] = wineItem['Microzona (prodotto)'];
          aziendaInfos['Microzona manuale (prodotto) ' + i] =
            wineItem['Microzona manuale (prodotto)'];
          aziendaInfos['Annata (prodotto) ' + i] = wineItem['Annata (prodotto)'];
          aziendaInfos['Affinamento (prodotto) ' + i] = wineItem['Affinamento (prodotto)'];
          aziendaInfos['Varietà (prodotto) ' + i] = wineItem['Varietà (prodotto)'];
          aziendaInfos['Varietà manuale (prodotto) ' + i] = wineItem['Varietà manuale (prodotto)'];
          aziendaInfos['Tipo agricoltura (prodotto) ' + i] =
            wineItem['Tipo agricoltura (prodotto)'];
          aziendaInfos['Commissione (prodotto) ' + i] = wineItem['Commissione (prodotto)'];
          aziendaInfos['Voto commissione (prodotto) ' + i] =
            wineItem['Voto commissione (prodotto)'];
          aziendaInfos['Voto finale (prodotto) ' + i] = wineItem['Voto finale (prodotto)'];
          aziendaInfos['Difetto? (prodotto) ' + i] = wineItem['Difetto? (prodotto)'];
        });
      } else if (category === 'spirit') {
        _map[key].forEach((spiritItem, i) => {
          (aziendaInfos['Nome (prodotto) ' + i] = spiritItem['Nome (prodotto)']),
            (aziendaInfos['Codice interno (prodotto) ' + i] =
              spiritItem['Codice interno (prodotto)']),
            (aziendaInfos['Regione (prodotto) ' + i] = spiritItem['Regione (prodotto)']),
            (aziendaInfos['Classificazione (prodotto) ' + i] =
              spiritItem['Classificazione (prodotto)']),
            (aziendaInfos['Tipologia (prodotto) ' + i] = spiritItem['Tipologia (prodotto)']);
          aziendaInfos['Prodotto biologico (prodotto) ' + i] =
            spiritItem['Prodotto biologico (prodotto)'];
          aziendaInfos['Senza glutine (prodotto) ' + 1] = spiritItem['Senza glutine (prodotto)'];
          aziendaInfos['Vegano (prodotto) ' + i] = spiritItem['Vegano (prodotto)'];
          aziendaInfos['Prodotto biodinamico (prodotto) ' + i] =
            spiritItem['Prodotto biodinamico (prodotto)'];
          aziendaInfos['Percentuale alcolica (prodotto) ' + i] =
            spiritItem['Percentuale alcolica (prodotto)'];
          aziendaInfos['Commissione (prodotto) ' + i] = spiritItem['Commissione (prodotto)'];
          aziendaInfos['Voto commissione (prodotto) ' + i] =
            spiritItem['Voto commissione (prodotto)'];
          aziendaInfos['Voto finale (prodotto) ' + i] = spiritItem['Voto finale (prodotto)'];
          aziendaInfos['Difetto? (prodotto) ' + i] = spiritItem['Difetto? (prodotto)'];
        });
      } else if (category === 'food') {
        _map[key].forEach((foodItem, i) => {
          aziendaInfos['Nome (prodotto) ' + i] = foodItem['Nome (prodotto)'];
          aziendaInfos['Codice interno (prodotto) ' + i] = foodItem['Codice interno (prodotto)'];
          aziendaInfos['Regione (prodotto) ' + i] = foodItem['Regione (prodotto)'];
          aziendaInfos['Classificazione (prodotto) ' + i] = foodItem['Classificazione (prodotto)'];
          aziendaInfos['Tipologia (prodotto) ' + i] = foodItem['Tipologia (prodotto)'];
          aziendaInfos['Prodotto biologico (prodotto) ' + i] =
            foodItem['Prodotto biologico (prodotto)'];
          aziendaInfos['Senza glutine (prodotto) ' + i] = foodItem['Senza glutine (prodotto)'];
          aziendaInfos['Vegano (prodotto) ' + i] = foodItem['Vegano (prodotto)'];
          aziendaInfos['Prodotto biodinamico (prodotto) ' + i] =
            foodItem['Prodotto biodinamico (prodotto)'];
          aziendaInfos['Commissione (prodotto) ' + i] = foodItem['Commissione (prodotto)'];
          aziendaInfos['Voto commissione (prodotto) ' + i] =
            foodItem['Voto commissione (prodotto)'];
          aziendaInfos['Voto finale (prodotto) ' + i] = foodItem['Voto finale (prodotto)'];
          aziendaInfos['Difetto? (prodotto) ' + i] = foodItem['Difetto? (prodotto)'];
        });
      } else if (category === 'beer') {
        _map[key].forEach((beerItem: { [x: string]: any }, i: number) => {
          (aziendaInfos['Nome (prodotto) ' + i] = beerItem['Nome (prodotto)']),
            (aziendaInfos['Codice interno (prodotto) ' + i] =
              beerItem['Codice interno (prodotto)']),
            (aziendaInfos['Regione (prodotto) ' + i] = beerItem['Regione (prodotto)']),
            (aziendaInfos['Tipologia / stile (prodotto) ' + i] =
              beerItem['Tipologia / stile (prodotto)']),
            (aziendaInfos['Colore (prodotto) ' + i] = beerItem['Colore (prodotto)']);
          aziendaInfos['Prodotto biologico (prodotto) ' + i] =
            beerItem['Prodotto biologico (prodotto)'];
          aziendaInfos['Senza glutine (prodotto) ' + i] = beerItem['Senza glutine (prodotto)'];
          aziendaInfos['Vegano (prodotto) ' + i] = beerItem['Vegano (prodotto)'];
          aziendaInfos['Prodotto biodinamico (prodotto) ' + i] =
            beerItem['Prodotto biodinamico (prodotto)'];
          aziendaInfos['Percentuale alcolica (prodotto) ' + i] =
            beerItem['Percentuale alcolica (prodotto)'];
          aziendaInfos['Commissione (prodotto) ' + i] = beerItem['Commissione (prodotto)'];
          aziendaInfos['Voto commissione (prodotto) ' + i] =
            beerItem['Voto commissione (prodotto)'];
          aziendaInfos['Voto finale (prodotto) ' + i] = beerItem['Voto finale (prodotto)'];
          aziendaInfos['Difetto? (prodotto) ' + i] = beerItem['Difetto? (prodotto)'];
        });
      }

      totalItems.push(aziendaInfos);
    });

    return totalItems;
  }

  buildEvaluationRow(item: any, userType: string) {
    const row = {
      ID: item._id,
      'Codice interno': get(item, 'codeImport', ''),
      Azienda: this.commonService.capitalize(get(item, 'user.canteen.businessName', '')),
      'Nome prodotto': this.commonService.capitalize(get(item, 'name'))
    } as any;
    row.Denominazione = this.commonService.capitalize(
      get(item, 'wineTechnical.areaDenomination', '')
    );
    row.Classificazione = this.commonService.uppercase(
      item.wineTechnical
        ? item.wineTechnical.denomination
        : item.foodTechnical
        ? item.foodTechnical.denomination
        : ''
    );
    row.Annata = this.commonService.capitalize(get(item, 'wineTechnical.year', ''));
    row.Regione = get(item, 'region', '');
    row.Categoria =
      this.enumService.getProductCategories().filter(category => category.code === item.category)[0]
        .name || '';
    row['Voto commissione'] = (item.board ? item.board.evaluation : '') || '';
    row['Prodotto con difetto'] = item.board ? (item.board.faulty === true ? 'sì' : 'no') : '';
    row['Commento commissione'] = (item.board ? item.board.description : '') || '';
    row['Voto finale'] = (item.board ? item.board.finalEvaluation : '') || '';
    if (userType === 'admin') {
      row['Commissione associata'] = this.commonService.capitalize(
        get(item, 'commission.commissionDetails.username', '')
      );
      row['Data associazione a commissione'] =
        (item.commissionAssociationDate
          ? this.commonService.formatDate(item.commissionAssociationDate)
          : '') || '';
    }
    return row;
  }

  buildEventRegistrationRow(
    registration: any,
    purchasedServices: any,
    purchasedVouchers: any,
    products: any[]
  ) {
    const total =
      (registration.purchasedServices || []).reduce((a, b) => {
        return a + b._id.price * b.quantity + (b._id.price * b.quantity * b._id.vat) / 100;
      }, 0) *
        1 +
      registration.event.amount * 1 * (1 + (registration.event.vat * 1) / 100);

    const firstReferent = registration.referents[0];
    const referent = {
      name: `${firstReferent ? firstReferent.name : ''} ${
        firstReferent ? firstReferent.surname : ''
      }`,
      email: firstReferent ? firstReferent.email : '',
      phone: firstReferent ? firstReferent.phone : ''
    };

    const row = {
      Codice: registration.progressiveCode,
      Evento: registration.event.name,
      Manifestazione: get(registration, 'event.eventGroup.name', ''),
      Azienda: registration.user ? registration.user.canteen.businessName : '-',
      'Stato iscrizione': this.translateService.instant(
        'eventRegistration.state.' + registration.state
      ),
      'Data iscrizione': registration.createdAt,
      // LinkIscrizione: "http://localhost:4200/dashboard/event-registration/" + registration._id + "/registration",
      // LinkOrganizzazione: "http://localhost:4200/dashboard/event-registration/" + registration._id + "/organization",
      'Stato evento': this.translateService.instant('event.state.' + registration.event.state),
      'Gestione prodotti': this.translateService.instant(
        'eventRegistration.productsHandling.' + registration.productsHandling
      ),
      'Stato invio prodotti': this.translateService.instant(
        'eventRegistration.productsShipmentState.' + registration.productsShipmentState
      ),
      'Servizi acquistati': purchasedServices,
      'Biglietti acquistati': purchasedVouchers,
      'Biglietti riscattati': '',
      'Biglietti utilizzati': '',
      'Azienda (catalogo)': this.commonService.capitalize(
        get(registration, 'user.canteen.businessName', '')
      ),
      'Enologo (catalogo)': this.commonService.capitalize(
        get(registration, 'user.wineDetails.winemaker', '')
      ),
      'Gruppo (catalogo)': this.commonService.capitalize(
        get(registration, 'user.canteen.group', '')
      ),
      'Indirizzo (catalogo)': this.commonService.capitalize(
        get(registration, 'user.canteen.address', '')
      ),
      'CAP (catalogo)': this.commonService.capitalize(
        get(registration, 'user.canteen.postalCode', '')
      ),
      'Città (catalogo)': this.commonService.capitalize(get(registration, 'user.canteen.city', '')),
      'Stato (catalogo)': this.commonService.capitalize(
        get(registration, 'user.canteen.country', '')
      ),
      'Regione (catalogo)': get(registration, 'user.canteen.district', ''),
      'Provincia (catalogo)': this.commonService.capitalize(
        get(registration, 'user.canteen.province', '')
      ),
      'Telefono (catalogo)': get(registration, 'user.canteen.phone', ''),
      'Fax (catalogo)': get(registration, 'user.canteen.fax', ''),
      'Email aziendale (catalogo)': get(
        registration,
        'user.canteen.corporateEmail',
        ''
      ).toLowerCase(),
      'Sito web (catalogo)':
        registration.user && registration.user.canteen && registration.user.canteen.webSite
          ? registration.user.canteen.webSite
          : '',
      'Microzona/Zona vitivinicola': this.commonService.capitalize(
        get(registration, 'user.canteen.microzone', '')
      ),
      'Microzona/Zona vitivinicola Manuale': this.commonService.capitalize(
        registration.user?.canteen?.manualMicrozone
          ? registration.user?.canteen.manualMicrozone
          : registration.user?.wineDetails?.zone
          ? registration.user?.wineDetails.zone
          : ''
      ),
      'Ettari vitati': get(registration, 'user.wineDetails.area', ''),
      'Totale produzione bottiglie': get(registration, 'user.wineDetails.productionBottles', ''),
      'Azienda (fiscale)': this.commonService.capitalize(
        get(registration, 'user.society.businessName', '')
      ),
      'Indirizzo (fiscale)': this.commonService.capitalize(
        get(registration, 'user.society.address', '')
      ),
      'CAP (fiscale)': get(registration, 'user.society.postalCode', ''),
      'Città (fiscale)': this.commonService.capitalize(get(registration, 'user.society.city', '')),
      'Stato (fiscale)': get(registration, 'user.society.country', ''),
      'Regione (fiscale)': get(registration, 'user.society.district', ''),
      'Provincia (fiscale)': this.commonService.capitalize(
        get(registration, 'user.society.province', '')
      ),
      'P. IVA': get(registration, 'user.society.vatNumber', ''),
      'C. Fiscale': get(registration, 'user.society.fiscalCode', ''),
      PEC: get(registration, 'user.society.certifiedEmail', ''),
      'Codice destinatario fattura elettronica': get(
        registration,
        'user.society.electronicInvoiceCode',
        ''
      ),
      'Totale da fatturare':
        (registration.purchasedServices || []).reduce((a, b) => {
          return a + b._id.price * b.quantity + (b._id.price * b.quantity * b._id.vat) / 100;
        }, 0) *
          1 +
        registration.event.amount * 1 * (1 + (registration.event.vat * 1) / 100),
      // Partecipato: this.translateService.instant(toTranslate),
      Referente: referent.name,
      'Email Referente': referent.email,
      'Telefono Referente': referent.phone,
      'Codice cliente': registration.user
        ? `${registration.user.progressiveId}${registration.user.progressiveCode}`
        : '-',
      'Nominativi Pass': registration.passes.reduce((str, pass) => {
        return `${str} ${pass ? pass.name : ''} ${pass && pass.surname ? pass.surname : ''},`;
      }, ''),
      'Annata Vecchia':
        registration.annataVecchia && registration.annataVecchia.annata
          ? `${registration.annataVecchia.annata} ${registration.annataVecchia.nome} ${registration.annataVecchia.classificazione} ${registration.annataVecchia.denominazione}`
          : '',
      'Mini Verticale':
        registration.miniVerticale && registration.miniVerticale.product
          ? `${registration.miniVerticale.product.name} ${registration.miniVerticale.product.wineTechnical.denomination} ${registration.miniVerticale.product.wineTechnical.areaDenomination} ${registration.miniVerticale.annate}`
          : ''
    };

    products.forEach((product, i) => {
      if (product) {
        row['Nome (prodotto) ' + (i + 1)] = this.commonService.capitalize(get(product, 'name', ''));
        row['Regione (prodotto) ' + (i + 1)] = get(product, 'region', '');

        const category = product.category;

        if (category === 'wine') {
          row['Tipologia (prodotto) ' + (i + 1)] = has(product, 'wineTechnical.wineType')
            ? this.translateService.instant('product.wineType.' + product.wineTechnical.wineType)
            : '';
          row['Codice (prodotto) ' + (i + 1)] = get(product, 'codeImport', '');
          row['Denominazione (prodotto) ' + (i + 1)] = this.commonService.capitalize(
            product.wineTechnical.areaDenomination
          );
          row['Denominazione Manuale (prodotto) ' + (i + 1)] = this.commonService.capitalize(
            product.wineTechnical.manualAreaDenomination || ''
          );
          row['Classificazione (prodotto) ' + (i + 1)] = (
            (product.wineTechnical
              ? product.wineTechnical.denomination
              : product.foodTechnical
              ? product.foodTechnical.denomination
              : '') || ''
          ).toUpperCase();
          row['Annata (prodotto) ' + (i + 1)] =
            product.wineTechnical && product.wineTechnical.years
              ? product.wineTechnical.years.map(year => year.year).join('; ')
              : '';
          row['Tipologia spumante (prodotto) ' + (i + 1)] =
            has(product, 'wineTechnical.sparklingType') && product.wineTechnical.sparklingType
              ? this.translateService.instant(
                  'category.sparkling.' + product.wineTechnical.sparklingType
                )
              : '';
          row['Affinamento (prodotto) ' + (i + 1)] = product.wineTechnical.aging
            .map(aging => this.translateService.instant(`product.aging.${aging}`))
            .join(';');
          row['Varietà (prodotto) ' + (i + 1)] =
            product.wineTechnical.variety.reduce((str, variety) => {
              if (variety.name && variety.name !== '') {
                return `${str} ${variety.perc}% ${variety.name},`;
              }
              return str;
            }, '') || '';
          row['Tipo agricoltura (prodotto) ' + (i + 1)] =
            product.wineTechnical.agricultureType || '';
          row['Microzona (prodotto) ' + (i + 1)] = product.wineTechnical.microzone || '';
          row['Specificazione (prodotto) ' + (i + 1)] = product.wineTechnical.specification || '';
          row['Altra Specificazione (prodotto) ' + (i + 1)] =
            product.wineTechnical.otherSpecification || '';
          row['Voto (prodotto) ' + (i + 1)] =
            product.board && product.board.evaluation ? product.board.evaluation : '';
          row['Voto finale (prodotto) ' + (i + 1)] =
            product.board && product.board.finalEvaluation ? product.board.finalEvaluation : '';
        } else if (category === 'spirit') {
          row['Denominazione (prodotto) ' + (i + 1)] = this.commonService.capitalize(
            get(product, 'spiritTechnical.denomination', '')
          );
          row['Tipologia (prodotto) ' + (i + 1)] =
            has(product, 'spiritTechnical.spiritCategory') && product.spiritTechnical.spiritCategory
              ? this.translateService.instant(
                  'product.spiritCategory.' + product.spiritTechnical.spiritCategory
                )
              : '';
          row['Biologico (prodotto) ' + (i + 1)] = product.spiritTechnical
            ? this.translateService.instant(
                `general.${product.spiritTechnical.biological ? `yes` : `no`}`
              )
            : ``;
          row['Senza glutine (prodotto) ' + (i + 1)] = product.spiritTechnical
            ? this.translateService.instant(
                `general.${product.spiritTechnical.glutenFree ? `yes` : `no`}`
              )
            : ``;
          row['Vegano (prodotto) ' + (i + 1)] = product.spiritTechnical
            ? this.translateService.instant(
                `general.${product.spiritTechnical.vegan ? `yes` : `no`}`
              )
            : ``;
          row['Biodinamico (prodotto) ' + (i + 1)] = product.spiritTechnical
            ? this.translateService.instant(
                `general.${product.spiritTechnical.biodinamic ? `yes` : `no`}`
              )
            : ``;
          row['Voto finale (prodotto) ' + (i + 1)] =
            product.board && product.board.finalEvaluation ? product.board.finalEvaluation : ``;
          row['Percentuale alcolica (prodotto) ' + (i + 1)] = product.spiritTechnical.degree
            ? `${product.spiritTechnical.degree}%`
            : ``;
        } else if (category === 'food') {
          row['Classificazione (prodotto) ' + (i + 1)] = has(product, 'foodTechnical.denomination')
            ? this.translateService.instant(
                'product.beerDenomination.' + product.foodTechnical.denomination
              )
            : '';
          row['Tipologia (prodotto) ' + (i + 1)] = has(product, 'foodTechnical.categoryFood')
            ? this.translateService.instant('category.food.' + product.foodTechnical.categoryFood)
            : '';
          row['Biologico (prodotto) ' + (i + 1)] = product.foodTechnical
            ? this.translateService.instant(
                `general.${product.foodTechnical.biological ? `yes` : `no`}`
              )
            : ``;
          row['Senza glutine (prodotto) ' + (i + 1)] = product.foodTechnical
            ? this.translateService.instant(
                `general.${product.foodTechnical.glutenFree ? `yes` : `no`}`
              )
            : ``;
          row['Vegano (prodotto) ' + (i + 1)] = product.foodTechnical
            ? this.translateService.instant(`general.${product.foodTechnical.vegan ? `yes` : `no`}`)
            : ``;
          row['Biodinamico (prodotto) ' + (i + 1)] = product.foodTechnical
            ? this.translateService.instant(
                `general.${product.foodTechnical.biodinamic ? `yes` : `no`}`
              )
            : ``;
          row['Voto finale (prodotto) ' + (i + 1)] =
            product.board && product.board.finalEvaluation ? product.board.finalEvaluation : ``;
        } else if (category === 'beer') {
          row['Tipologia / stile (prodotto) ' + (i + 1)] = this.commonService.capitalize(
            get(product, 'beerTechnical.type', '')
          );
          row['Colore (prodotto) ' + (i + 1)] = this.commonService.capitalize(
            get(product, 'beerTechnical.color', '')
          );
          row['Biologico (prodotto) ' + (i + 1)] = product.beerTechnical
            ? this.translateService.instant(
                `general.${product.beerTechnical.biological ? `yes` : `no`}`
              )
            : ``;
          row['Senza glutine (prodotto) ' + (i + 1)] = product.beerTechnical
            ? this.translateService.instant(
                `general.${product.beerTechnical.glutenFree ? `yes` : `no`}`
              )
            : ``;
          row['Vegano (prodotto) ' + (i + 1)] = product.beerTechnical
            ? this.translateService.instant(`general.${product.beerTechnical.vegan ? `yes` : `no`}`)
            : ``;
          row['Biodinamico (prodotto) ' + (i + 1)] = product.beerTechnical
            ? this.translateService.instant(
                `general.${product.beerTechnical.biodinamic ? `yes` : `no`}`
              )
            : ``;
          row['Voto finale (prodotto) ' + (i + 1)] =
            product.board && product.board.finalEvaluation ? product.board.finalEvaluation : ``;
          row['Percentuale alcolica (prodotto) ' + (i + 1)] =
            product.beerTechnical && product.beerTechnical.degree
              ? `${product.beerTechnical.degree}%`
              : ``;
        }
      }
    });

    return row;
  }

  buildEventInvitationRow(invitation: any) {
    const row = {
      'Codice cliente': `${invitation.user ? invitation.user.progressiveId : ''}${
        invitation.user ? invitation.user.progressiveCode : ''
      }`,
      Evento: `${invitation.event.name}`,
      Manifestazione: `${invitation.event.eventGroup ? invitation.event.eventGroup.name : ''}`,
      'Codice Invito': invitation.progressiveCode,
      'Stato invito senza prodotti': invitation.noProductsState
        ? this.translateService.instant(`eventRegistration.state.${invitation.noProductsState}`)
        : '',
      'Stato invito prodotti': invitation.productsState
        ? this.translateService.instant(`eventRegistration.state.${invitation.productsState}`)
        : '',
      'Data inizio': new DatePipe('en_US').transform(invitation.event.startDate, 'dd/MM/yyyy'),
      'Data Fine': new DatePipe('en_US').transform(invitation.event.endDate, 'dd/MM/yyyy'),
      'Data chiusura iscrizioni': new DatePipe('en_US').transform(
        invitation.event.registrationEndDate,
        'dd/MM/yyyy'
      ),
      'Azienda (catalogo)': this.commonService.capitalize(
        get(invitation, 'user.canteen.businessName', '')
      ),
      'Gruppo (catalogo)': this.commonService.capitalize(get(invitation, 'user.canteen.group', '')),
      'Indirizzo (catalogo)': this.commonService.capitalize(
        get(invitation, 'user.canteen.address', '')
      ),
      'CAP (catalogo)': this.commonService.capitalize(
        get(invitation, 'user.canteen.postalCode', '')
      ),
      'Città (catalogo)': this.commonService.capitalize(get(invitation, 'user.canteen.city', '')),
      'Stato (catalogo)': this.commonService.capitalize(
        get(invitation, 'user.canteen.country', '')
      ),
      'Regione (catalogo)': get(invitation, 'user.canteen.district', ''),
      'Provincia (catalogo)': this.commonService.capitalize(
        get(invitation, 'user.canteen.province', '')
      ),
      'Telefono (catalogo)': get(invitation, 'user.canteen.phone', ''),
      'Fax (catalogo)': get(invitation, 'user.canteen.fax', ''),
      'Email aziendale (catalogo)': get(
        invitation,
        'user.canteen.corporateEmail',
        ''
      ).toLowerCase(),
      'Sito web (catalogo)':
        invitation.user && invitation.user.canteen && invitation.user.canteen.webSite
          ? invitation.user.canteen.webSite
          : ''
    };

    invitation.products.forEach((product, i) => {
      if (product) {
        row['Nome (prodotto) ' + (i + 1)] = this.commonService.capitalize(get(product, 'name', ''));
        row['Regione (prodotto) ' + (i + 1)] = get(product, 'region', '');

        const category = product.category;

        if (category === 'wine') {
          row['Tipologia (prodotto) ' + (i + 1)] = has(product, 'wineTechnical.wineType')
            ? this.translateService.instant('product.wineType.' + product.wineTechnical.wineType)
            : '';
          row['Codice (prodotto) ' + (i + 1)] = get(product, 'codeImport', '');
          row['Denominazione (prodotto) ' + (i + 1)] = this.commonService.capitalize(
            product.wineTechnical.areaDenomination
          );
          row['Denominazione Manuale (prodotto) ' + (i + 1)] = this.commonService.capitalize(
            product.wineTechnical.manualAreaDenomination || ''
          );
          row['Classificazione (prodotto) ' + (i + 1)] = (
            (product.wineTechnical
              ? product.wineTechnical.denomination
              : product.foodTechnical
              ? product.foodTechnical.denomination
              : '') || ''
          ).toUpperCase();
          row['Annata (prodotto) ' + (i + 1)] =
            product.wineTechnical && product.wineTechnical.years
              ? product.wineTechnical.years.map(year => year.year).join('; ')
              : '';
          row['Tipologia spumante (prodotto) ' + (i + 1)] =
            has(product, 'wineTechnical.sparklingType') && product.wineTechnical.sparklingType
              ? this.translateService.instant(
                  'category.sparkling.' + product.wineTechnical.sparklingType
                )
              : '';
          row['Affinamento (prodotto) ' + (i + 1)] = product.wineTechnical.aging
            .map(aging => this.translateService.instant(`product.aging.${aging}`))
            .join(';');
          row['Varietà (prodotto) ' + (i + 1)] =
            product.wineTechnical.variety.reduce((str, variety) => {
              if (variety.name && variety.name !== '') {
                return `${str} ${variety.name} ${variety.perc},`;
              }
              return str;
            }, '') || '';
          row['Tipo agricoltura (prodotto) ' + (i + 1)] =
            product.wineTechnical.agricultureType || '';
          row['Microzona (prodotto) ' + (i + 1)] = product.wineTechnical.microzone || '';
          row['Specificazione (prodotto) ' + (i + 1)] = product.wineTechnical.specification || '';
          row['Altra Specificazione (prodotto) ' + (i + 1)] =
            product.wineTechnical.otherSpecification || '';
          row['Voto (prodotto) ' + (i + 1)] =
            product.board && product.board.finalEvaluation ? product.board.finalEvaluation : '';
        } else if (category === 'spirit') {
          row['Denominazione (prodotto) ' + (i + 1)] = this.commonService.capitalize(
            get(product, 'spiritTechnical.denomination', '')
          );
          row['Tipologia (prodotto) ' + (i + 1)] =
            has(product, 'spiritTechnical.spiritCategory') && product.spiritTechnical.spiritCategory
              ? this.translateService.instant(
                  'product.spiritCategory.' + product.spiritTechnical.spiritCategory
                )
              : '';
        } else if (category === 'food') {
          row['Classificazione (prodotto) ' + (i + 1)] = has(product, 'foodTechnical.denomination')
            ? this.translateService.instant(
                'product.beerDenomination.' + product.foodTechnical.denomination
              )
            : '';
          row['Tipologia (prodotto) ' + (i + 1)] = has(product, 'foodTechnical.categoryFood')
            ? this.translateService.instant('category.food.' + product.foodTechnical.categoryFood)
            : '';
        } else if (category === 'beer') {
          row['Tipologia / stile (prodotto) ' + (i + 1)] = this.commonService.capitalize(
            get(product, 'beerTechnical.type', '')
          );
          row['Colore (prodotto) ' + (i + 1)] = this.commonService.capitalize(
            get(product, 'beerTechnical.color', '')
          );
        }
      }
    });

    return row;
  }

  groupBy(xs, key) {
    return xs.reduce((rv, x) => {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  }

  exportExcel(items, fileTitle) {
    const ws = XLSX.utils.json_to_sheet(items);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, fileTitle);
    XLSX.writeFile(wb, fileTitle + '.xlsx');
  }

  /**
   * Receives a file of the specified product's category and sends it to the ro REST endpoints that
   * will process it.
   *
   * @param file The actual XLSX file that'll be processed
   * @param category The product's file's actual category
   */
  importExcel(file: File, category: string) {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);

    return this.http.post(`${environment.server}/v2/import/products/${category}`, formData);
  }

  exportProducts(category: string = 'wine', searchOptions: any) {
    const { page, limit } = searchOptions;
    return this.http
      .post(
        `${environment.server}/v2/export/products/${category}?page=${page}&limit=${limit}`,
        searchOptions,
        {
          responseType: 'arraybuffer'
        }
      )
      .pipe(
        take(1),
        tap(res => {
          let binary = '';
          const bytes = new Uint8Array(res);
          const len = bytes.byteLength;
          for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
          }

          const url = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset:UTF-8;base64,${window.btoa(
            binary
          )}`;

          const downloadLink = document.createElement('a');
          const fileName = `Products ${category}.xlsx`;

          downloadLink.href = url;
          downloadLink.download = fileName;
          downloadLink.click();
        })
      );
  }
}
