import { Component, OnInit } from '@angular/core';
import { ModalService } from '../../core/modal.service';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../core/user.service';

@Component({
  selector: 'app-verify',
  templateUrl: './verify.component.html'
})
export class VerifyComponent implements OnInit {
  private key: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private modalService: ModalService,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.key = this.route.snapshot.queryParamMap.get('q');

    if (this.key) {
      this.userService
        .verifyUser(this.key)
        .toPromise()
        .then(_ => {
          this.modalService.success(this.translateService.instant('verify.success'), 30000);
          this.router.navigate(['/login']);
        })
        .catch(_ => this.modalService.error(this.translateService.instant('general.error'), 30000));
    } else {
      this.router.navigate(['/login']);
    }
  }
}
