import {Component, OnInit} from '@angular/core';
import {BsModalRef} from "ngx-bootstrap/modal";

@Component({
    selector: 'app-privacy-policy-modal',
    templateUrl: './privacy-policy-modal.component.html',
    styleUrls: ['./privacy-policy-modal.component.scss']
})
export class PrivacyPolicyModalComponent implements OnInit {

    isModal: boolean = false;

    constructor(private modal: BsModalRef) {
    }

    ngOnInit() {
    }

    close() {
        this.modal.hide()
    }

}
