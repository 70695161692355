import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  constructor() {}

  objectToQueryParams(obj: any) {
    return Object.keys(obj)
      .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`)
      .join('&');
  }

  getNgxEditorConfig() {
    return {
      minHeight: '300',
      toolbar: [
        ['bold', 'italic', 'underline', 'strikeThrough', 'superscript', 'subscript'],
        ['fontSize'],
        ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
        ['removeFormat', 'undo', 'redo'],
        [
          'paragraph',
          'blockquote',
          'removeBlockquote',
          'horizontalLine',
          'orderedList',
          'unorderedList'
        ],
        ['link', 'unlink']
      ]
    };
  }

  formatDate(date) {
    const newDate = new Date(date);
    return newDate.getDate() + '-' + (newDate.getMonth() + 1) + '-' + newDate.getFullYear();
  }

  isNumberStringValid(strTovalidate: string) {
    console.log('String checked', JSON.stringify(strTovalidate));
    return /^[0-9]+([,.][0-9]+)?$/.test(strTovalidate); // || string === "" || string === null)
  }

  isNumberStringValidOrNull(strToValidate: string) {
    console.log('String checked', JSON.stringify(strToValidate));
    return (
      /^[0-9]+([,.][0-9]+)?$/.test(strToValidate) || strToValidate === '' || strToValidate === null
    );
  }

  stringToFloat(field: string) {
    return parseFloat((field + '').replace(',', '.'));
  }

  capitalize(strToCapitalize: string) {
    if (!strToCapitalize) {
      return '';
    }
    strToCapitalize = strToCapitalize + ''; // trasforma eventuali numeri in stringa
    strToCapitalize = strToCapitalize.toLowerCase();
    let splitted = strToCapitalize.split(' ');
    splitted = splitted.map(el => {
      if (el.indexOf("'") > -1) {
        let spl = el.split("'");
        spl = spl.map(s => {
          return s.replace(/\w/, c => c.toUpperCase());
        });
        el = spl.join("'");
      }
      return el.replace(/\w/, c => c.toUpperCase());
    });

    return splitted.join(' ').trim();
  }

  uppercase(strToUppercase: string) {
    if (!strToUppercase) {
      strToUppercase = '';
    }
    strToUppercase += '';

    return strToUppercase.toUpperCase();
  }

  isInternetExplorer() {
    let resp = 0;

    const ua = window.navigator.userAgent;
    if (ua.indexOf('Trident/7.0') > -1) resp = 11;
    else if (ua.indexOf('Trident/6.0') > -1) resp = 10;
    else if (ua.indexOf('Trident/5.0') > -1) resp = 9;
    else if (ua.indexOf('Edge') > -1) resp = 1;
    else resp = 0; // not IE9, 10 or 11

    return resp > 0;
  }

  isUrlToHideSpinner(url: string, hideSpinner: boolean = false): boolean {
    // TODO: implements a better way to disable spinner
    return url.includes('api/voucher/event/') || url.includes('api/eventService') || hideSpinner;
  }
}
