<div class="custom-file">
  <input
    #fileInput
    type="file"
    class="custom-file-input"
    id="customFile"
    (change)="onFileChange($event)"
    data-buttonText="Your label here."
  />
  <label class="custom-file-label" for="customFile">{{
    placeHolder || ('eventWizard.upload.change' | translate)
  }}</label>
</div>
