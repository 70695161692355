import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'noHtml'
})
export class NoHtmlPipe implements PipeTransform {

    transform(value: any, args?: any): any {
        if (!value) {
            return ""
        }

        return String(value).replace(/<[^>]+>/gm, '')
    }

}
