import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {EventRegistrationService} from "../../core/event-registration.service";

@Component({
    selector: 'app-user-confirmed-event-registration-checker',
    templateUrl: './user-confirmed-event-registration-checker.component.html',
    styleUrls: ['./user-confirmed-event-registration-checker.component.scss']
})
export class UserConfirmedEventRegistrationCheckerComponent implements OnInit, OnChanges {

    @Input() userId: string;
    incompleteConfirmedEventRegistrations: any[] = [];

    constructor(private eventRegistrationService: EventRegistrationService,) {
    }

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.userId) {
            this.searchConfirmedEventRegistrations();
        }
    }

    private searchConfirmedEventRegistrations() {
        this.eventRegistrationService.search(1, 10000, {
            filterBy: {
                state: "confirmed"
            }
        }).subscribe((r: any) => {
            this.checkIncompleteEventRegistrations(r.response);
        })
    }

    private checkIncompleteEventRegistrations(eventRegistrations: any[]) {
        this.incompleteConfirmedEventRegistrations = eventRegistrations.filter(eventRegistration => {
            let includeThis = false;
            if (eventRegistration.event.state == "created") {
                if (!eventRegistration.passes || eventRegistration.passes.length === 0) {
                    includeThis = true
                }
                if (!eventRegistration.productsHandling || eventRegistration.productsHandling === 'none') {
                    includeThis = true
                }
            }
            return includeThis
        })

        console.log("incomplete confirmed event registration", this.incompleteConfirmedEventRegistrations)
    }
}
