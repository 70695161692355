import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { PaymentService } from 'core/payment.service';

@Component({
  selector: 'app-process',
  templateUrl: './process.component.html',
  styleUrls: ['./process.component.scss']
})
export class ProcessComponent implements OnInit {
  localPayment: string;
  eventRegistrationId: string;
  callbackUrl: string[];
  subject: string;
  items: string;

  constructor(private route: ActivatedRoute, private router: Router, private paymentService: PaymentService) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.localPayment = params.localPayment;
      this.eventRegistrationId = params.eventRegistration;


      if (this.localPayment && this.eventRegistrationId) {
        this.paymentService.preConfirm(this.localPayment, null).subscribe(() => {
          this.paymentService.get(this.localPayment).subscribe((res: any) => {
            console.log(res);
            if (res) {
              if (res.object === 'registration') {
                this.subject = `Iscrizione evento: ${res.eventRegistration.event.name}`;
                this.items = res.stripeItems.map(item => `${item.quantity}x ${item.name} € ${(item.amount / 100).toFixed(2)}`)
                this.callbackUrl = [
                  'dashboard',
                  'event'
                ];
              } else {
                this.subject = `Acquisto biglietti per evento: ${res.eventRegistration.event.name}`;
                this.items = res.stripeItems.map(item => `${item.quantity}x ${item.name} € ${(item.amount / 100).toFixed(2)}`)
                this.callbackUrl = [
                  'dashboard',
                  'event-registration',
                  this.eventRegistrationId,
                  'organization'
                ];
              }
            }
          })
        });


      }


    });
  }

  goBack() {
    this.router.navigate(this.callbackUrl);
  }
}
