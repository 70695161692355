<div class="register uk-height-viewport">
  <app-lang-navbar></app-lang-navbar>

  <div class="m-modal">
    <div class="logo">
      <img src="/assets/img/logo.png" />
    </div>

    <div class="title">{{ 'changepassword.title' | translate }}</div>

    <form #passwordForm="ngForm" [ngClass]="{ submitted: passwordForm.submitted }" novalidate>
      <div class="uk-grid uk-grid-small">
        <div class="uk-width-medium-1-1">
          <label class="m-label"> {{ 'changepassword.password' | translate }}</label>
          <input type="password" name="password" [(ngModel)]="password" class="m-input" required />
        </div>
        <div class="uk-width-medium-1-1">
          <label class="m-label"> {{ 'changepassword.password2' | translate }}</label>
          <input
            type="password"
            name="password2"
            [(ngModel)]="password2"
            class="m-input"
            required
          />
        </div>
      </div>

      <div class="bottom-buttons">
        <div class="uk-grid uk-grid-small">
          <div class="uk-width-medium-1-2">
            <button class="m-button m-button--disabled" routerLink="/login">
              {{ 'general.indietro' | translate }}
            </button>
          </div>
          <div class="uk-width-medium-1-2">
            <button class="m-button" (click)="changePassword()">
              {{ 'changepassword.ok' | translate }}
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>

<app-copyright></app-copyright>
