import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalService } from '../../core/modal.service';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../../core/user.service';

@Component({
  selector: 'app-recovery',
  templateUrl: './recovery.component.html',
  styleUrls: ['./recovery.component.scss']
})
export class RecoveryComponent implements OnInit {
  @ViewChild('formRecovery') formRecovery;
  email = '';

  constructor(
    private modalService: ModalService,
    private translateService: TranslateService,
    private userService: UserService
  ) {}

  ngOnInit() {}

  recoverPassword() {
    this.formRecovery.submitted = this.formRecovery.invalid;

    if (this.formRecovery.invalid) {
      this.modalService.error(this.translateService.instant('general.form.error'));
      return;
    }

    this.userService.recoverPassword(this.email).subscribe(
      (res: any) => {
        console.log('Recover password response:', res);
        this.modalService.success(this.translateService.instant('recovery.success'), 20000);
      },
      err => {
        if (err.error.err === 'USER.NOT.FOUND') {
          this.modalService.error(this.translateService.instant('recovery.error'));
        } else {
          this.modalService.error(this.translateService.instant('general.error'));
        }
      }
    );
  }
}
