<div class="datepicker-wrapper">
  <input
    class="datepicker-input {{ innerClass }}"
    [ngClass]="{ 'datepicker-invisible': hideInput }"
    #dp="bsDatepicker"
    bsDatepicker
    (ngModelChange)="changeDate($event)"
    [bsConfig]="{
      dateInputFormat: 'DD/MM/YYYY',
      containerClass: 'theme-default',
      showWeekNumbers: false
    }"
    [(ngModel)]="date"
  />
  <i *ngIf="withClear" (click)="clearDate()" class="reset-date ti-close"></i>
</div>
