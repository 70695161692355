import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild
} from "@angular/core";
import { EnumService } from "../../../core/enum.service";
import { ModalService } from "../../../core/modal.service";
import { TranslateService } from "@ngx-translate/core";
import { UserService } from "../../../core/user.service";

@Component({
  selector: "app-registration-step-account",
  templateUrl: "./registration-step-account.component.html",
  styleUrls: ["./registration-step-account.component.scss"]
})
export class RegistrationStepAccountComponent implements OnInit, OnChanges {
  @Input() user: any = {};
  @Output() userCategoryChanged = new EventEmitter();
  @ViewChild("form") form;
  userCategories: any[];
  password2: string;
  emailUniqueChecked: boolean = false;
  emailIsUnique: boolean = true;

  constructor(
    private modalService: ModalService,
    private translateService: TranslateService,
    private userService: UserService,
    private enumService: EnumService
  ) {}

  ngOnInit() {
    this.userCategories = this.enumService.getUserCategories();
  }

  ngOnChanges() {
    if (!this.user || Object.keys(this.user).length == 0) {
      this.user = {};
    }
    if (this.user.password) {
      this.password2 = this.user.password;
    }
  }

  changeCategory() {
    this.userCategoryChanged.emit(this.user.category);
  }

  isValid() {
    return this.form.valid;
  }

  async validate() {
    this.form.submitted = true;
    console.log("Account validating", this.form.valid);
    if (!this.isValid()) {
      if (this.form.controls.email && this.form.controls.email.invalid) {
        this.modalService.error(this.translateService.instant('general.form.error.invalidmail'));
      } else {
        this.modalService.error(
          this.translateService.instant("general.form.error")
        );
      }
      return false;
    }

    if (this.user.password !== this.password2) {
      this.modalService.error(
        this.translateService.instant("changepassword.mismatch")
      );
      return false;
    }

    await this.checkEmailIsUnique();

    if (this.emailUniqueChecked && !this.emailIsUnique) {
      this.modalService.error(
        this.translateService.instant("registration.email.alreadyExists")
      );
      return false;
    }

    return true;
  }

  getUser() {
    return this.user;
  }

  async checkEmailIsUnique() {
    const user: any = await this.userService
      .verifyEmail(this.user.email)
      .toPromise();

    if (user && user.response && user.response.exists) {
      console.log("Email already exists:");
      this.emailUniqueChecked = true;
      this.emailIsUnique = false;
    } else {
      this.emailUniqueChecked = true;
      this.emailIsUnique = true;
    }
  }
}
