import {Injectable} from '@angular/core';
import { cloneDeep } from 'lodash';

@Injectable({
    providedIn: 'root'
})
export class SearchCacheService {

    private usersCache: any;
    private productsCache: any;
    private evaluationsSearch: any;
    private commissionsSearch: any;
    private eventInvitationsSearch: any;
    private eventRegistrationSearch: any;
    private eventsSearch: any;

    constructor() {
    }

    reset() {
        this.productsCache = null;
        this.usersCache = null;
        this.evaluationsSearch = null;
        this.commissionsSearch = null;
        this.eventInvitationsSearch = null;
        this.eventRegistrationSearch = null;
        this.eventsSearch = null;
    }

    setUsersSearch(search: any) {
        this.usersCache = cloneDeep(search)
    }

    getUsersSearch() {
        return cloneDeep(this.usersCache)
    }

    getProductsSearch() {
        return cloneDeep(this.productsCache)
    }

    setProductsSearch(lastProductsSearch) {
        this.productsCache = cloneDeep(lastProductsSearch)
    }

    getEvaluationsSearch() {
        return cloneDeep(this.evaluationsSearch)
    }

    setEvaluationsSearch(lastEvaluationsSearch) {
        this.evaluationsSearch = cloneDeep(lastEvaluationsSearch)
    }

    getCommissionsSearch() {
        return cloneDeep(this.commissionsSearch)
    }

    setCommissionsSearch(lastCommissionsSearch) {
        this.commissionsSearch = cloneDeep(lastCommissionsSearch)
    }

    setEventInvitationsSearch (search) {
        this.eventInvitationsSearch = cloneDeep(search);
    }

    getEventInvitationsSearch () {
        return cloneDeep(this.eventInvitationsSearch);
    }

    setEventRegistrationsSearch (search) {
        this.eventRegistrationSearch = cloneDeep(search);
    }

    getEventRegistrationsSearch () {
        return cloneDeep(this.eventRegistrationSearch);
    }

    setEventsSearch (search) {
        this.eventsSearch = cloneDeep(search);
    }

    getEventsSearch () {
        return cloneDeep(this.eventsSearch);
    }
}
