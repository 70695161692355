import { Component, OnInit } from '@angular/core';
import { ModalService } from '../../core/modal.service';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../../core/user.service';
import { StoreService } from '../../core/store.service';
import { PrivacyPolicyModalComponent } from '../../shared/privacy-policy-modal/privacy-policy-modal.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AuthenticationService } from '../../core/authentication.service';

@Component({
  selector: 'app-privacy-check',
  templateUrl: './privacy-check.component.html'
})
export class PrivacyCheckComponent implements OnInit {
  privacyAccepted = false;
  userType: string;

  constructor(
    private modalService: ModalService,
    private translateService: TranslateService,
    private authService: AuthenticationService,
    private userService: UserService,
    private storeService: StoreService,
    private modal: BsModalService
  ) {}

  ngOnInit() {
    this.userType = this.storeService.get('userType');
  }

  openPrivacy() {
    this.modal.show(PrivacyPolicyModalComponent, {
      initialState: {
        isModal: true
      }
    });
  }

  submitPrivacyChoice() {
    if (this.privacyAccepted === true) {
      this.userService.acceptPrivacy().subscribe(
        _ => this.authService.setAllowedRouting(),
        _ => this.modalService.error(this.translateService.instant('general.error'), 30000)
      );
    } else {
      this.modalService.error(this.translateService.instant('privacy.error'), 30000);
    }
  }
}
