import { Component, OnInit } from '@angular/core';
import { StoreService } from '../../core/store.service';
import { UserService } from '../../core/user.service';
import { AuthenticationService } from '../../core/authentication.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent implements OnInit {
  profileIncomplete: boolean = true;
  userType: string;

  constructor(
    private storeService: StoreService,
    private userService: UserService,
    private authenticationService: AuthenticationService,
    private router: Router
  ) {}

  ngOnInit() {
    this.profileIncomplete = this.storeService.get('profileIncomplete');
    this.userType = this.storeService.get('userType');

    console.log('User type', this.userType, this.profileIncomplete);
  }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }
}
