import {Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild} from '@angular/core';
import {ControlValueAccessor} from "@angular/forms";
import {BsLocaleService} from "ngx-bootstrap/datepicker";

@Component({
  selector: 'app-daterangepicker',
  templateUrl: './daterangepicker.component.html',
  styleUrls: ['./daterangepicker.component.scss']
})
export class DaterangepickerComponent implements OnInit, ControlValueAccessor, OnChanges {
  @Input() options;
  @Input() innerClass: string = '';
  @Input() date: any;
  @Input() hideInput: boolean = false;
  @Output() dateChange = new EventEmitter<Date>();

  @ViewChild('dp') bsDaterangepicker;

  constructor(private bsLocaleService: BsLocaleService) {
  }

  ngOnInit() {
    this.bsLocaleService.use("it")
  }

  ngOnChanges() {
    if (this.date && typeof this.date === 'string') {
      this.date = new Date(this.date);
    }
  }
  public show() {
    this.bsDaterangepicker.show()
  }

  changeDate(newDaterange) {
    this.date = newDaterange;
    this.date[0].setHours(0, 0, 0);
    this.date[1].setHours(0, 0, 0);
    this.dateChange.emit(this.date);
  }

  onTouched = () => {
  };

  onChange(event) {
    this.date = event;
  }

  writeValue(value: any): void {
    this.date = value;
    this.onChange(this.date);
  }

  registerOnChange(fn: (rating: number) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }
}

