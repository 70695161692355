import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { LangNavbarComponent } from './lang-navbar/lang-navbar.component';
import { HeaderComponent } from './header/header.component';
import { SideMenuComponent } from './side-menu/side-menu.component';
import { CopyrightComponent } from './copyright/copyright.component';
import { PrivacyPolicyModalComponent } from './privacy-policy-modal/privacy-policy-modal.component';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { TableSortableHeaderComponent } from './table-sortable-header/table-sortable-header.component';
import { PaginationComponent } from './pagination/pagination.component';
import { FormsModule } from '@angular/forms';
import { DotPipe } from './pipes/dot.pipe';
import { NoHtmlPipe } from './pipes/no-html.pipe';
import { NgxEditorModule } from './ngx-editor/ngx-editor.module';
import { DatepickerModule } from './datepicker/datepicker.module';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { DaterangepickerModule } from './daterangepicker/daterangepicker.module';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { AutocompleteComponent } from './autocomplete/autocomplete.component';
import { ProductsSelectorComponent } from './products-selector/products-selector.component';
import { ProductsVisualizerComponent } from './products-visualizer/products-visualizer.component';
import { UserConfirmedEventRegistrationCheckerComponent } from './user-confirmed-event-registration-checker/user-confirmed-event-registration-checker.component';
import { AwardIconComponent } from './award-icon/award-icon.component';
import { TwaSurveyModalComponent } from './twa-survey-modal/twa-survey-modal.component';
import { JoinByPipe } from './pipes/join-by.pipe';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { FormProgressModule } from './form-progress/form-progress.module';
import { AddProductModalModule } from './add-product-modal/add-product-modal.module';
import { UploadImageBoxModule } from './upload-image-box/upload-image-box.module';
import { DirectivesModule } from './directives/directives.module';
import { UsersAutocompleteModule } from './users-autocomplete/users-autocomplete.module';

import { defineLocale } from 'ngx-bootstrap/chronos';
import { itLocale } from 'ngx-bootstrap/locale';
import { TagInputModule } from 'ngx-chips';
import { NgxSelectModule } from 'ngx-select-ex';
import { RegulationModalComponent } from './regulation-modal/regulation-modal.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { EvaluationHistoryModalComponent } from './evaluation-history-modal/evaluation-history-modal.component';
import { RegistrationPaymentInfoModalComponent } from './registration-payment-info-modal/registration-payment-info-modal.component';

defineLocale('it', itLocale);

@NgModule({
  declarations: [
    LangNavbarComponent,
    HeaderComponent,
    SideMenuComponent,
    CopyrightComponent,
    PrivacyPolicyModalComponent,
    TableSortableHeaderComponent,
    PaginationComponent,
    DotPipe,
    NoHtmlPipe,
    AutocompleteComponent,
    ProductsSelectorComponent,
    ProductsVisualizerComponent,
    UserConfirmedEventRegistrationCheckerComponent,
    AwardIconComponent,
    TwaSurveyModalComponent,
    JoinByPipe,
    RegulationModalComponent,
    FileUploadComponent,
    EvaluationHistoryModalComponent,
    RegistrationPaymentInfoModalComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule,
    FormsModule,
    NgxEditorModule,
    DatepickerModule,
    DaterangepickerModule,
    TooltipModule.forRoot(),
    AutocompleteLibModule,
    PerfectScrollbarModule,
    TranslateModule,
    FormProgressModule,
    AddProductModalModule,
    UploadImageBoxModule,
    DirectivesModule,
    UsersAutocompleteModule,
    TagInputModule,
    NgxSelectModule
  ],
  exports: [
    NgxEditorModule,
    LangNavbarComponent,
    HeaderComponent,
    SideMenuComponent,
    CopyrightComponent,
    PrivacyPolicyModalComponent,
    TableSortableHeaderComponent,
    PaginationComponent,
    DotPipe,
    NoHtmlPipe,
    UploadImageBoxModule,
    DatepickerModule,
    DaterangepickerModule,
    TooltipModule,
    AutocompleteLibModule,
    AutocompleteComponent,
    ProductsSelectorComponent,
    ProductsVisualizerComponent,
    DatePipe,
    UserConfirmedEventRegistrationCheckerComponent,
    AwardIconComponent,
    TwaSurveyModalComponent,
    JoinByPipe,
    TranslateModule,
    FormsModule,
    RouterModule,
    FormProgressModule,
    AddProductModalModule,
    DirectivesModule,
    UsersAutocompleteModule,
    NgxSelectModule
  ],
  entryComponents: [PrivacyPolicyModalComponent, TwaSurveyModalComponent],
  providers: [DatePipe]
})
export class SharedModule {}
