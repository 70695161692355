<div class="register uk-height-viewport">
  <app-lang-navbar></app-lang-navbar>

  <div class="m-modal">
    <div class="logo"><img src="/assets/img/logo.png" /></div>

    <div>
      <div class="title">{{ 'privacy.title' | translate }}</div>

      <div class="info">
        {{ 'privacy.text' | translate }}
        <a (click)="openPrivacy()">{{ 'privacy.policy' | translate }}</a>
      </div>

      <div class="bottom-buttons">
        <div class="uk-grid">
          <div class="uk-width-1-1">
            <label>
              <input type="checkbox" [(ngModel)]="privacyAccepted" />
              {{ 'privacy.disclaimer' | translate }}
            </label>
          </div>
          <div class="uk-width-1-1 uk-text-center">
            <br />
            <br />
            <button class="m-button m-button--inline" (click)="submitPrivacyChoice()">
              {{ 'privacy.next' | translate }}
            </button>
          </div>
        </div>
      </div>
      <br />
      <br />
    </div>
  </div>

  <app-copyright></app-copyright>
</div>
