<form #form="ngForm" [ngClass]="{ 'submitted': form.submitted }">
  <div class="uk-grid uk-grid-small">
    <div class="uk-width-small-1-2">
      <label class="m-label">{{"nome" | translate}} *</label>
      <input type="text"
             class="m-input"
             name="referentName"
             [(ngModel)]="user.referent.name"
             required>
    </div>
    <div class="uk-width-small-1-2">
      <label class="m-label">{{"cognome" | translate}} *</label>
      <input type="text"
             class="m-input"
             name="referentSurname"
             [(ngModel)]="user.referent.surname"
             required>
    </div>
    <div class="uk-width-1-1">
      <label class="m-label">{{"ruolo" | translate}} *</label>
      <input type="text"
             class="m-input"
             name="referentRole"
             [(ngModel)]="user.referent.role"
             required>
    </div>
    <div class="uk-width-small-1-2">
      <label class="m-label">{{"email" | translate}} *</label>
      <input type="email"
             class="m-input"
             name="referentEmail"
             [(ngModel)]="user.referent.email"
             required
             email>
    </div>
    <div class="uk-width-small-1-2">
      <div class="uk-grid uk-grid-small">
        <div class="uk-width-small-1-1">
          <label class="m-label">{{"telefono" | translate}} *</label>
        </div>
        <div class="uk-width-small-2-10">
          <input type="text"
                class="m-input"
                (keypress)="checkPhoneNumber($event)"
                (paste)="checkPhoneNumber($event)"
                name="referentPrefixPhone"
                [(ngModel)]="user.referent.prefixPhone"
                required>
        </div>
        <div class="uk-width-small-8-10">
          <input type="text"
                class="m-input"
                (keypress)="checkPhoneNumber($event)"
                (paste)="checkPhoneNumber($event)"
                name="referentPhone"
                [(ngModel)]="user.referent.phone"
                required>
        </div>
      </div>
    </div>
    <div class="uk-width-1-1" *ngIf="user.category === 'wine'">
      <div class="terms">
        <label class="m-label m-label__statement">
          {{"gdpr.confirmation" | translate}}
        </label>
      </div>
    </div>
  </div>
  <div class="fields-required">
    {{ "campi.obbligatori" | translate }}
  </div>
</form>
