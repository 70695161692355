import { Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { EnumService } from '../../../core/enum.service';
import { ModalService } from '../../../core/modal.service';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-step6',
  templateUrl: './step6.component.html',
  styleUrls: ['./step6.component.scss']
})
export class Step6Component implements OnInit, OnChanges {
  @Input() product: any = {};
  @Input() productDetailMode: boolean;
  @Input() readonly = false;
  @Input() varieties: any[];
  @ViewChild('form') form;
  column = 'uk-width-1-1';

  constructor(
    private enumService: EnumService,
    private translateService: TranslateService,
    private modalService: ModalService
  ) {}

  ngOnInit() {}

  ngOnChanges(): void {
    if (this.productDetailMode) {
      this.column = 'uk-width-small-1-2';
    }
  }

  isValid() {
    return this.form.valid;
  }

  validate() {
    this.form.submitted = true;
    if (!this.isValid()) {
      this.modalService.showFormError();
      return false;
    }

    let totalPerc = 0;
    let missingVariety = false;
    if (this.product.wineTechnical.variety) {
      totalPerc = this.product.wineTechnical.variety.reduce(
        (acc, variety) => acc + parseInt(variety.perc, 10),
        0
      );
    } else {
      missingVariety = true;
    }

    if (missingVariety) {
      this.modalService.error(this.translateService.instant('general.form.error.wineVarietyName'));
      return;
    }

    if (totalPerc !== 100) {
      this.modalService.error(this.translateService.instant('general.form.error.wineVarietyPerc'));
      return;
    }

    return true;
  }

  getData() {
    return this.product;
  }

  removeVariety($index: any) {
    this.product.wineTechnical.variety.splice($index, 1);
  }

  addVariety() {
    if (!this.product.wineTechnical.variety) {
      this.product.wineTechnical.variety = [];
    }

    this.product.wineTechnical.variety.push({
      name: '',
      perc: 0
    });
  }
}
