import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UserService} from "../../core/user.service";

@Component({
    selector: 'app-users-autocomplete',
    templateUrl: './users-autocomplete.component.html',
    styleUrls: ['./users-autocomplete.component.scss']
})
export class UsersAutocompleteComponent implements OnInit {

    searchKey: string = "";
    users: any[] = [];
    selectedUser: any = {};
    loading: boolean = false;

    @Input() searchObject: any = {};
    @Output() userSelected: EventEmitter<any> = new EventEmitter();

    constructor(private userService: UserService) {
    }

    ngOnInit() {
    }

    searchUser(key) {
        this.loading = true;
        let searchObject = this.searchObject;
        if (!searchObject.filterBy) {
            searchObject.filterBy = {};
        }
        searchObject.filterBy.businessName = key;

        this.userService.getAllBasic(1, 30, searchObject).subscribe((res: any) => {
            this.users = res.response;
            this.loading = false;
        }, err => {
            this.loading = false
        })
    }

    selectUser(user) {
        this.selectedUser = user;
        this.users = [];
        this.searchKey = "";

        this.userSelected.emit(this.selectedUser)
    }

}
