<form #form="ngForm" [ngClass]="{ 'submitted': form.submitted }" novalidate>
    <div>
        <span>{{"dati.immagine.hint" | translate}}</span>
        <br>
        <app-upload-image-box (fileUploaded)="addUserImage($event)"
                              (fileDeleted)="user.image = null"
                              [uploadType]="'image'"
                              [previewImage]="true"
                              [attachment]="user.image"
                              [title]="'product.image.upload' | translate"
                              [uploadPath]="'/user/image'"
                              [imageRelativePath]="'/user/image/'">
        </app-upload-image-box>
    </div>
    <div *ngIf="user.category !== 'wine'">
        <div class="terms">
            <label class="m-label m-label__statement">
                {{"gdpr.confirmation" | translate}}
            </label>
        </div>
    </div>
</form>
