import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UsersAutocompleteComponent} from './users-autocomplete.component';
import {TranslateModule} from "@ngx-translate/core";
import {FormsModule} from "@angular/forms";

@NgModule({
    declarations: [UsersAutocompleteComponent],
    imports: [
        CommonModule,
        TranslateModule,
        FormsModule
    ],
    exports: [
        UsersAutocompleteComponent
    ]
})
export class UsersAutocompleteModule {
}
