<div class="my-autocomplete">
    <div class="row">
        <div class="col-sm-9">
            <input type="text" class="m-input"
                   [placeholder]="inputPlaceholder"
                   [ngModelOptions]="{standalone: true}"
                   [(ngModel)]="companyToken"
                   required>
            <ul class="uk-list uk-list-striped scrollable" *ngIf="companyList?.length > 0">
                <li class="m-clickable" *ngFor="let company of companyList"
                    (click)="selectItem(company)">
                    {{ company.canteen?.businessName || company.society?.businessName }}
                </li>
            </ul>
            <div class="uk-text-danger"
                 *ngIf="companyList !== null && companyList?.length === 0 && companyToken">
                {{ "event-invitation.searchCompanyToken.nodata" | translate }}
            </div>
        </div>
        <div class="col-sm-3">
            <button class="m-button" type="button"
                    [disabled]="!invitationData.event || !companyToken"
                    (click)="searchCompanyByName(companyToken)">
                {{"general.search" | translate}}
            </button>
        </div>
    </div>
</div>