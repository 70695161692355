import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { UserService } from '../../../../core/user.service';

@Component({
  selector: 'app-user-autocomplete',
  templateUrl: './user-autocomplete.component.html',
  styleUrls: ['./user-autocomplete.component.scss']
})
export class UserAutocompleteComponent implements OnInit, OnChanges {
  @Input() userType: string;
  @Input() product: any = {};
  @Input() productDetailMode: boolean;
  @Output() userChange: EventEmitter<string> = new EventEmitter<string>();
  searchedUserToken: string;
  usernameList: any[] = [];
  selectedUser: any;
  column = 'uk-width-1-1';

  constructor(private userService: UserService) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.productDetailMode) {
      this.column = 'uk-width-small-1-2';
    }
  }

  searchUser(searchedUserToken: any) {
    console.log('Search user', searchedUserToken);
    this.userService
      .getAllBasic(1, 30, {
        filterBy: {
          businessName: searchedUserToken,
          userType: 'supplier'
        }
      })
      .subscribe((res: any) => {
        this.usernameList = res.response;
      });
  }

  chooseUser(user: any) {
    this.product.user = user._id;
    this.selectedUser = user.canteen.businessName || user.society.businessName || user.username;
    this.usernameList = [];
    this.searchedUserToken = '';

    this.userChange.emit(user._id);
  }
}
