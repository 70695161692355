<div class="productMarkets">
  <div class="row gutter-20" [ngClass]="{ 'gutter-20': !inline }">
    <div [ngClass]="{ 'col-sm-4': !inline }" *ngFor="let market of markets; index as $index">
      <div class="row gutter-0 text-right">
        <div class="col-xs-2 text-right">
          <i
            class="m-action--delete icon ti-trash ko"
            (click)="removeCountry($index)"
            [hidden]="readonly"
          ></i>
        </div>
        <div class="col-xs-10">
          <select
            class="market"
            [ngModel]="market"
            (change)="changeCountry($index, $event.target.value)"
            [disabled]="readonly"
          >
            <option value="">{{ 'product.choose.country' | translate }}</option>
            <option *ngFor="let country of countries" [value]="country.code"
              >{{ country.name }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div
      [ngClass]="{ 'col-sm-4': !inline, 'col-sm-12': inline, 'text-right': inline }"
      [hidden]="readonly"
    >
      <i class="m-action icon fa fa-plus-circle" (click)="addCountry()"></i>
    </div>
  </div>
</div>
