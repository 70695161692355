import {Component} from '@angular/core';
import {BsModalService} from "ngx-bootstrap/modal";
import {PrivacyPolicyModalComponent} from "../privacy-policy-modal/privacy-policy-modal.component";

@Component({
    selector: 'app-copyright',
    templateUrl: './copyright.component.html',
    styleUrls: ['./copyright.component.scss']
})
export class CopyrightComponent {

    year: number = new Date().getFullYear();

    constructor(private modal: BsModalService) {
    }

    openPrivacyPolicy() {
        this.modal.show(PrivacyPolicyModalComponent, {
            initialState: {
                isModal: true
            }
        })
    }

}
