<div class="m-modal m-modal__nomargin twaSurvey">

  <h3 class="uk-text-center">{{ "twa.survey.title" | translate }}</h3>

  <div class="uk-grid">
    <div class="uk-width-1-2 uk-text-justify">
      <div [innerHTML]="'twa.survey.yes.text.wine' | translate" *ngIf="isWine">
      </div>
      <div [innerHTML]="'twa.survey.yes.text.gourmet' | translate" *ngIf="!isWine">
      </div>
    </div>
    <div class="uk-width-1-2 uk-text-justify">
      <div [innerHTML]="'twa.survey.no.text' | translate">
      </div>
    </div>
    <div class="uk-width-1-2">
      <button class="m-button choice-button"
              [ngClass]="{'not-selected': twa === false}"
              (click)="twa = true">{{"twa.survey.yes" | translate}}
      </button>
    </div>
    <div class="uk-width-1-2">
      <button class="m-button choice-button"
              [ngClass]="{'not-selected': twa === true}"
              (click)="twa = false">{{"twa.survey.no" | translate}}
      </button>
    </div>
  </div>

  <p class="uk-margin-top" *ngIf="twa === true || twa === false" [innerHtml]="'twa.survey.subtitle' | translate"></p>

  <div class="explanation">
    <!--<p *ngIf="twa === true">
        {{ "twa.survey.yes.explain" | translate }}
    </p>
    <p *ngIf="twa === false">
        {{ "twa.survey.no.explain" | translate }}
    </p>-->
    <!--<div *ngIf="twa === true">
        <p *ngIf="isWine || isAdmin">
            {{ "twa.survey.wine" | translate }}
        </p>
        <p *ngIf="!isWine || isAdmin">
            {{ "twa.survey.nonWine" | translate }}
        </p>
    </div>-->
  </div>
  <div class="attachment-download" *ngIf="twa === true">
    <p>{{ "twa.survey.attachment" | translate }}</p>
    <a href='/assets/doc/{{ "twa.survey.attachment.filename" |translate }}'
       *ngIf="isAdmin || isWine"
       target="_blank"
       class="m-button m-button--secondary-light m-button--inline m-button--link">
      {{ "twa.survey.attachment.download" | translate }} {{ 'category.wine' | translate }}
    </a>
    <br>
    <a href="/assets/doc/etichetta-culinaria.pdf"
       *ngIf="isAdmin || !isWine"
       target="_blank"
       class="m-button m-button--secondary-light m-button--inline m-button--link">
      {{ "twa.survey.attachment.download" | translate }} {{ 'category.food' | translate }}
    </a>
  </div>

  <div class="image-warning" *ngIf="(twa !== null) && isWine">
    <h4>{{ "twa.survey.image.title" | translate }}</h4>
    {{ "twa.survey.image.warning" | translate }}
  </div>

  <div class="uk-text-right" *ngIf="twa !== null">
    <button class="m-button m-button--ok m-button--inline" (click)="close()">
      {{ "twa.survey.next" | translate }} <span class="icon ti-arrow-right"></span>
    </button>
  </div>

</div>
