import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { ProductService } from '../../core/product.service';
import { ModalService } from '../../core/modal.service';
import { TranslateService } from '@ngx-translate/core';
import { StoreService } from '../../core/store.service';

import * as _ from 'lodash';

@Component({
  selector: 'app-products-selector',
  templateUrl: './products-selector.component.html',
  styleUrls: ['./products-selector.component.scss']
})
export class ProductsSelectorComponent implements OnInit, OnChanges {
  @Input() userId: string;
  @Input() type: 'eventInvitation' | 'eventRegistration';
  @Input() event: any;
  @Input() selectedProducts: any[] = [];
  @Input() readonly: boolean = false;
  @Input() miniVerticale = false;
  @Input() products: any[] = [];
  userType: string;
  @Input() annateMiniVerticale: string[] = [];

  @Output() productSelected: EventEmitter<any> = new EventEmitter();
  @Output() annateMiniVerticaleChange = new EventEmitter<any>();

  invalidYears = false;

  constructor(
    private productService: ProductService,
    private modalService: ModalService,
    private storeService: StoreService,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.userType = this.storeService.get('userType');
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      !this.miniVerticale &&
      changes.userId &&
      changes.userId.previousValue !== changes.userId.currentValue
    ) {
      this.productService.getByUser(this.userId, { limit: 1000 }).subscribe((products: any) => {
        this.products = products.response;
        this.products.forEach(product => {
          if (product.wineTechnical && product.wineTechnical.years) {
            if (product.wineTechnical.years.length === 1) {
              product.wineTechnical.years = product.wineTechnical.years[0].year;
            } else {
              product.wineTechnical.years = product.wineTechnical.years.reduce(
                (a, b) => `${a} ${b.year}`,
                ''
              );
            }
          }
        });

        if (this.products.length) {
          this.mapSelectedProducts();
        }
      });
    }

    if (
      changes.selectedProducts &&
      changes.selectedProducts.previousValue !== changes.selectedProducts.currentValue
    ) {
      this.mapSelectedProducts();
    }

    if (this.miniVerticale && this.selectedProducts.length > 0) {
      const selected = this.selectedProducts[0];
      this.products.forEach(product => {
        product.selected = product._id === selected._id;
      });
    }
  }

  selectProduct(product: any, event: any) {
    if (this.miniVerticale) {
      if (event.target.checked) {
        this.productSelected.emit(product);
      } else {
        this.productSelected.emit(null);
        this.annateMiniVerticaleChange.emit([]);
      }
    } else {
      if (event.target.checked) {
        this.selectedProducts.push(product);
        this.productSelected.emit(this.selectedProducts);
      } else {
        let index = 0;
        let found = false;

        this.selectedProducts.forEach((sel, i) => {
          if (sel._id === product._id) {
            index = i;
          }
        });

        this.selectedProducts.splice(index, 1);
        this.productSelected.emit(this.selectedProducts);
      }

      console.log(this.selectedProducts);
    }
  }

  mapSelectedProducts() {
    this.products.forEach(product => {
      this.selectedProducts.forEach(prod => {
        if (prod._id === product._id) {
          product.selected = true;
        }
      });
    });
  }

  validate() {
    let validationPassed = false;
    if (this.event && this.event.constraint.evaluationRequired) {
      const selectedIds = this.selectedProducts.map(p => p._id);
      this.products.forEach(prod => {
        if (_.includes(selectedIds, prod._id)) {
          if (prod.board && prod.board.evaluation >= this.event.constraint.minEvaluation) {
            validationPassed = true;
          }
        }
      });

      if (!validationPassed) {
        this.modalService.error(
          this.translateService.instant('event-invitation-product.errorHtml.products'),
          60000
        );
        return false;
      }
    }
    if (this.event && this.selectedProducts.length < this.event.constraint.minProducts) {
      this.modalService.error(
        this.translateService.instant('event-invitation-product.save.error-numprods')
      );
      return false;
    }

    if (this.miniVerticale && this.annateMiniVerticale.length < 3) {
      this.modalService.error(
        this.translateService.instant('event-invitation-product.save.error-miniverticale-annate')
      );
      this.invalidYears = true;
      return false;
    } else if (this.miniVerticale && this.annateMiniVerticale.length >= 3) {
      this.invalidYears = false;
    }

    return true;
  }

  changeAnnata() {
    this.annateMiniVerticaleChange.emit(this.annateMiniVerticale);
  }
}
