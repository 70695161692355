import {Directive, ElementRef, EventEmitter, HostBinding, HostListener, Input, Output} from '@angular/core';
import {FormControl, NG_VALIDATORS, ValidationErrors, Validator} from "@angular/forms";

@Directive({
    selector: '[inputDecimal]',
    providers: [
        {provide: NG_VALIDATORS, useExisting: InputDecimalDirective, multi: true}
    ]
})
export class InputDecimalDirective implements Validator {
    @Input() ngModel: string;
    @Output() decimalChanged = new EventEmitter();

    constructor(private _el: ElementRef) {
    }

    @HostListener('input', ['$event'])
    onInputChange(event) {
        const initialValue = this._el.nativeElement.value;
        ///^[0-9]+([,.][0-9]+)?$/
        // Ammetti solo numeri, virgole e punti
        this._el.nativeElement.value = initialValue.replace(/[^0-9,.]*/g, '');
        if (initialValue !== this._el.nativeElement.value) {
            event.stopPropagation();
        }
    }

    /*@HostListener('blur', ['$event'])
    onBlur(event) {
        if (this._el.nativeElement.value) {
            this._el.nativeElement.value = this._el.nativeElement.value.replace(/[^0-9,.]*!/g, '').replace(/,/g, '.');
            this.decimalChanged.emit(this._el.nativeElement.value)
        }
    }*/

    @HostListener('keyup', ['$event'])
    onKeyup(event) {
        console.log("Value", this._el.nativeElement.value)
        if (this._el.nativeElement.value) {
            this._el.nativeElement.value = this._el.nativeElement.value.replace(/[^0-9,.]*/g, '').replace(/,/g, '.');
            this.decimalChanged.emit(this._el.nativeElement.value)
        }
        else {
            this.decimalChanged.emit(null)
        }
    }

    // This method is the one required by the Validator interface
    validate(c: FormControl): ValidationErrors | null {
        // Here we call our static validator function
        return InputDecimalDirective.validateNumber(c);
    }

    static validateNumber(control: FormControl): ValidationErrors {
        if (control.errors && control.errors.required === true) {
            if (/^[0-9]+([,.][0-9]+)?$/.test(control.value) == false) {
                return {inputDecimal: 'Numero non valido'};
            }
        }
        if (!!control.value) {
            if (/^[0-9]+([,.][0-9]+)?$/.test(control.value) == false) {
                return {inputDecimal: 'Numero non valido'};
            }
        }
        // If no error, return null
        return null;
    }
}
