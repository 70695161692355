<form #form="ngForm" [ngClass]="{ 'submitted': form.submitted }">
    <div class="uk-grid uk-grid-small">
        <div class="uk-width-small-1-2">
            <label class="m-label">{{"nome.azienda" | translate}} *</label>
            <input type="text"
                   name="businessName"
                   class="m-input"
                   [(ngModel)]="user.canteen.businessName"
                   (input)="user.canteenChanged = true; user.canteen.businessNameChanged = true"
                   required>
        </div>
        <div class="uk-width-small-1-2">
            <label class="m-label">{{"indirizzo" | translate}} *</label>
            <input type="text"
                   name="address"
                   class="m-input"
                   [(ngModel)]="user.canteen.address"
                   (input)="user.canteenChanged = true; user.canteen.addressChanged = true"
                   required>
        </div>
        <div class="uk-width-small-1-2">
            <label class="m-label">{{"cap" | translate}} *</label>
            <input type="text"
                   name="postalCode"
                   class="m-input"
                   [(ngModel)]="user.canteen.postalCode"
                   (input)="user.canteenChanged = true; user.canteen.postalCodeChanged = true"
                   required>
        </div>
        <div class="uk-width-small-1-2">
            <label class="m-label">{{"citta" | translate}} *</label>
            <input type="text"
                   name="city"
                   class="m-input"
                   [(ngModel)]="user.canteen.city"
                   (input)="user.canteenChanged = true; user.canteen.cityChanged = true"
                   required>
        </div>
        <div class="uk-width-small-1-2">
            <label class="m-label">{{'stato' | translate}} *</label>
            <div class="m-select" [ngClass]="{'ng-invalid': form.submitted && !user.canteen.country}">
                <select name="country" [(ngModel)]="user.canteen.country" (change)="selectCountry(); user.canteenChanged = true" required>
                    <option *ngFor="let country of countries" [value]="country.code">
                        {{ country.name }}
                    </option>
                </select>
            </div>
        </div>
        <div class="uk-width-small-1-2">
            <label class="m-label">{{"regione" | translate}}
                <span *ngIf="districts.length > 0">*</span></label>
            <div class="m-select" *ngIf="districts.length > 0"
                 [ngClass]="{'ng-invalid': form.submitted && !user.canteen.district}">
                <select name="district" [(ngModel)]="user.canteen.district" (change)="selectDistrict(); user.canteenChanged = true" required>
                    <option *ngFor="let district of districts" [value]="district.name">
                        {{ district.name }}
                    </option>
                </select>
            </div>
            <!--Per paesi non mappati sul database con le proprie regioni-->
            <input type="text" class="m-input" name="district"
                   [(ngModel)]="user.canteen.district"
                   (input)="user.canteenChanged = true; user.canteen.districtChanged = true" *ngIf="user.canteen.country !== 'IT' || districts.length == 0">
        </div>
        <div class="uk-width-small-1-2">
            <label class="m-label">{{"provincia" | translate}}
                <span *ngIf="provinces.length > 0">*</span></label>
            <div class="m-select" *ngIf="provinces.length > 0"
                 [ngClass]="{'ng-invalid': form.submitted && !user.canteen.province}">
                <select name="province" [(ngModel)]="user.canteen.province" required>
                    <option *ngFor="let province of provinces" [value]="province.name">
                        {{ province.name }}
                    </option>
                </select>
            </div>
            <input type="text" class="m-input" name="province"
                   [(ngModel)]="user.canteen.province"
                   (input)="user.canteenChanged = true; user.canteen.provinceChanged = true" *ngIf="user.canteen.country !== 'IT' || provinces.length == 0">
        </div>
        <div class="uk-width-small-1-2">
            <div class="uk-grid uk-grid-small">
                <div class="uk-width-small-1-1">
                    <label class="m-label">{{'prefix' | translate}} {{'telefono' | translate}} *</label>
                </div>
                <div class="uk-width-small-2-10">
                    <input type="text"
                    name="prefixPhone"
                    class="m-input"
                    (keypress)="checkPhoneNumber($event)"
                    (paste)="checkPhoneNumber($event)"
                    [(ngModel)]="user.canteen.prefixPhone"
                    (input)="user.canteenChanged = true; user.canteen.phoneChanged = true"
                    required>
                </div>
                <div class="uk-width-small-8-10">
                    <input type="text"
                    name="phone"
                    class="m-input"
                    (keypress)="checkPhoneNumber($event)"
                    (paste)="checkPhoneNumber($event)"
                    [(ngModel)]="user.canteen.phone"
                    (input)="user.canteenChanged = true; user.canteen.phoneChanged = true"
                    required>
                </div>
            </div>


           
        </div>
        <div class="uk-width-small-1-2">
            <div class="uk-grid uk-grid-small">
                <div class="uk-width-small-1-1">
                    <label class="m-label">{{'prefix' | translate}} {{'fax' | translate}} *</label>
                </div>
                <div class="uk-width-small-2-10">
                    <input type="text"
                        name="prefixFax"
                        class="m-input"
                        (keypress)="checkPhoneNumber($event)"
                        (paste)="checkPhoneNumber($event)"
                        [(ngModel)]="user.canteen.prefixFax"
                        (input)="user.canteenChanged = true; user.canteen.faxChanged = true">
                </div>
                <div class="uk-width-small-8-10">
                    <input type="text"
                        name="fax"
                        class="m-input"
                        (keypress)="checkPhoneNumber($event)"
                        (paste)="checkPhoneNumber($event)"
                        [(ngModel)]="user.canteen.fax"
                        (input)="user.canteenChanged = true; user.canteen.faxChanged = true">
                </div>
            </div>
        </div>
        <div class="uk-width-small-1-2">
            <label class="m-label">{{"email.aziendale" | translate}} *</label>
            <input type="email"
                   name="corporateEmail"
                   class="m-input"
                   [(ngModel)]="user.canteen.corporateEmail"
                   (input)="user.canteenChanged = true; user.canteen.corporateEmailChanged = true"
                   required
                   email>
        </div>
        <div class="uk-width-small-1-2">
            <label class="m-label">{{"sito" | translate}}</label>
            <input type="text"
                   name="webSite"
                   class="m-input"
                   [(ngModel)]="user.canteen.webSite"
                   (input)="user.canteenChanged = true; user.canteen.webSiteChanged = true">
        </div>
    </div>

    <div *ngIf="user.category == 'wine'">
        <div class="uk-grid uk-grid-small">
            <div class="uk-width-small-1-2">
                <label class="m-label">{{'product.microzone' | translate}} *</label>
                <div class="m-select" [ngClass]="{'ng-invalid': form.submitted && !user.canteen.microzone}">
                    <select name="microzone" [(ngModel)]="user.canteen.microzone" [disabled]="missingMicrozone.checked" [required]="!missingMicrozone.checked">
                        <option *ngFor="let microzone of microzonesToShow" [value]="microzone.name">
                            {{ microzone.name }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="uk-width-small-1-2">
                <label class="m-label"> <input #missingMicrozone type="checkbox" name="missingMicrozone" [checked]="false" (change)="setMicrozone()"> {{ "product.microzone_not_present" | translate}} *</label>
               
                <input type="text"
                       name="manualMicrozone"
                       class="m-input"
                       [(ngModel)]="user.canteen.manualMicrozone"
                       [disabled]="!missingMicrozone.checked"
                       [required]="missingMicrozone.checked">

            </div>
            <div class="uk-width-small-1-2">
                <label class="m-label">{{"produzione.bottiglie" | translate}} *</label>
                <input type="number"
                       name="productionBottles"
                       class="m-input"
                       [(ngModel)]="user.wineDetails.productionBottles"
                       (input)="user.canteenChanged = true; user.canteen.productionBottlesChanged = true"
                       required>
            </div>
            <div class="uk-width-small-1-2">
                <label class="m-label">{{"ettari" | translate}} *</label>
                <input type="number"
                       name="area"
                       class="m-input"
                       [(ngModel)]="user.wineDetails.area"
                       (input)="user.canteenChanged = true; user.canteen.areaChanged = true"
                       required>
            </div>
            <div class="uk-width-small-1-2">
                <label class="m-label">{{"winemaker" | translate}} *</label>
                <input type="text"
                       name="winemaker"
                       class="m-input"
                       [(ngModel)]="user.wineDetails.winemaker"
                       (input)="user.canteenChanged = true; user.canteen.winemakerChanged = true"
                       required>
            </div>
        </div>
    </div>

    <div *ngIf="user.category == 'beer'">
        <div class="uk-grid uk-grid-small">
            <div class="uk-width-small-1-2">
                <label class="m-label">{{"product.production.quantity" | translate}} *</label>
                <input type="number"
                       name="productionQuantity"
                       class="m-input"
                       ng-min="0"
                       min="0"
                       [(ngModel)]="user.beerDetails.productionQuantity"
                       (input)="user.canteenChanged = true; user.canteen.productionQuantityChanged = true"
                       required>
            </div>
        </div>
    </div>

    <div *ngIf="user.category == 'wine'">
        <div class="uk-grid uk-grid-small"
             *ngFor="let agricultureType of user.canteen.agricultureType; let index = index">
            <div class="uk-width-small-1-1">
                <label class="m-label">{{"registration.agricoltura" | translate}}
                    <span *ngIf="index == 0">*</span>
                    <span *ngIf="index > 0">
                      <i class="m-action--delete icon ti-trash ko" (click)="removeAgricultureType(index)"></i>
                  </span>
                </label>
                <div class="m-select"
                     [ngClass]="{'ng-invalid': form.submitted && !user.canteen.agricultureType[index]}">
                    <select [(ngModel)]="user.canteen.agricultureType[index]"
                            [ngModelOptions]="{standalone: true}">
                        <option *ngFor="let type of agricultureTypes" [value]="type.code">
                            {{ type.name }}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <div class="uk-text-right">
            <i class="m-action icon fa fa-plus-circle" (click)="addAgricultureType()"></i>
        </div>
    </div>
    <div class="fields-required">
        {{ "campi.obbligatori" | translate }}
    </div>
</form>
