<form #form="ngForm" [ngClass]="{ submitted: form.submitted }" novalidate>
  <!--vino-->
  <div *ngIf="product.category == 'wine'">
    <div [ngClass]="{ highlight: !productDetailMode }">
      <div class="uk-grid">
        <div [ngClass]="column">
          <label class="m-label">{{ 'product.variety' | translate }} *</label>
        </div>
        <div [ngClass]="column">
          <div
            class="uk-grid uk-grid-small"
            *ngFor="let variety of product.wineTechnical?.variety; let $index = index"
          >
            <div
              [ngClass]="{
                'uk-width-small-5-10': !productDetailMode,
                'uk-width-1-1': productDetailMode
              }"
            >
              <label class="m-label">{{ 'product.variety' | translate }} *</label>

              <ngx-select
                [items]="varieties"
                [(ngModel)]="variety.name"
                optionTextField="name"
                [name]="'variety-' + $index"
                [disabled]="readonly || missingVariety.checked"
              ></ngx-select>

              <div [ngClass]="column">
                <input
                  #missingVariety
                  type="checkbox"
                  name="missingVariety"
                  [checked]="variety.manualName"
                  (change)="variety.name = null; variety.manualName = null"
                  [disabled]="readonly"
                />
                {{ 'product.variety' | translate }} non presente
              </div>
              <div [ngClass]="column" *ngIf="missingVariety.checked">
                <input
                  type="text"
                  class="m-input"
                  [(ngModel)]="variety.manualName"
                  [name]="'manual-variety-' + $index"
                  [readonly]="readonly"
                  [placeholder]="'product.variety' | translate"
                  [required]="missingVariety.checked"
                />
              </div>
            </div>
            <div
              [ngClass]="{
                'uk-width-small-4-10': !productDetailMode,
                'uk-width-1-1': productDetailMode
              }"
            >
              <label class="m-label">{{ 'product.perc' | translate }} ({{ 'product.perc_total' | translate }} = 100)</label>
              <input
                type="text"
                inputDecimal
                (decimalChanged)="variety.perc = $event"
                required
                class="m-input"
                [ngModelOptions]="{ standalone: true }"
                [readonly]="readonly"
                [(ngModel)]="variety.perc"
              />
            </div>
            <div
              class="remove-icon"
              [ngClass]="{
                'uk-width-small-1-10': !productDetailMode,
                'uk-width-1-1 text-right': productDetailMode
              }"
            >
              <span
                class="remove-product-icon ti-trash ko"
                title="{{ 'general.remove' | translate }}"
                [hidden]="readonly"
                (click)="removeVariety($index)"
                *ngIf="(variety.name || $index !== 0) && product.wineTechnical.variety.length > 1"
              ></span>
            </div>
          </div>
        </div>
      </div>
      <div class="uk-grid" [hidden]="readonly">
        <div class="uk-width-1-1 text-right">
          <div class="add-product-icon fa fa-plus-circle" (click)="addVariety()"></div>
        </div>
      </div>
    </div>
  </div>
</form>
