<div
  class="product-constraints"
  *ngIf="event && !(userType === 'supplier' && event.freeAccess === false)"
>
  {{ 'event-invitation-product.selectedProducts' | translate }}:
  <strong>{{ selectedProducts.length }} /{{ event.constraint?.maxProducts }}</strong>
  <br />
  <div *ngIf="event.constraint?.minEvaluation">
    {{ 'event-invitation-product.minEvaluation' | translate }}
    <app-award-icon
      [evaluation]="event.constraint?.minEvaluation"
      [year]="event.year"
      [showText]="false"
      [showAward]="true"
    ></app-award-icon>
  </div>
</div>

<div class="product-constraints" *ngIf="miniVerticale">
  {{ 'event-invitation-product.selectedProducts' | translate }}:
  <strong>{{ selectedProducts.length }}/1</strong>
</div>

<div class="Table-container">
  <table class="table table-hover">
    <tr>
      <th>
        {{ 'event-invitation-product.select' | translate }}
      </th>
      <th>
        {{ 'dashboard.products.name' | translate }}
      </th>
      <th *ngIf="!miniVerticale">
        {{ 'dashboard.products.category' | translate }}
      </th>
      <th>
        {{ 'dashboard.products.wine.areaDenomination' | translate }}
      </th>
      <th>
        {{ 'product.specification' | translate }}
      </th>
      <th>
        {{ 'product.sparkling.type' | translate }}
      </th>
      <th>
        {{ 'dashboard.products.wine.denomination' | translate }}
      </th>
      <th *ngIf="!miniVerticale">
        {{ 'product.year' | translate }}
      </th>
      <th>
        {{ 'dashboard.products.region' | translate }}
      </th>
      <th>
        {{ 'dashboard.products.dateYear' | translate }}
      </th>
      <th *ngIf="!miniVerticale">
        <div *ngIf="userType == 'admin'">{{ 'dashboard.products.score' | translate }}</div>
        <div *ngIf="userType == 'supplier'">
          {{ 'dashboard.products.merano.festival' | translate }}
        </div>
      </th>
      <th *ngIf="miniVerticale">
        {{ 'product.years' | translate }}
      </th>
    </tr>
    <tr *ngFor="let product of products; let i = index" [class.highlight]="product.selected">
      <td>
        <input
          type="checkbox"
          [(ngModel)]="product.selected"
          [name]="product._id + (miniVerticale ? '-mv' : '')"
          (change)="selectProduct(product, $event)"
          [disabled]="
            readonly ||
            (!miniVerticale &&
              selectedProducts.length == event?.constraint?.maxProducts &&
              !product.selected) ||
            (miniVerticale && selectedProducts.length > 0 && !product.selected)
          "
        />
      </td>
      <td>{{ product.name }}</td>
      <td *ngIf="!miniVerticale">{{ 'category.' + product.category | translate }}</td>
      <td>
        {{
          product.category == 'wine'
            ? product.wineTechnical?.areaDenomination
              ? product.wineTechnical?.areaDenomination
              : product.wineTechnical?.manualAreaDenomination
            : ''
        }}
      </td>
      <td>
        {{
          product.wineTechnical
            ? (product.wineTechnical.otherSpecification
                ? product.wineTechnical.otherSpecification
                : '') +
              ' ' +
              (product.wineTechnical.specification ? product.wineTechnical.specification : '')
            : ''
        }}
      </td>
      <td>
        <span *ngIf="product.wineTechnical?.sparklingType">{{
          'category.sparkling.' + product.wineTechnical.sparklingType | translate
        }}</span>
      </td>
      <td>{{ product.wineTechnical?.denomination }}</td>
      <td *ngIf="!miniVerticale">{{ product.wineTechnical?.years }}</td>
      <td>{{ product.region }}</td>
      <td>{{ product.creationDate | date: 'yyyy' }}</td>
      <td *ngIf="!miniVerticale">
        <div *ngIf="userType == 'admin'">{{ product.board?.evaluation || '-' }}</div>
        <app-award-icon
          *ngIf="userType == 'supplier'"
          [evaluation]="product.board?.finalEvaluation || product.board?.evaluation"
          [year]="product.creationDate | date: 'yyyy'"
          [showAward]="'true'"
        ></app-award-icon>
      </td>
      <th *ngIf="miniVerticale && product.selected">
        <tag-input
          [(ngModel)]="annateMiniVerticale"
          (onAdd)="changeAnnata()"
          (onRemove)="changeAnnata()"
          [modelAsStrings]="true"
          [maxItems]="5"
          name="annate"
          [separatorKeys]="[' ', ',', '.']"
          placeholder="Inserisci le annate separate da ,"
          secondaryPlaceholder="Inserisci le annate separate da ,"
          [inputClass]="'miniverticale-tag' + (invalidYears ? ' ng-invalid' : '')"
        >
        </tag-input>
      </th>
    </tr>
  </table>
</div>
