<div class="Card">
  <div class="Card__title">
    {{ 'process.title' | translate }}
  </div>

  <p>{{ subject }}</p>
  <p *ngFor="let item of items"><small >{{ item }}</small></p>

  <div class="uk-grid">
    <div class="uk-width-1-1">
      <button class="m-button m-button--inline" (click)="goBack()">
        {{ 'button.undo' | translate }}
      </button>
    </div>
  </div>
</div>
