<div class="register uk-height-viewport">
  <app-lang-navbar></app-lang-navbar>

  <div class="m-modal">
    <div class="logo">
      <img src="/assets/img/logo.png" />
    </div>

    <div>
      <div class="title">{{ 'recovery.title' | translate }}</div>
      <form #formRecovery="ngForm" [ngClass]="{ submitted: formRecovery.submitted }" novalidate>
        <div class="uk-grid uk-grid-small">
          <div class="uk-width-medium-1-1">
            <label class="m-label text-center"> {{ 'recovery.email' | translate }}</label>
            <input type="email" name="email" [(ngModel)]="email" class="m-input" required />
          </div>
        </div>
      </form>

      <div class="bottom-buttons">
        <div class="uk-grid uk-grid-small">
          <div class="uk-width-medium-1-2">
            <button class="m-button m-button--disabled" routerLink="/login">
              {{ 'recovery.back' | translate }}
            </button>
          </div>
          <div class="uk-width-medium-1-2">
            <button class="m-button" (click)="recoverPassword()">
              {{ 'recovery.send' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="bottom-section">
      {{ 'register.text' | translate }} <a routerLink="/register">{{ 'register' | translate }}</a>
    </div>
  </div>

  <app-copyright></app-copyright>
</div>
