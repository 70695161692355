import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { EnumService } from './enum.service';

@Injectable()
export class LocaleInterceptor implements HttpInterceptor {
    constructor(private cookieService: CookieService,
        private enumService: EnumService) {
    }


    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (request.url.includes('api/login')) {
            return next.handle(request);
        }

        let locale = this.enumService.languages[0].code;

        if (this.cookieService.check('locale')
            && this.enumService.languages.some(lang => lang.code === this.cookieService.get('locale'))) {
            locale = this.cookieService.get('locale');
        }

        if (locale) {
            request = request.clone({
                setHeaders: {
                    Locale: `${locale}`
                }
            })
        }
        return next.handle(request).pipe(
            finalize(() => { })
        );
    }
}