<div class="uk-grid">
  <div [ngClass]="column" *ngIf="userType == 'admin' || userType == 'board'">
    <label class="m-label">{{ 'product.user' | translate }} *</label>
  </div>
  <div [ngClass]="column" *ngIf="userType == 'admin'">
    <a routerLink="/dashboard/user/{{ product?.user?._id }}"
      ><strong>{{
        product?.user?.canteen?.businessName ||
          product?.user?.society?.businessName ||
          product?.user?.email
      }}</strong></a
    >
  </div>
  <div [ngClass]="column" *ngIf="userType == 'board'">
    <strong>{{
      product?.user?.canteen?.businessName ||
        product?.user?.society?.businessName ||
        product?.user?.email
    }}</strong>
  </div>

  <div class="uk-width-3-4" *ngIf="userType == 'admin' && !productDetailMode">
    <input
      type="text"
      class="m-input"
      placeholder="Cerca per nome azienda o username"
      [(ngModel)]="searchedUserToken"
      [required]="!product.user"
    />

    <ul class="uk-list uk-list-striped" [hidden]="!usernameList.length">
      <li *ngFor="let user of usernameList" (click)="chooseUser(user)">
        {{ user.canteen?.businessName || user.society?.businessName }} ({{ user.email }})
      </li>
    </ul>
    <br />
    <br />
  </div>

  <div class="uk-width-1-4" *ngIf="userType == 'admin' && !productDetailMode">
    <button class="m-button m-button--nomargin" (click)="searchUser(searchedUserToken)">
      {{ 'general.search' | translate }}
    </button>
  </div>

  <div
    class="uk-width-1-1"
    *ngIf="userType == 'admin'"
    [hidden]="!selectedUser && productDetailMode"
  >
    <label class="m-label">{{ 'product.selected.user' | translate }}</label>
    <div>
      <strong>{{ selectedUser }}</strong>
    </div>
    <br />
  </div>
</div>
