<div class="searchUser">
    <div class="uk-grid">
        <div class="uk-width-3-4">
            <input type="text"
                   class="m-input"
                   placeholder="Cerca per nome azienda"
                   (keyup.enter)="searchUser(searchKey)"
                   [(ngModel)]="searchKey">
            <h2 *ngIf="loading">
                <i class="fa fa-refresh fa-spin"></i>
            </h2>
            <ul class="uk-list uk-list-striped" *ngIf="!loading && users.length > 0">
                <li *ngFor="let user of users" (click)="selectUser(user)">
                    {{ user.canteen?.businessName || user.society?.businessName }} ({{ user.email }})
                </li>
            </ul>
            <br>
            <br>
        </div>

        <div class="uk-width-1-4">
            <button class="m-button m-button--nomargin" (click)="searchUser(searchKey)">
                {{"general.search" | translate}}
            </button>
        </div>
    </div>
</div>
