<div class="sideMenu">
  <perfect-scrollbar style="max-height: 100vh;">
    <div class="Logo">
      <img src="/assets/img/Gourmet-logo.png" alt="Gourmet International" />
    </div>
    <ul class="uk-list">
      <li
        routerLinkActive="active"
        routerLink="/dashboard/summary"
        *ngIf="userType != 'board' && !profileIncomplete"
      >
        <a><span class="icon ti-home"></span> {{ 'menu.dashboard' | translate }}</a>
      </li>
      <li
        routerLinkActive="active"
        routerLink="/dashboard/product"
        *ngIf="userType != 'board' && !profileIncomplete"
      >
        <a><span class="icon ti-files"></span> {{ 'menu.products' | translate }}</a>
      </li>
      <li
        routerLinkActive="active"
        routerLink="/dashboard/product"
        *ngIf="userType == 'board' && !profileIncomplete"
      >
        <a>
          <span class="icon ti-files"></span> {{ 'dashboard.summary.all.products' | translate }}
        </a>
      </li>
      <li
        routerLink="/dashboard/user"
        routerLinkActive="active"
        *ngIf="userType == 'admin' && !profileIncomplete"
      >
        <a> <span class="icon ti-user"></span> {{ 'menu.users' | translate }} </a>
      </li>
      <li routerLink="/dashboard/commission" routerLinkActive="active" *ngIf="userType == 'admin'">
        <a> <span class="icon ti-clipboard"></span> {{ 'menu.commissions' | translate }} </a>
      </li>
      <li
        routerLinkActive="active"
        routerLink="/dashboard/evaluation"
        *ngIf="userType === 'admin' || userType === 'board'"
      >
        <a> <span class="icon ti-medall-alt"></span> {{ 'menu.evaluations' | translate }} </a>
      </li>

      <li
        routerLink="/dashboard/shop"
        routerLinkActive="active"
        *ngIf="userType != 'board' && !profileIncomplete"
      >
        <a><span class="icon ti-shopping-cart"></span> {{ 'Shop' | translate }}</a>
      </li>

      <li
        routerLink="/dashboard/event"
        routerLinkActive="active"
        class="highlight"
        *ngIf="(userType == 'admin' || userType == 'supplier') && !profileIncomplete"
      >
        <a> <span class="icon ti-cup"></span> {{ 'menu.events' | translate }} </a>
      </li>

      <li
        routerLink="/dashboard/event-invitations"
        class="highlight"
        routerLinkActive="active"
        *ngIf="userType == 'admin'"
      >
        <a> <span class="icon ti-file"></span> {{ 'menu.event-invitations' | translate }} </a>
      </li>

      <li
        routerLink="/dashboard/event-registrations"
        class="highlight"
        *ngIf="userType == 'admin'"
        routerLinkActive="active"
      >
        <a>
          <span class="icon ti-cup"></span><span class="icon ti-user second-icon"></span>
          {{ 'menu.event-registrations' | translate }}
        </a>
      </li>

      <li
        routerLinkActive="active"
        routerLink="/dashboard/notification"
        *ngIf="userType !== 'board' && !profileIncomplete"
      >
        <a>
          <span class="icon ti-bell" routerLink="/dashboard/notification"></span>
          {{ 'menu.notifications' | translate }}
        </a>
      </li>
      <li routerLinkActive="active" routerLink="/dashboard/user/me">
        <a>
          <span class="icon ti-face-smile" *ngIf="userType == 'admin'"></span>
          <span class="icon ti-user" *ngIf="userType != 'admin'"></span>
          {{ 'menu.profile' | translate }}
        </a>
      </li>
      <!--<li routerLinkActive="active"
                routerLink="/dashboard/setting"
                *ngIf="userType === 'admin'">
                <a>
            <span class="icon ti-settings"
                  routerLink="/dashboard/setting"></span> {{"menu.settings" | translate}}
                </a>
            </li>-->

      <li (click)="logout()">
        <a><span class="icon ti-arrow-left"></span> {{ 'general.logout' | translate }}</a>
      </li>
    </ul>

    <div class="copyright">
      <p>
        {{ 'menu.copyright1' | translate }}
        <br />
        {{ 'menu.copyright2' | translate }}
        <br />
        {{ 'menu.copyright3' | translate }}
      </p>
      <p>
        {{ 'menu.copyright4' | translate }}
        <br />
        {{ 'menu.copyright5' | translate }}
      </p>
      <p>
        {{ 'menu.copyright6' | translate }}
      </p>
    </div>
  </perfect-scrollbar>
</div>
