<form #form="ngForm" [ngClass]="{ 'submitted': form.submitted }">
  <div class="uk-grid uk-grid-small">
    <div class="uk-width-small-1-1">
      <label class="m-label">{{"email" | translate}} *</label>
      <input type="email"
             name="email"
             class="m-input"
             [(ngModel)]="user.email"
             required
             email>
    </div>
    <div class="uk-width-1-1">
      <label class="m-label">{{"categoria" | translate}} *</label>
      <div class="m-select"
           [ngClass]="{'ng-invalid': form.submitted && !user.category}">
        <select name="category"
                [(ngModel)]="user.category"
                (change)="changeCategory()"
                required>
          <option value=""></option>
          <option *ngFor="let category of userCategories" [value]="category.code">
            {{ category.name }}
          </option>
        </select>
      </div>
    </div>
    <div class="uk-width-small-1-2">
      <label class="m-label">{{"password" | translate}} *</label>
      <input type="password"
             name="password"
             class="m-input"
             [(ngModel)]="user.password"
             required>
    </div>
    <div class="uk-width-small-1-2">
      <label class="m-label">{{'password.repeat' | translate}} *</label>
      <input type="password"
             class="m-input"
             name="password2"
             [(ngModel)]="password2"
             required>
    </div>
  </div>
  <div class="fields-required">
    {{ "campi.obbligatori" | translate }}
  </div>
</form>
