<form #form="ngForm" [ngClass]="{ submitted: form.submitted }" novalidate>
  <!--vino-->
  <div *ngIf="product.category == 'wine'" class="uk-grid">
    <div class="uk-width-1-1">
      <div class="uk-grid">
        <div
          class="uk-width-1-1"
          *ngFor="let agingType of product.wineTechnical?.aging; let $index = index"
        >
          <div class="uk-grid">
            <div [ngClass]="column">
              <label class="m-label"
                >{{ 'product.aging' | translate }} *
                <span *ngIf="$index != 0" [hidden]="readonly">
                  <i
                    class="m-action--delete icon ti-trash ko"
                    (click)="removeAgingType($index)"
                  ></i>
                </span>
              </label>
            </div>
            <div [ngClass]="column">
              <div
                class="m-select"
                [ngClass]="{ 'ng-invalid': form.submitted && !product.wineTechnical.aging[$index] }"
              >
                <select
                  [(ngModel)]="product.wineTechnical.aging[$index]"
                  [ngModelOptions]="{ standalone: true }"
                  [disabled]="readonly"
                  required
                >
                  <option value=""></option>
                  <option *ngFor="let cat of aging" value="{{ cat.code }}">
                    {{ cat.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="uk-width-1-1 uk-text-right" [hidden]="readonly">
          <i class="m-action icon fa fa-plus-circle" (click)="addAgingType()"></i>
        </div>
      </div>
    </div>

    <div class="uk-width-1-1">
      <div class="uk-grid">
        <div [ngClass]="column">
          <label class="m-label">{{ 'product.agriculture' | translate }} * </label>
        </div>
        <div [ngClass]="column">
          <div
            class="m-select"
            [ngClass]="{ 'ng-invalid': form.submitted && !product.wineTechnical.agricultureType }"
          >
            <select
              [(ngModel)]="product.wineTechnical.agricultureType"
              [ngModelOptions]="{ standalone: true }"
              [disabled]="readonly"
              required
            >
              <option value=""></option>
              <option *ngFor="let cat of agricultureTypes" value="{{ cat.code }}">
                {{ cat.name }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>

    <div class="uk-width-1-1" *ngIf="product.category != 'spirit'">
      <div class="uk-grid">
        <div [ngClass]="column">
          <label class="m-label">{{ 'product.sugarResidue' | translate }}</label>
        </div>
        <div [ngClass]="column">
          <input
            type="text"
            inputDecimal
            [readonly]="readonly"
            (decimalChanged)="product.wineTechnical.sugarResidue = $event"
            class="m-input"
            name="sugarResidue"
            [(ngModel)]="product.wineTechnical.sugarResidue"
          />
        </div>
      </div>
    </div>
    <div class="uk-width-1-1" *ngIf="product.category != 'spirit'">
      <div class="uk-grid">
        <div [ngClass]="column">
          <label class="m-label">{{ 'product.alcohol.perc' | translate }} *</label>
        </div>
        <div [ngClass]="column">
          <input
            type="text"
            inputDecimal
            [readonly]="readonly"
            (decimalChanged)="product.wineTechnical.alcoholPerc = $event"
            class="m-input"
            name="alcoholPerc"
            [(ngModel)]="product.wineTechnical.alcoholPerc"
            required
          />
        </div>
      </div>
    </div>
    <div class="uk-width-1-1" *ngIf="product.category != 'spirit'">
      <div class="uk-grid">
        <div [ngClass]="column">
          <label class="m-label">{{ 'product.acidity' | translate }}</label>
        </div>
        <div [ngClass]="column">
          <input
            type="text"
            inputDecimal
            [readonly]="readonly"
            (decimalChanged)="product.wineTechnical.acidity = $event"
            class="m-input"
            name="acidity"
            [(ngModel)]="product.wineTechnical.acidity"
          />
        </div>
      </div>
    </div>
  </div>
  <!--alcolici-->
  <div *ngIf="product.category == 'spirit'" class="uk-grid">
    <div
      [ngClass]="booleanColumn"
      [ngStyle]="
        !isBoolean(product.spiritTechnical.biological) && form.submitted
          ? { color: 'red' }
          : { color: 'black' }
      "
    >
      <div class="uk-grid">
        <div [ngClass]="column">
          <label class="m-label">{{ 'product.biological' | translate }} *</label>
        </div>
        <div [ngClass]="column">
          <div class="uk-grid uk-grid-small">
            <div class="uk-width-1-2">
              <label>
                <input
                  type="radio"
                  name="bio"
                  group="bio"
                  [disabled]="readonly"
                  [value]="true"
                  [(ngModel)]="product.spiritTechnical.biological"
                  required
                />
                {{ 'general.yes' | translate }}
              </label>
            </div>
            <div class="uk-width-1-2">
              <label>
                <input
                  type="radio"
                  name="bio"
                  group="bio"
                  [disabled]="readonly"
                  [value]="false"
                  [(ngModel)]="product.spiritTechnical.biological"
                  required
                />
                {{ 'general.no' | translate }}
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      [ngClass]="booleanColumn"
      [ngStyle]="
        !isBoolean(product.spiritTechnical.glutenFree) && form.submitted
          ? { color: 'red' }
          : { color: 'black' }
      "
    >
      <div class="uk-grid">
        <div [ngClass]="column">
          <label class="m-label">{{ 'product.gluten.free' | translate }} *</label>
        </div>
        <div [ngClass]="column">
          <div class="uk-grid uk-grid-small">
            <div class="uk-width-1-2">
              <label>
                <input
                  type="radio"
                  group="glutenFree"
                  name="glutenFree"
                  [disabled]="readonly"
                  [value]="true"
                  [(ngModel)]="product.spiritTechnical.glutenFree"
                  required
                />
                {{ 'general.yes' | translate }}
              </label>
            </div>
            <div class="uk-width-1-2">
              <label>
                <input
                  type="radio"
                  group="glutenFree"
                  name="glutenFree"
                  [disabled]="readonly"
                  [value]="false"
                  [(ngModel)]="product.spiritTechnical.glutenFree"
                  required
                />
                {{ 'general.no' | translate }}
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      [ngClass]="booleanColumn"
      [ngStyle]="
        !isBoolean(product.spiritTechnical.vegan) && form.submitted
          ? { color: 'red' }
          : { color: 'black' }
      "
    >
      <div class="uk-grid">
        <div [ngClass]="column">
          <label class="m-label">{{ 'product.vegan' | translate }} *</label>
        </div>
        <div [ngClass]="column">
          <div class="uk-grid uk-grid-small">
            <div class="uk-width-1-2">
              <label>
                <input
                  type="radio"
                  group="vegan"
                  [value]="true"
                  name="vegan"
                  [disabled]="readonly"
                  [(ngModel)]="product.spiritTechnical.vegan"
                  required
                />
                {{ 'general.yes' | translate }}
              </label>
            </div>
            <div class="uk-width-1-2">
              <label>
                <input
                  type="radio"
                  group="vegan"
                  [value]="false"
                  name="vegan"
                  [disabled]="readonly"
                  [(ngModel)]="product.spiritTechnical.vegan"
                  required
                />
                {{ 'general.no' | translate }}
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      [ngClass]="booleanColumn"
      [ngStyle]="
        !isBoolean(product.spiritTechnical.biodinamic) && form.submitted
          ? { color: 'red' }
          : { color: 'black' }
      "
    >
      <div class="uk-grid">
        <div [ngClass]="column">
          <label class="m-label">{{ 'product.biodinamic' | translate }} *</label>
        </div>
        <div [ngClass]="column">
          <div class="uk-grid uk-grid-small">
            <div class="uk-width-1-2">
              <label>
                <input
                  type="radio"
                  group="biodinamic"
                  [value]="true"
                  name="biodinamic"
                  [disabled]="readonly"
                  required
                  [(ngModel)]="product.spiritTechnical.biodinamic"
                />
                {{ 'general.yes' | translate }}
              </label>
            </div>
            <div class="uk-width-1-2">
              <label>
                <input
                  type="radio"
                  group="biodinamic"
                  [value]="false"
                  name="biodinamic"
                  [disabled]="readonly"
                  [(ngModel)]="product.spiritTechnical.biodinamic"
                  required
                />
                {{ 'general.no' | translate }}
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="uk-width-1-1">
      <div class="uk-grid">
        <div [ngClass]="column">
          <label class="m-label">{{ 'product.other' | translate }}</label>
        </div>
        <div [ngClass]="column">
          <textarea
            class="m-textarea"
            [readonly]="readonly"
            name="other"
            [(ngModel)]="product.spiritTechnical.other"
            rows="1"
          >
          </textarea>
        </div>
      </div>
    </div>
    <div class="uk-width-1-1">
      <div class="uk-grid">
        <div [ngClass]="column">
          <label class="m-label">{{ 'product.specifics' | translate }}</label>
        </div>
        <div [ngClass]="column">
          <textarea
            class="m-textarea"
            [readonly]="readonly"
            name="specifics"
            [(ngModel)]="product.spiritTechnical.specifics"
            rows="1"
          >
          </textarea>
        </div>
      </div>
    </div>
  </div>
  <!--cibo-->
  <div *ngIf="product.category == 'food'" class="uk-grid">
    <div
      [ngClass]="booleanColumn"
      [ngStyle]="
        !isBoolean(product.foodTechnical.biological) && form.submitted
          ? { color: 'red' }
          : { color: 'black' }
      "
    >
      <div class="uk-grid">
        <div [ngClass]="column">
          <label class="m-label">{{ 'product.biological' | translate }} *</label>
        </div>
        <div [ngClass]="column">
          <div class="uk-grid uk-grid-small">
            <div class="uk-width-1-2">
              <label>
                <input
                  type="radio"
                  group="bio"
                  [value]="true"
                  [disabled]="readonly"
                  name="bio"
                  [(ngModel)]="product.foodTechnical.biological"
                  required
                />
                {{ 'general.yes' | translate }}
              </label>
            </div>
            <div class="uk-width-1-2">
              <label>
                <input
                  type="radio"
                  group="bio"
                  [value]="false"
                  name="bio"
                  [disabled]="readonly"
                  [(ngModel)]="product.foodTechnical.biological"
                  required
                />
                {{ 'general.no' | translate }}
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      [ngClass]="booleanColumn"
      [ngStyle]="
        !isBoolean(product.foodTechnical.glutenFree) && form.submitted
          ? { color: 'red' }
          : { color: 'black' }
      "
    >
      <div class="uk-grid">
        <div [ngClass]="column">
          <label class="m-label">{{ 'product.gluten.free' | translate }} *</label>
        </div>
        <div [ngClass]="column">
          <div class="uk-grid uk-grid-small">
            <div class="uk-width-1-2">
              <label>
                <input
                  type="radio"
                  group="glutenFree"
                  [disabled]="readonly"
                  [value]="true"
                  name="glutenFree"
                  [(ngModel)]="product.foodTechnical.glutenFree"
                  required
                />
                {{ 'general.yes' | translate }}
              </label>
            </div>
            <div class="uk-width-1-2">
              <label>
                <input
                  type="radio"
                  group="glutenFree"
                  [disabled]="readonly"
                  [value]="false"
                  name="glutenFree"
                  [(ngModel)]="product.foodTechnical.glutenFree"
                  required
                />
                {{ 'general.no' | translate }}
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      [ngClass]="booleanColumn"
      [ngStyle]="
        !isBoolean(product.foodTechnical.vegan) && form.submitted
          ? { color: 'red' }
          : { color: 'black' }
      "
    >
      <div class="uk-grid">
        <div [ngClass]="column">
          <label class="m-label">{{ 'product.vegan' | translate }} *</label>
        </div>
        <div [ngClass]="column">
          <div class="uk-grid uk-grid-small">
            <div class="uk-width-1-2">
              <label>
                <input
                  type="radio"
                  group="vegan"
                  [disabled]="readonly"
                  [value]="true"
                  name="vegan"
                  required
                  [(ngModel)]="product.foodTechnical.vegan"
                />
                {{ 'general.yes' | translate }}
              </label>
            </div>
            <div class="uk-width-1-2">
              <label>
                <input
                  type="radio"
                  group="vegan"
                  [disabled]="readonly"
                  [value]="false"
                  name="vegan"
                  [(ngModel)]="product.foodTechnical.vegan"
                  required
                />
                {{ 'general.no' | translate }}
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      [ngClass]="booleanColumn"
      [ngStyle]="
        !isBoolean(product.foodTechnical.biodinamic) && form.submitted
          ? { color: 'red' }
          : { color: 'black' }
      "
    >
      <div class="uk-grid">
        <div [ngClass]="column">
          <label class="m-label">{{ 'product.biodinamic' | translate }} *</label>
        </div>
        <div [ngClass]="column">
          <div class="uk-grid uk-grid-small">
            <div class="uk-width-1-2">
              <label>
                <input
                  type="radio"
                  group="biodinamic"
                  [disabled]="readonly"
                  [value]="true"
                  name="biodinamic"
                  required
                  [(ngModel)]="product.foodTechnical.biodinamic"
                />
                {{ 'general.yes' | translate }}
              </label>
            </div>
            <div class="uk-width-1-2">
              <label>
                <input
                  type="radio"
                  group="biodinamic"
                  name="biodinamic"
                  [disabled]="readonly"
                  [value]="false"
                  [(ngModel)]="product.foodTechnical.biodinamic"
                  required
                />
                {{ 'general.no' | translate }}
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="uk-width-1-1">
      <div class="uk-grid">
        <div [ngClass]="column">
          <label class="m-label">{{ 'product.other' | translate }}</label>
        </div>
        <div [ngClass]="column">
          <textarea
            class="m-textarea"
            name="other"
            [readonly]="readonly"
            [(ngModel)]="product.foodTechnical.other"
            rows="1"
          >
          </textarea>
        </div>
      </div>
      <!--ng-required="product.category == 'food'"-->
    </div>
    <div class="uk-width-1-1">
      <div class="uk-grid">
        <div [ngClass]="column">
          <label class="m-label">{{ 'product.specifics' | translate }}</label>
        </div>
        <div [ngClass]="column">
          <textarea
            class="m-textarea"
            name="specifics"
            [readonly]="readonly"
            [(ngModel)]="product.foodTechnical.specifics"
            rows="1"
          >
          </textarea>
          <!--ng-required="product.category == 'food'"-->
        </div>
      </div>
    </div>
  </div>
  <!--birra-->
  <div *ngIf="product.category == 'beer'" class="uk-grid">
    <div
      [ngClass]="booleanColumn"
      [ngStyle]="
        !isBoolean(product.beerTechnical.biological) && form.submitted
          ? { color: 'red' }
          : { color: 'black' }
      "
    >
      <div class="uk-grid">
        <div [ngClass]="column">
          <label class="m-label">{{ 'product.biological' | translate }} *</label>
        </div>
        <div [ngClass]="column">
          <div class="uk-grid uk-grid-small">
            <div class="uk-width-1-2">
              <label>
                <input
                  type="radio"
                  group="bio"
                  name="bio"
                  [disabled]="readonly"
                  [value]="true"
                  [(ngModel)]="product.beerTechnical.biological"
                  required
                />
                {{ 'general.yes' | translate }}
              </label>
            </div>
            <div class="uk-width-1-2">
              <label>
                <input
                  type="radio"
                  group="bio"
                  name="bio"
                  [disabled]="readonly"
                  [value]="false"
                  [(ngModel)]="product.beerTechnical.biological"
                  required
                />
                {{ 'general.no' | translate }}
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      [ngClass]="booleanColumn"
      [ngStyle]="
        !isBoolean(product.beerTechnical.glutenFree) && form.submitted
          ? { color: 'red' }
          : { color: 'black' }
      "
    >
      <div class="uk-grid">
        <div [ngClass]="column">
          <label class="m-label">{{ 'product.gluten.free' | translate }} *</label>
        </div>
        <div [ngClass]="column">
          <div class="uk-grid uk-grid-small">
            <div class="uk-width-1-2">
              <label>
                <input
                  type="radio"
                  group="glutenFree"
                  [value]="true"
                  [disabled]="readonly"
                  name="glutenFree"
                  [(ngModel)]="product.beerTechnical.glutenFree"
                  required
                />
                {{ 'general.yes' | translate }}
              </label>
            </div>
            <div class="uk-width-1-2">
              <label>
                <input
                  type="radio"
                  group="glutenFree"
                  name="glutenFree"
                  [disabled]="readonly"
                  [value]="false"
                  [(ngModel)]="product.beerTechnical.glutenFree"
                  required
                />
                {{ 'general.no' | translate }}
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      [ngClass]="booleanColumn"
      [ngStyle]="
        !isBoolean(product.beerTechnical.vegan) && form.submitted
          ? { color: 'red' }
          : { color: 'black' }
      "
    >
      <div class="uk-grid">
        <div [ngClass]="column">
          <label class="m-label">{{ 'product.vegan' | translate }} *</label>
        </div>
        <div [ngClass]="column">
          <div class="uk-grid uk-grid-small">
            <div class="uk-width-1-2">
              <label>
                <input
                  type="radio"
                  group="vegan"
                  name="vegan"
                  [disabled]="readonly"
                  [value]="true"
                  [(ngModel)]="product.beerTechnical.vegan"
                  required
                />
                {{ 'general.yes' | translate }}
              </label>
            </div>
            <div class="uk-width-1-2">
              <label>
                <input
                  type="radio"
                  group="vegan"
                  name="vegan"
                  [value]="false"
                  [disabled]="readonly"
                  [(ngModel)]="product.beerTechnical.vegan"
                  required
                />
                {{ 'general.no' | translate }}
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      [ngClass]="booleanColumn"
      [ngStyle]="
        !isBoolean(product.beerTechnical.biodinamic) && form.submitted
          ? { color: 'red' }
          : { color: 'black' }
      "
    >
      <div class="uk-grid">
        <div [ngClass]="column">
          <label class="m-label">{{ 'product.biodinamic' | translate }} *</label>
        </div>
        <div [ngClass]="column">
          <div class="uk-grid uk-grid-small">
            <div class="uk-width-1-2">
              <label>
                <input
                  type="radio"
                  group="biodinamic"
                  name="biodinamic"
                  [disabled]="readonly"
                  [value]="true"
                  required
                  [(ngModel)]="product.beerTechnical.biodinamic"
                />
                {{ 'general.yes' | translate }}
              </label>
            </div>
            <div class="uk-width-1-2">
              <label>
                <input
                  type="radio"
                  group="biodinamic"
                  name="biodinamic"
                  [value]="false"
                  [disabled]="readonly"
                  [(ngModel)]="product.beerTechnical.biodinamic"
                  required
                />
                {{ 'general.no' | translate }}
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="uk-width-1-1">
      <div class="uk-grid">
        <div [ngClass]="column">
          <label class="m-label">{{ 'product.other' | translate }}</label>
        </div>
        <div [ngClass]="column">
          <textarea
            class="m-textarea"
            [readonly]="readonly"
            [(ngModel)]="product.beerTechnical.other"
            name="other"
            rows="1"
          >
          </textarea>
        </div>
      </div>
    </div>
    <div class="uk-width-1-1">
      <div class="uk-grid">
        <div [ngClass]="column">
          <label class="m-label">{{ 'product.specifics' | translate }}</label>
        </div>
        <div [ngClass]="column">
          <textarea
            class="m-textarea"
            name="specifics"
            [readonly]="readonly"
            [(ngModel)]="product.beerTechnical.specifics"
            rows="2"
          >
          </textarea>
        </div>
      </div>
    </div>
  </div>
</form>
