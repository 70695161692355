import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DaterangepickerComponent} from './daterangepicker.component';
import {FormsModule} from '@angular/forms';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import {defineLocale} from 'ngx-bootstrap/chronos';
import {itLocale} from 'ngx-bootstrap/locale';

defineLocale('it', itLocale);

@NgModule({
    declarations: [DaterangepickerComponent],
    imports: [
        CommonModule,
        FormsModule,
        BsDatepickerModule.forRoot()
    ],
    exports: [DaterangepickerComponent]
})
export class DaterangepickerModule {
}
