import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { StoreService } from './store.service';
import * as _ from 'lodash';
import { isNullOrUndefined } from 'util';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(private http: HttpClient, private storeService: StoreService) {}

  me() {
    return this.http.get<any>(`${environment.server}/user/me`);
  }

  getIncompleteInfoArray(user: any) {
    const toRet = [];
    if (user.userType !== 'admin' && user.userType !== 'board') {
      if (!user.category) {
        toRet.push('category');
      }

      if (!user.canteen || !user.canteen.businessName) {
        toRet.push('canteenBusinessName');
      }

      if (!user.canteen || !user.canteen.address) {
        toRet.push('canteenAddress');
      }

      if (!user.canteen || !user.canteen.postalCode) {
        toRet.push('canteenPostalCode');
      }

      if (!user.canteen || !user.canteen.city) {
        toRet.push('canteenCity');
      }

      if (!user.canteen || !user.canteen.country) {
        toRet.push('canteenCountry');
      }

      if (
        (!user.canteen || !user.canteen.district) &&
        user.canteen &&
        (user.canteen.country == 'IT' || user.canteen.country == 'Italia')
      ) {
        toRet.push('canteenDistrict');
      }

      if (
        (!user.canteen || !user.canteen.province) &&
        user.canteen &&
        (user.canteen.country == 'IT' || user.canteen.country == 'Italia')
      ) {
        toRet.push('canteenProvince');
      }

      if (!user.canteen || !user.canteen.phone) {
        toRet.push('canteenPhone');
      }

      if (!user.canteen || !user.canteen.corporateEmail) {
        toRet.push('canteenCorporateEmail');
      }

      if (user.category == 'wine' && (!user.wineDetails || !user.wineDetails.zone)) {
        toRet.push('wineDetailsZone');
      }

      if (
        user.category == 'wine' &&
        (!user.wineDetails || isNullOrUndefined(user.wineDetails.productionBottles))
      ) {
        toRet.push('wineDetailsProductionBottles');
      }

      if (
        user.category == 'wine' &&
        (!user.wineDetails || isNullOrUndefined(user.wineDetails.area))
      ) {
        toRet.push('wineDetailsArea');
      }

      if (user.category == 'wine' && (!user.wineDetails || !user.wineDetails.winemaker)) {
        toRet.push('wineDetailsWinemaker');
      }

      if (user.category == 'beer' && (!user.beerDetails || !user.beerDetails.productionQuantity)) {
        toRet.push('beerDetailsProductionQuantity');
      }

      if (
        user.category == 'wine' &&
        (!user.canteen || !user.canteen.agricultureType || user.canteen.agricultureType.length < 1)
      ) {
        toRet.push('canteenAgricultureType');
      }

      if (!user.society || !user.society.businessName) {
        toRet.push('societyBusinessName');
      }

      if (!user.society || !user.society.address) {
        toRet.push('societyAddress');
      }

      if (!user.society || !user.society.postalCode) {
        toRet.push('societyPostalCode');
      }

      if (!user.society || !user.society.city) {
        toRet.push('societyCity');
      }

      if (!user.society || !user.society.country) {
        toRet.push('societyCountry');
      }

      if (
        (!user.society || !user.society.district) &&
        user.society &&
        (user.society.country == 'IT' || user.society.country == 'Italia')
      ) {
        toRet.push('societyDistrict');
      }

      if (
        (!user.society || !user.society.province) &&
        user.society &&
        (user.society.country == 'IT' || user.society.country == 'Italia')
      ) {
        toRet.push('societyProvince');
      }

      if (!user.society || (!user.society.fiscalCode && !user.society.vatNumber)) {
        toRet.push('societyFiscalCodeVatNumber');
      }

      if (!user.society || !user.society.electronicInvoiceCode) {
        toRet.push('societyElectronicInvoiceCode');
      }

      if (!user.society || !user.society.certifiedEmail) {
        toRet.push('societyCertifiedEmail');
      }

      if (!user.society || !user.society.administrativeEmail) {
        toRet.push('societyAdministrativeEmail');
      }

      if (!user.referent || !user.referent.email) {
        toRet.push('referentEmail');
      }
      if (!user.referent || !user.referent.role) {
        toRet.push('referentRole');
      }
      if (!user.referent || !user.referent.phone) {
        toRet.push('referentPhone');
      }
      if (!user.referent || !user.referent.name) {
        toRet.push('referentName');
      }
      if (!user.referent || !user.referent.surname) {
        toRet.push('referentSurname');
      }
    }
    return toRet;
  }

  get(userId: string) {
    return this.http.get(`${environment.server}/user/${userId}`);
  }

  modify(userId: string, user: any, hideSpinner: boolean = false) {
    return this.http.put(environment.server + '/user/' + userId, user, {
      params: { hideSpinner: hideSpinner.toString() }
    });
  }

  verifyEmail(email: string) {
    return this.http.get(`${environment.server}/user/verifyEmail?email=${email}`);
  }

  verify() {}

  signup(user) {
    return this.http.post(`${environment.server}/signup`, user);
  }

  create(user) {
    return this.http.post(`${environment.server}/user`, user);
  }

  registerCommissionUser(data) {
    return this.http.post(`${environment.server}/user/commission`, data);
  }

  setUserInfo(
    userId: string,
    userType: string,
    username: string,
    category: string,
    profileIncomplete: boolean,
    taxData: any
  ) {
    this.storeService.set('userId', userId);
    this.storeService.set('userType', userType);
    this.storeService.set('username', username);
    this.storeService.set('category', category);
    this.storeService.set('profileIncomplete', profileIncomplete);
    localStorage.setItem('taxData', JSON.stringify(taxData));
  }

  changePassword(password, userId) {
    return this.http.put(`${environment.server}/user/${userId}/passwordchange`, {
      password
    });
  }

  recoverPassword(email) {
    return this.http.get(`${environment.server}/recover?email=${email.toLowerCase()}`);
  }

  resetPassword(password, key) {
    return this.http.post(`${environment.server}/reset-password/${key}`, {
      password
    });
  }

  verifyUser(key: string) {
    return this.http.post(`${environment.server}/verify/${key}`, {});
  }

  acceptPrivacy() {
    return this.http.put(`${environment.server}/user/privacy`, {});
  }

  getAllBasic(page: number, limit: number, searchObject?: any) {
    if (!searchObject) {
      searchObject = {};
    }
    return this.search(page, limit, searchObject);
  }

  search(page: number, limit: number, searchObject, isCSVExport = false) {
    return this.http.post(
      environment.server +
        '/user/search?page=' +
        page +
        '&limit=' +
        limit +
        '&isCSVExport=' +
        isCSVExport,
      searchObject
    );
  }

  changeUserPassword(password: string, userId: string) {
    return this.http.put(environment.server + '/user/' + userId + '/change-password', {
      password
    });
  }

  getAutocomplete(token: string, searchObject: any) {
    return this.http.post(`${environment.server}/user/autocomplete/${token}`, searchObject);
  }

  delete(userId: string) {
    return this.http.delete(`${environment.server}/user/${userId}`);
  }

  transferProperty(userId, toUserId) {
    return this.http.put(`${environment.server}/user/${userId}/transfer/${toUserId}`, {});
  }

  getUserBusinessName(userData: any) {
    return _.get(userData, 'canteen.businessName', _.get(userData, 'society.businessName', ''));
  }

  deleteCommission(id: string) {
    return this.http.delete(environment.server + '/user/commission/' + id);
  }

  getCommissionByUsername(username: string, hideSpinner: boolean = false) {
    return this.http.post(
      `${environment.server}/user/commission/username`,
      {
        username
      },
      { params: { hideSpinner: hideSpinner.toString() } }
    );
  }

  getUserByUserCode(progressiveId: string, progressiveCode: string, hideSpinner: boolean = false) {
    return this.http.post(
      `${environment.server}/user/usercode`,
      {
        progressiveId,
        progressiveCode
      },
      { params: { hideSpinner: hideSpinner.toString() } }
    );
  }
}
