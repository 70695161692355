import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UploadImageBoxComponent } from './upload-image-box.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [UploadImageBoxComponent],
  imports: [CommonModule, TranslateModule],
  exports: [UploadImageBoxComponent]
})
export class UploadImageBoxModule {}
