import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EventService {
  constructor(private http: HttpClient) {}

  get(id: string) {
    return this.http.get(`${environment.server}/event/${id}`);
  }

  add(data: any) {
    return this.http.post(`${environment.server}/event`, data);
  }

  modify(id: string, data: any) {
    return this.http.put(`${environment.server}/event/${id}`, data);
  }

  search(page, limit, searchObject) {
    return this.http.post(
      `${environment.server}/event/search?page=${page}&limit=${limit}`,
      searchObject
    );
  }

  searchEnded(page, limit, searchObject) {
    return this.http.post(
      `${environment.server}/event/search/ended?page=${page}&limit=${limit}`,
      searchObject
    );
  }

  getAll(page: number, limit: number, options: {} = {}) {
    return this.search(page, limit, options);
  }

  getAutocomplete(token: string, searchFilter) {
    return this.http.post(`${environment.server}/event/autocomplete/${token}`, searchFilter);
  }

  getEventsByUser(obj) {
    return this.http.post(`${environment.server}/event/user/events`, obj);
  }

  getEventGroupsByUser() {
    return this.http
      .get<{ response: any[] }>(`${environment.server}/event/user/eventgroups`)
      .pipe(map(res => res.response));
  }

  countBuyedTickets(eventId) {
    return this.http.get(`${environment.server}/event/${eventId}/tickets`);
  }

  deleteSection(eventID: string) {
    return this.http.delete(`${environment.server}/event/${eventID}`);
  }
}
