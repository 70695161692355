<div class="privacy uk-container uk-container-center">
    <div class="uk-text-right" *ngIf="isModal">
        <h3 style="cursor: pointer; cursor: hand;" (click)="close()">
            <i class="fa fa-times"></i>
        </h3>
    </div>

    <div class="gdpr-container">
        <label class="m-label">Trattativa dati personali</label>
        <div class="gdpr" [innerHtml]="'gdpr.text' | translate">
        </div>
    </div>
</div>