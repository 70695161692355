import { Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { EnumService } from '../../../core/enum.service';
import { ModalService } from '../../../core/modal.service';
import { TranslateService } from '@ngx-translate/core';
import { CountryService } from '../../../core/country.service';
import { ProductService } from 'core/product.service';

@Component({
  selector: 'app-registration-step-canteen',
  templateUrl: './registration-step-canteen.component.html',
  styleUrls: ['./registration-step-canteen.component.scss'],
})
export class RegistrationStepCanteenComponent implements OnInit, OnChanges {
  @Input() user: any = {
    canteen: {} as any,
    wineDetails: {} as any,
    beerDetails: {} as any,
  };
  @ViewChild('form') form;
  agricultureTypes: any[] = [];
  countries: any[] = [];
  districts: any[] = [];
  provinces: any[] = [];
  microzones: any[] = [];
  microzonesToShow: any[] = [];

  constructor(
    private enumService: EnumService,
    private modalService: ModalService,
    private translateService: TranslateService,
    private countryService: CountryService,
    private productService: ProductService
  ) { }

  ngOnInit() {
    this.agricultureTypes = this.enumService.getAgricultureTypes();
    this.countryService.getCountries().subscribe((r: any) => {
      this.countries = r;
      this.user.canteen.country = 'IT';
      this.selectCountry();
    });

  }

  ngOnChanges() {
    if (this.user) {
      if (this.user.category === 'wine' && !this.user.wineDetails) {
        this.user.wineDetails = {} as any;
      } else if (this.user.category === 'beer' && !this.user.beerDetails) {
        this.user.beerDetails = {} as any;
      }
      if (!this.user.canteen) {
        this.user.canteen = {} as any;
      }
      if (this.user.category === 'wine' && !this.user.canteen.agricultureType) {
        this.user.canteen.agricultureType = [''];
      }
      this.selectCountry();
      this.selectDistrict();
      this.selectMicrozone();
    }
  }

  isValid() {
    let isValid: boolean = this.form.valid;
    if (
      this.user.category === 'wine' &&
      (!this.user.canteen.agricultureType ||
        this.user.canteen.agricultureType.find((a) => !a) === '')
    ) {
      isValid = false;
    }

    return isValid;
  }

  validate() {
    this.form.submitted = true;
    if (!this.isValid()) {
      if (
        this.form.controls.corporateEmail &&
        this.form.controls.corporateEmail.invalid
      ) {
        this.modalService.error(
          this.translateService.instant('general.form.error.invalidmail')
        );
      } else {
        this.modalService.error(
          this.translateService.instant('general.form.error')
        );
      }
      return false;
    }

    return true;
  }

  getUser() {
    return this.user;
  }

  addAgricultureType() {
    if (!this.user.canteen.agricultureType) {
      this.user.canteen.agricultureType = [];
    }
    this.user.canteen.agricultureType.push('');
  }

  removeAgricultureType(index) {
    this.user.canteen.agricultureType.splice(index, 1);
  }

  selectCountry() {
    if (!this.user.canteen.country) {
      return;
    }
    const phoneCode = this.countries.filter(c => c.code === this.user.canteen.country);
    if (phoneCode.length > 0) {
      this.user.canteen.prefixPhone = phoneCode[0].phoneCode;
      this.user.canteen.prefixFax = phoneCode[0].phoneCode;
    }
    this.countryService
      .getDistrictsByCountry(this.user.canteen.country)
      .subscribe((r: any[]) => {
        this.districts = r;
      });
  }

  selectDistrict() {
    if (!this.user.canteen.district || !this.user.canteen.country) {
      return;
    }
    this.countryService
      .getProvincesByDistrict(
        this.user.canteen.district,
        this.user.canteen.country
      )
      .subscribe((r: any[]) => {
        this.provinces = r;

        this.productService.getWineMicrozones().subscribe((res: any) => {
          this.microzones = res.response;
          this.microzonesToShow = this.microzones.filter(
            zone => zone.regione === this.user.canteen.district
          );
        })
      });


  }

  selectMicrozone() {
    this.productService.getWineMicrozones().subscribe((res: any) => {
      this.microzones = res.response;
    })
  }

  setMicrozone() {
    this.user.canteen.microzone = null;
    this.user.canteen.manualMicrozone = null;
  }

  checkPhoneNumber(event) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode >= 43 && charCode <= 57)) {
      return true;
    }
    return false;
  }

}
