import {Component, OnInit, Input, OnChanges} from '@angular/core';
import {StoreService} from "../../core/store.service";

@Component({
    selector: 'app-products-visualizer',
    templateUrl: './products-visualizer.component.html',
    styleUrls: ['./products-visualizer.component.scss']
})
export class ProductsVisualizerComponent implements OnInit, OnChanges {

    @Input() selectedProducts: any[] = [];
    @Input() category;
    @Input() miniVerticale = false;
    @Input() annate: string[];
    userType: string;

    currentYear = new Date().getFullYear().toString();
    
    constructor(private storeService: StoreService) {
    }

    ngOnInit() {
        this.userType = this.storeService.get("userType");
    }


    ngOnChanges() {
        // TODO verify if selectedProducts parsing
        // is correct for all product categories

        //console.log("selectedProducts", this.selectedProducts)
        //console.log("category", this.category)
    }

    getYearsString(years) {
        if (Array.isArray(years)) {
            return years.map((year) => year.year).join(', ');
        } else {
            return years;
        }
    }
}
