<div class="register uk-height-viewport">
    <app-lang-navbar></app-lang-navbar>

    <div class="m-modal">

        <div class="logo"><img src="/assets/img/logo.png"></div>

        <app-form-progress [steps]="steps"
                           [currentStep]="step"
                           size="large">
        </app-form-progress>


        <app-registration-step-gdpr #stepGdpr *ngIf="step === 0">
        </app-registration-step-gdpr>

        <app-registration-step-account #stepAccount *ngIf="step === 1"
                                       [user]="user"
                                       (userCategoryChanged)="initForm()">
        </app-registration-step-account>

        <app-registration-step-canteen #stepCanteen *ngIf="step === 2" [user]="user">
        </app-registration-step-canteen>

        <app-registration-step-society #stepSociety *ngIf="step === 3" [user]="user">
        </app-registration-step-society>

        <app-registration-step-referent #stepReferent *ngIf="step === 4" [user]="user">
        </app-registration-step-referent>

        <app-registration-step-logo #stepLogo *ngIf="step === 5 && user.category !== 'wine'"
                                    [user]="user">
        </app-registration-step-logo>


        <div class="bottom-buttons">
            <div class="uk-grid uk-grid-small">
                <div class="uk-width-small-1-2">
                    <button class="m-button m-button--disabled"
                            *ngIf="step > 0"
                            (click)="prevStep()">{{"general.indietro" | translate}}
                    </button>
                </div>
                <div class="uk-width-small-1-2">
                    <button class="m-button"
                            (click)="nextStep()">
                        <span>{{"general.avanti" | translate}}</span>
                    </button>
                </div>
            </div>
        </div>

        <div class="bottom-section">
            {{'login.already_registered' | translate}} <a routerLink="/login">{{'login.go_to_login' | translate}}</a>
        </div>
    </div>

    <app-copyright></app-copyright>
</div>
