<form #form="ngForm" [ngClass]="{ submitted: form.submitted }" novalidate>
  <!--vino-->
  <div *ngIf="product.category == 'wine'" class="uk-grid">
    <div class="uk-width-1-1">
      <div class="uk-grid">
        <div [ngClass]="column">
          <label class="m-label">{{ 'product.price' | translate }} *</label>
        </div>
        <div [ngClass]="column">
          <input
            type="text"
            inputDecimal
            (decimalChanged)="product.wineTechnical.price = $event"
            class="m-input"
            name="price"
            [readonly]="readonly"
            [(ngModel)]="product.wineTechnical.price"
            required
          />
        </div>
      </div>
    </div>
    <div
      class="uk-width-1-1"
      [ngClass]="{ 'ng-invalid': form.submitted && !product.wineTechnical.releaseDate }"
    >
      <div class="uk-grid">
        <div [ngClass]="column">
          <label class="m-label">{{ 'product.date' | translate }} *</label>
        </div>
        <div [ngClass]="column">
          <input
            type="text"
            class="m-input"
            name="releaseDate"
            [readonly]="readonly"
            [(ngModel)]="product.wineTechnical.releaseDate"
            [bsConfig]="{ isAnimated: false, dateInputFormat: 'DD/MM/YYYY', placement: 'bottom' }"
            bsDatepicker
          />
        </div>
      </div>
    </div>
    <div class="uk-width-1-1">
      <div class="uk-grid">
        <div [ngClass]="column">
          <label class="m-label">{{ 'product.longevity' | translate }} *</label>
        </div>
        <div [ngClass]="column">
          <div
            class="m-select"
            [ngClass]="{ 'ng-invalid': form.submitted && !product.wineTechnical.longevity }"
          >
            <select
              [(ngModel)]="product.wineTechnical.longevity"
              name="longevity"
              required
              [disabled]="readonly"
            >
              <option value=""></option>
              <option *ngFor="let cat of longevity" value="{{ cat.code }}">
                {{ cat.name }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="uk-width-1-1">
      <div class="uk-grid">
        <div [ngClass]="column">
          <label class="m-label">{{ 'product.international.presence' | translate }}</label>
        </div>
        <div [ngClass]="column">
          <div class="m-select">
            <select
              [(ngModel)]="markets"
              (change)="!markets ? (product.wineTechnical.markets = []) : ''"
              name="markets"
              [disabled]="readonly"
            >
              <option [ngValue]="true">{{ 'general.yes' | translate }}</option>
              <option [ngValue]="false">{{ 'general.no' | translate }}</option>
            </select>
          </div>
          <!--style="margin-left: 30px; padding-top: 10px;"-->
          <div *ngIf="markets">
            <h5 style="font-weight: 600;">{{ 'product.add.country' | translate }}</h5>
            <app-product-markets
              [countries]="countries"
              [markets]="product.wineTechnical?.markets"
              [inline]="productDetailMode"
              [readonly]="readonly"
              (marketsUpdated)="product.wineTechnical.markets = $event"
            >
            </app-product-markets>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
