import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'dot'
})
export class DotPipe implements PipeTransform {

    transform(value: any, param?: number): any {
        if (!value) {
            return ""
        }

        if (!param) {
            if (value.length <= 40) {
                return value;
            }

            return value.substring(0, 40) + '...';
        }

        if (value.length <= param) {
            return value;
        }

        return value.substring(0, param) + '...';
    }

}
