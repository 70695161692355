<form #form="ngForm" [ngClass]="{ submitted: form.submitted }" novalidate>
  <!--vino-->
  <div *ngIf="product.category == 'wine'" class="uk-grid">
    <div class="uk-width-1-1">
      <div class="uk-grid">
        <div [ngClass]="column">
          <label class="m-label">{{ 'product.ageVines' | translate }} *</label>
        </div>
        <div [ngClass]="column">
          <input
            type="text"
            inputDecimal
            (decimalChanged)="product.wineTechnical.ageVines = $event"
            name="ageVines"
            class="m-input"
            [readonly]="readonly"
            [(ngModel)]="product.wineTechnical.ageVines"
            required
          />
        </div>
      </div>
    </div>
    <div class="uk-width-1-1">
      <div class="uk-grid">
        <div [ngClass]="column">
          <label class="m-label">{{ 'product.vineyard' | translate }}</label>
        </div>
        <div [ngClass]="column">
          <div class="m-select">
            <!--[ngClass]="{'ng-invalid' : form..submitted && !product.wineTechnical.vineyard }"-->
            <select
              [(ngModel)]="product.wineTechnical.vineyard"
              name="vineyard"
              [disabled]="readonly"
            >
              <option value=""></option>
              <option *ngFor="let cat of vineyard" value="{{ cat.code }}">
                {{ cat.name }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="uk-width-1-1">
      <div class="uk-grid">
        <div [ngClass]="column">
          <label class="m-label">{{ 'product.ground' | translate }} *</label>
        </div>
        <div [ngClass]="column">
          <input
            type="text"
            class="m-input"
            name="ground"
            [(ngModel)]="product.wineTechnical.ground"
            required
            [readonly]="readonly"
          />
        </div>
      </div>
    </div>
    <div class="uk-width-1-1">
      <div class="uk-grid">
        <div [ngClass]="column">
          <label class="m-label">{{ 'product.irrigation' | translate }}</label>
        </div>
        <div [ngClass]="column">
          <div class="m-select">
            <!--[ngClass]="{'ng-invalid' : form..submitted && !product.wineTechnical.irrigation }"-->
            <select
              [(ngModel)]="product.wineTechnical.irrigation"
              name="irrigation"
              [disabled]="readonly"
            >
              <option value=""></option>
              <option *ngFor="let cat of irrigation" value="{{ cat.code }}">
                {{ cat.name }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
