import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreService } from './store.service';
import { AuthenticationService } from './authentication.service';
import { UserService } from './user.service';
import { CookieService } from 'ngx-cookie-service';
import { SearchCacheService } from './search-cache.service';
import { ModalService } from './modal.service';
import { EventService } from './event.service';
import { EventGroupService } from './event-group.service';
import { EvaluationService } from './evaluation.service';
import { CommissionService } from './commission.service';
import { CommonService } from './common.service';
import { FileService } from './file.service';
import { ExportService } from './export.service';
import { EnumService } from './enum.service';
import { CountryService } from './country.service';
import { RegionService } from './region.service';
import { ProductService } from './product.service';
import { NotificationService } from './notification.service';
import { TranslationLoaderResolver } from './translation-loader.resolver';
import { EventReferentService } from './event-referent.service';
import { SettingsService } from './settings.service';

@NgModule({
  declarations: [],
  imports: [CommonModule]
})
export class CoreModule {
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        StoreService,
        AuthenticationService,
        UserService,
        CookieService,
        SearchCacheService,
        ModalService,
        EventService,
        EventGroupService,
        EventReferentService,
        EvaluationService,
        CommissionService,
        CommonService,
        FileService,
        ExportService,
        EnumService,
        CountryService,
        RegionService,
        ProductService,
        NotificationService,
        TranslationLoaderResolver,
        SettingsService
      ]
    };
  }
}
