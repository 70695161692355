import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthenticationService } from './authentication.service';
import { StoreService } from './store.service';
import { finalize } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonService } from './common.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(
    private authenticationService: AuthenticationService,
    private storeService: StoreService,
    public spinnerService: NgxSpinnerService,
    private commonService: CommonService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.storeService.get('token');
    if (token) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      });
    }
    if (
      this.commonService.isUrlToHideSpinner(
        request.url,
        request.params.has('hideSpinner') && request.params.get('hideSpinner') === 'true'
      )
    ) {
      return next.handle(request);
    } else {
      this.spinnerService.show();
      return next.handle(request).pipe(finalize(() => this.spinnerService.hide()));
    }
  }
}
