import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { CommonService } from './common.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { FileService } from './file.service';

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  constructor(private http: HttpClient, private commonService: CommonService, private fileService: FileService) { }

  add(product: any) {
    return this.http.post(environment.server + '/product', product);
  }

  getAll(page: number, limit: number, options: {} = {}) {
    return this.search(page, limit, options);
  }

  search(page, limit, searchObject, hideSpinner: boolean = false, forExport: boolean = false) {
    return this.http.post(
      environment.server + '/product/search?page=' + page + '&limit=' + limit,
      searchObject,
      { params: { hideSpinner: hideSpinner.toString(), forExport: forExport.toString() } }
    );
  }

  getByUser(userId: string, query?: any) {
    return this.http.get(`${environment.server}/product/user/${userId}`, { params: query });
  }

  get(productId: string, hideSpinner: boolean = false) {
    return this.http.get(`${environment.server}/product/${productId}`, {
      params: { hideSpinner: hideSpinner.toString() }
    });
  }

  getWineVarieties(): Observable<any> {
    return this.http.post(`${environment.server}/product/wine-varieties`, {});
  }

  getWineDenominations(): Observable<any> {
    return this.http.post(`${environment.server}/product/wine-denominations`, {});
  }

  getWineMicrozones(): Observable<any> {
    return this.http.post(`${environment.server}/product/wine-microzones`, {});
  }

  getWineSpecifications(): Observable<any> {
    return this.http.post(`${environment.server}/product/wine-specifications`, {});
  }

  createItem(obj) {
    return this.http.post(environment.server + '/product', obj);
  }

  connectToCommission(productId: string, commissionId: string, hideSpinner: boolean = false) {
    return this.http.put(
      environment.server + '/product/' + productId + '/commission/' + commissionId,
      {},
      { params: { hideSpinner: hideSpinner.toString() } }
    );
  }

  removeCommissionFromProduct(productId) {
    return this.http.delete(environment.server + '/product/' + productId + '/commission', {});
  }

  generateProductImagesZip(body: { id: any[]; category: any }, hideSpinner: boolean = false) {
    return this.http
      .post(environment.server + '/attachment/zip', body, { params: { hideSpinner: hideSpinner.toString() } });

  }

  modify(productId: any, productToModify: any, hideSpinner: boolean = false) {
    productToModify = this.convertStringsToNumber(productToModify);
    return this.http.put(
      environment.server + '/product/' + productId,
      {
        obj: productToModify
      },
      { params: { hideSpinner: hideSpinner.toString() } }
    );
  }

  clone(productId) {
    return this.http.post(environment.server + '/product/' + productId + '/clone', {});
  }

  deleteProduct(productId) {
    return this.http.delete(environment.server + '/product/' + productId, {});
  }

  zipAttachment(obj) {
    return this.http.post(environment.server + '/attachment/zip/', obj);
  }

  private convertStringsToNumber(product) {
    if (product.spiritTechnical) {
      product.spiritTechnical.degree = this.commonService.stringToFloat(
        product.spiritTechnical.degree
      );
    }
    if (product.wineTechnical) {
      product.wineTechnical.sugarResidue = this.commonService.stringToFloat(
        product.wineTechnical.sugarResidue
      );
      product.wineTechnical.alcoholPerc = this.commonService.stringToFloat(
        product.wineTechnical.alcoholPerc
      );
      product.wineTechnical.acidity = this.commonService.stringToFloat(
        product.wineTechnical.acidity
      );
      product.wineTechnical.price = this.commonService.stringToFloat(product.wineTechnical.price);
    }
    if (product.beerTechnical) {
      product.beerTechnical.degree = this.commonService.stringToFloat(product.beerTechnical.degree);
    }

    return product;
  }
}
