import { Component, Input, OnChanges } from "@angular/core";

@Component({
  selector: "app-award-icon",
  templateUrl: "./award-icon.component.html",
  styleUrls: ["./award-icon.component.scss"]
})
export class AwardIconComponent implements OnChanges {
  isAfterAward = false; //Indica se si deve mostrare l'award
  @Input() year: number = null;
  @Input() evaluation: number = null;
  @Input() showAward = false; // Indica se mostrare l'award senza considerare l'anno
  @Input() showText = false;

  constructor() {}

  ngOnChanges() {
    if (this.year !== null && this.evaluation !== null) {
      const today = new Date();
      this.isAfterAward =
        this.year &&
        (this.year < today.getFullYear() ||
          today > new Date(today.getFullYear(), 7, 16, 0, 0, 0));
    }
  }
}
