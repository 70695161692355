import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EventRegistrationService } from './event-registration.service';
import { StoreService } from './store.service';
import { FileService } from './file.service';

declare var swal: any;

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  constructor(
    private translateService: TranslateService,
    private storeService: StoreService,
    private eventRegistrationService: EventRegistrationService,
    private fileService: FileService
  ) {}

  error(message: string, timeout: number = 5000) {
    return swal.fire({
      title: this.translateService.instant('general.error.title'),
      text: message,
      type: 'error',
      // showCancelButton: true,
      confirmButtonText: this.translateService.instant('general.ok'),
      buttonsStyling: false,
      confirmButtonClass: 'm-button m.button--ko',
      timer: timeout || 4000,
      // cancelButtonText: 'No, keep it'
    });
  }

  success(message: string, timeout: number = 5000) {
    return swal.fire({
      // title: 'Errore',
      text: message,
      type: 'success',
      // showCancelButton: true,
      confirmButtonText: this.translateService.instant('general.ok'),
      buttonsStyling: false,
      confirmButtonClass: 'm-button m-button--ok',
      timer: timeout || 4000,
      // cancelButtonText: 'No, keep it'
    });
  }

  info(message: string, timeout: number = 5000) {
    return swal.fire({
      html: message,
      type: 'question',
      showCancelButton: true,
      confirmButtonText: this.translateService.instant('general.ok'),
      buttonsStyling: false,
      confirmButtonClass: 'm-button m-button--ok',
      timer: timeout || 4000,
      cancelButtonText: this.translateService.instant('general.annulla'),
      cancelButtonClass: 'm-button m-button--ko',
    });
  }

  errorHtml(html: string, timeout: number = 5000) {
    return swal.fire({
      title: this.translateService.instant('general.error.title'),
      html,
      type: 'error',
      // showCancelButton: true,
      confirmButtonText: this.translateService.instant('general.ok'),
      buttonsStyling: false,
      confirmButtonClass: 'm-button m.button--ko',
      timer: timeout || 4000,
      // cancelButtonText: this.translateService.instant("login.resend.email")
    });
  }

  successHtml(html: string, timeout: number = 5000) {
    return swal.fire({
      html,
      type: 'success',
      // showCancelButton: true,
      confirmButtonText: this.translateService.instant('general.ok'),
      buttonsStyling: false,
      confirmButtonClass: 'm-button m-button--ok',
      timer: timeout || 4000,
      // cancelButtonText: 'No, keep it'
    });
  }

  selectEventAndPrintRegistrations(events: any[]) {
    const inputOptions = {};
    events.forEach((event) => {
      inputOptions[event._id] = event.name;
    });

    return swal.fire({
      title: 'Per quale evento vuoi stampare la lista iscrizioni?',
      input: 'select',
      inputOptions,
      inputPlaceholder: 'Seleziona un evento',
      showCancelButton: true,
      confirmButtonText: 'Scarica',
      cancelButtonText: 'Cancella',
      showLoaderOnConfirm: true,
      preConfirm: (event) => {
        this.storeService.setDirect('jwt', this.storeService.get('token'));
        const pdfLink = this.eventRegistrationService.printAll(event);
        this.fileService.downloadFileByUrl(pdfLink);
      },
      allowOutsideClick: () => !swal.isLoading(),
    });
  }

  showFormError() {
    return this.error(this.translateService.instant('general.form.error'));
  }

  showGenericError() {
    return this.error(this.translateService.instant('general.error'));
  }
}
