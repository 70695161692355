import { Component, Input, OnInit, ViewChild, OnChanges } from '@angular/core';
import { ModalService } from '../../../core/modal.service';
import { CountryService } from '../../../core/country.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-registration-step-society',
  templateUrl: './registration-step-society.component.html',
  styleUrls: ['./registration-step-society.component.scss'],
})
export class RegistrationStepSocietyComponent implements OnInit, OnChanges {
  @Input() user: any = {
    society: {} as any,
  };
  @ViewChild('form') form;
  countries: any[] = [];
  districts: any[] = [];
  provinces: any[] = [];
  certifiedEmailDisable: boolean;
  electronicInvoiceCodeDisable: boolean;

  constructor(
    private modalService: ModalService,
    private translateService: TranslateService,
    private countryService: CountryService
  ) {}

  ngOnInit() {
    this.countryService.getCountries().subscribe((r: any) => {
      this.countries = r;
    });
    this.user.society.country = 'IT';
    this.selectCountry();
  }

  ngOnChanges() {
    if (this.user) {
      if (!this.user.society) {
        this.user.society = {} as any;
      }

      this.certifiedEmailDisable = this.user.society?.certifiedEmail === 'NON PRESENTE';

      this.selectDistrict();
    }
  }

  isValid() {
    const isValid: boolean = this.form.valid;
    return isValid;
  }

  validate() {
    this.form.submitted = true;
    console.log(this.user);
    if (
      this.user.society.country === 'IT' &&
      (!this.user.society.vatNumber ||
        !this.user.society.vatNumber.match(/^[0-9]{11}$/))
    ) {
      this.modalService.error('Formato della partita iva non valido o assente');
      return false;
    }

    if (this.form.controls.certifiedEmail.invalid) {
      this.modalService.error(
        this.translateService.instant('general.form.error.invalidmail')
      );
      return false;
    }

    if (this.form.controls.administrativeEmail.invalid) {
      this.modalService.error(
        this.translateService.instant('general.form.error.invalidmail')
      );
      return false;
    }

    if (!this.isValid()) {
      this.modalService.error(
        this.translateService.instant('general.form.error')
      );
      return false;
    }

    return true;
  }

  getUser() {
    return this.user;
  }

  selectCountry() {
    if (!this.user.society.country) {
      return;
    }
    this.countryService
      .getDistrictsByCountry(this.user.society.country)
      .subscribe((r: any[]) => {
        this.districts = r;
      });
  }

  selectDistrict() {
    if (!this.user.society.district || !this.user.society.country) {
      return;
    }
    this.countryService
      .getProvincesByDistrict(
        this.user.society.district,
        this.user.society.country
      )
      .subscribe((r: any[]) => {
        this.provinces = r;
      });
  }
}
