import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
  ChangeDetectorRef,
  AfterViewChecked
} from '@angular/core';
import { EnumService } from '../../../core/enum.service';
import { ModalService } from '../../../core/modal.service';

@Component({
  selector: 'app-step1',
  templateUrl: './step1.component.html'
})
export class Step1Component implements OnInit, OnChanges, AfterViewChecked {
  @Input() userType: string;
  @Input() product: any = {};
  @Input() productDetailMode: boolean;
  @Input() readonly = false;

  @Input() varieties: any[];
  @Input() denominations: any[];
  @Input() microzones: any[];
  @Input() specifications: any[];

  @Input() countries = [];
  @Input() districts = [];

  @Output() productCategoryChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() selectCountry = new EventEmitter<any>();
  @Output() selectDistrict = new EventEmitter<any>();
  @Output() selectDenomination = new EventEmitter<any>();

  selectedCountry = false;

  @ViewChild('form') form;
  productCategories: any[] = [];
  wineTypes: any[] = [];
  sparklingTypes: any[] = [];
  wineDenominations: any[] = [];
  spiritCategories: any[] = [];
  spiritTypes: any[] = [];
  craftTypes: any[] = [];
  foodCategories: any[] = [];
  foodDenominations: any[] = [];
  beerDenominations: any[] = [];
  column = 'uk-width-1-1';

  years = ['Senza Annata', 'Blend', ...this.getYears()];

  constructor(
    private enumService: EnumService,
    private modalService: ModalService,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.productCategories = this.enumService.getProductCategories();
    this.wineTypes = this.enumService.getWineTypes();
    this.sparklingTypes = this.enumService.getSparklingTypes();
    this.wineDenominations = this.enumService.getWineDenominations();
    this.spiritCategories = this.enumService.getSpiritCategories();
    this.spiritTypes = this.enumService.getSpiritTypes();
    this.craftTypes = this.enumService.getCraftTypes();
    this.foodCategories = this.enumService.getFoodCategories();
    this.foodDenominations = this.enumService.getBeerDenominations();
    this.beerDenominations = this.enumService.getBeerDenominations();
  }

  ngOnChanges() {
    if (this.productDetailMode) {
      this.column = 'uk-width-small-1-2';
    }

    if (this.product.country && !this.selectedCountry) {
      this.onSelectCountry();
      this.selectedCountry = true;
    }
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  switchProductCategory() {
    this.productCategoryChange.emit(this.product.category);
  }

  changeUser($event: string) {
    this.product.user = $event;
  }

  isValid() {
    return this.form.valid;
  }

  validate() {
    this.form.submitted = true;
    if (!this.isValid() || !this.product.user) {
      this.modalService.showFormError();
      return false;
    }
    return true;
  }

  getData() {
    return this.product;
  }

  onSelectCountry() {
    if (!this.product.country) {
      return;
    }

    this.selectCountry.emit();
  }

  onSelectDistrict() {
    if (!this.product.region) {
      return;
    }

    this.selectDistrict.emit();
  }

  onSelectDenomination() {
    if (!this.product.wineTechnical.denomination) {
      return;
    }

    this.selectDenomination.emit();
  }

  setMicrozone() {
    this.product.wineTechnical.microzone = null;
    this.product.wineTechnical.manualMicrozone = null;
  }

  setDenomination() {
    this.product.wineTechnical.areaDenomination = null;
    this.product.wineTechnical.manualAreaDenomination = null;
  }

  getYears() {
    const years = [];
    for (let i = new Date().getFullYear(); i >= 1900; i--) {
      years.push(i.toString());
    }
    return years;
  }

  removeYear(index: number) {
    this.product.wineTechnical.years.splice(index, 1);
  }

  addYear() {
    this.product.wineTechnical.years.push({ year: '' });
  }
}
