import {Component, OnInit} from '@angular/core';
import {UserService} from "../../core/user.service";
import {AuthenticationService} from "../../core/authentication.service";
import {Router} from "@angular/router";

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

    userInfo: any = <any>{};

    constructor(private userService: UserService,
                private authenticationService: AuthenticationService,
                private router: Router) {
    }

    ngOnInit() {
        this.userService.me().subscribe((r: any) => {
            this.userInfo = r;
        })
    }

    logout() {
        this.authenticationService.logout();
        this.router.navigate(['/login']);
    }

}
