import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {ModalService} from "../../../core/modal.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
    selector: 'app-registration-step-referent',
    templateUrl: './registration-step-referent.component.html',
    styleUrls: ['./registration-step-referent.component.scss']
})
export class RegistrationStepReferentComponent implements OnInit {

    @Input() user: any = {};
    @ViewChild('form') form;

    constructor(private modalService: ModalService,
                private translateService: TranslateService) {
    }

    ngOnInit() {
    }

    ngOnChanges() {
        if (this.user) {
            if (!this.user.referent) {
                this.user.referent = <any>{}
            }
        }
    }

    isValid() {
        return this.form.valid;
    }

    validate() {
        this.form.submitted = true;
        if (!this.isValid()) {
            if (this.form.controls.referentEmail.invalid) {
                this.modalService.error(this.translateService.instant('general.form.error.invalidmail'));
            } else {
                this.modalService.error(this.translateService.instant("general.form.error"));
            }
            return false;
        }
        return true
    }

    getUser() {
        return this.user;
    }

    checkPhoneNumber(event) {
        const charCode = (event.which) ? event.which : event.keyCode;
        if ((charCode >= 43 && charCode <= 57)) {
          return true;
        }
        return false;
      }

}
