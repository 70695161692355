import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { StoreService } from '../../core/store.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-twa-survey-modal',
  templateUrl: './twa-survey-modal.component.html',
  styleUrls: ['./twa-survey-modal.component.scss'],
})
export class TwaSurveyModalComponent implements OnInit {
  isModal = false;
  twa: boolean = null;
  isWine: boolean;
  isAdmin: boolean;
  onClose = new Subject();

  constructor(private modal: BsModalRef, private storeService: StoreService) {}

  ngOnInit() {
    this.isWine = this.storeService.get('category') === 'wine';
    this.isAdmin = this.storeService.get('userType') === 'admin';
  }

  close() {
    this.onClose.next(this.twa);
    this.modal.hide();
  }
}
