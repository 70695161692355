import { Component, EventEmitter, Input, Output } from '@angular/core';
import { last } from 'lodash';
import { ModalService } from '../../../core/modal.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-product-markets',
  templateUrl: './product-markets.component.html',
  styleUrls: ['./product-markets.component.scss']
})
export class ProductMarketsComponent {
  @Input() markets: any[] = [];
  @Input() inline = false;
  @Input() readonly = false;
  @Input() countries: any[];
  @Output() marketsUpdated: EventEmitter<any> = new EventEmitter<any>();

  constructor(private modalService: ModalService, private translateService: TranslateService) { }

  addCountry() {
    console.log(this.markets);
    const lastCountry = last(this.markets);
    if (this.markets && this.markets.length > 0 && !lastCountry) {
      return;
    }
    if (!this.markets) {
      this.markets = [];
    }
    this.markets = [...this.markets, ''];
  }

  removeCountry(index) {
    this.markets.splice(index, 1);
  }

  changeCountry(index, newValue) {
    if (!newValue) {
      return;
    }
    if (this.markets.indexOf(newValue) > -1) {
      this.modalService.error(this.translateService.instant('product.country.error'));
      this.removeCountry(index);
      return;
    }
    this.markets[index] = newValue;

    this.marketsUpdated.emit(this.markets);
  }
}
