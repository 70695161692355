import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { PaymentService } from '../../../core/payment.service';

@Component({
  selector: 'app-cancel',
  templateUrl: './cancel.component.html',
  styleUrls: ['./cancel.component.scss']
})
export class CancelComponent implements OnInit {
  respApi: string;
  paymentId: any;
  callbackUrl: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private paymentService: PaymentService
  ) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.paymentId = params.localPayment;

      this.paymentService.get(this.paymentId).subscribe((payment: any) => {
        const body = { event: payment.eventRegistration.event, user: payment.user, sessionId: payment.stripeSessionId }
        this.paymentService.cancel(body).subscribe((res: any) => {
          this.respApi = 'Transazione annullata';
          this.callbackUrl = `/dashboard/event-registration/${payment.eventRegistration._id}/tickets`;
        });
      });
    });
  }

  undo() {
    this.router.navigate([this.callbackUrl]);
  }
}
