import {Injectable} from '@angular/core';
import {CookieService} from "ngx-cookie-service";

@Injectable({
    providedIn: 'root'
})
export class StoreService {

    constructor(private cookieService: CookieService) {
    }

    set(key, value) {
        this.cookieService.set(key, JSON.stringify(value), null, "/");
    }

    setDirect(key, value) {
        this.cookieService.set(key, value, null, "/");
    }

    get(key) {
        return JSON.parse(this.cookieService.get(key) || null);
    }

    getDirect(key) {
        return this.cookieService.get(key)
    }

    delete(key) {
        return this.cookieService.delete(key);
    }
}
