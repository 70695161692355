<div class="Pagination">
    {{ "pagination.page" | translate }}
    <button class="Pagination__button" (click)="prev()">
        <span class="ti-arrow-circle-left"></span>
    </button>

    <input type="number"
           class="Pagination__input"
           [(ngModel)]="paginationModel.page"
           (keyup.enter)="sendRefetch()">

    <button class="Pagination__button" (click)="next()">
        <span class="ti-arrow-circle-right"></span>
    </button>

    {{"pagination.of" | translate}} <strong>{{ paginationModel.pages }}</strong> <span [hidden]="pagesOnly"> | {{"pagination.view" | translate}} <select
        class="Pagination__select"
        [(ngModel)]="paginationModel.limit"
        (change)="changeLimit()">
      <option *ngFor="let limit of pageLimits" [value]="limit">{{ limit }}</option>
  </select>
  {{ "pagination.results.per.page" | translate }} | {{ "pagination.found" | translate }} <strong>{{ paginationModel.total }}</strong> {{ "pagination.total" | translate }}
  </span>
</div>
