<div class="Table-container">
    <table class="table table-hover">
        <tr>
            <th>
                {{ "product.year" | translate }}
            </th>
            <th>
                {{ "dashboard.products.name" | translate }}
            </th>
            <th>
                {{ 'product.area.denomination' | translate }}
            </th>
            <th>
                {{ 'product.specification' | translate }}
            </th>
            <th>
                {{'product.sparkling.type' | translate}}
            </th>
            <th>
                {{ 'product.denomination' | translate }}
            </th>
            <th *ngIf="!miniVerticale">
                <div *ngIf="userType == 'admin'">{{ "dashboard.products.score.final" | translate }}</div>
                <div *ngIf="userType == 'supplier'">{{ 'dashboard.products.merano.festival' | translate }}</div>
            </th>
            <th *ngIf="miniVerticale">
                {{ 'product.years' | translate }}
            </th>
        </tr>
        <tr class="highlightedRow">
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
        </tr>
        <tr *ngFor="let product of selectedProducts; let i = index">
            <td>{{ product?.wineTechnical?.years ? getYearsString(product.wineTechnical.years) : '' }}</td>
            <td>{{ product.name }}</td>
            <td>{{ product.wineTechnical ? (product.wineTechnical.areaDenomination ? product.wineTechnical.areaDenomination : product.wineTechnical.manualAreaDenomination) : '' }}</td>
            <td>{{ product.wineTechnical ? ((product.wineTechnical.otherSpecification ? product.wineTechnical.otherSpecification : '') + ' ' + (product.wineTechnical.specification ? product.wineTechnical.specification : '')) : '' }}</td>
            <td><span *ngIf="product.wineTechnical?.sparklingType">{{ 'category.sparkling.' + product.wineTechnical.sparklingType | translate }}</span></td>
            <td>{{ product.wineTechnical ? product.wineTechnical.denomination : '' }}</td>
            <td *ngIf="!miniVerticale">
                <div *ngIf="userType == 'admin'">{{ product.board?.finalEvaluation || "-" }}</div>
                <app-award-icon *ngIf="userType == 'supplier'"
                                [year]="product.creationDate | date:'yyyy'"
                                [showAward]="'true'"
                                [evaluation]="product.board?.finalEvaluation || product.board?.evaluation">
                </app-award-icon>
            </td>
            <td *ngIf="miniVerticale">
                {{ annate }}
            </td>
        </tr>
    </table>
</div>
