import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-registration-step-gdpr',
  templateUrl: './registration-step-gdpr.component.html',
  styleUrls: ['./registration-step-gdpr.component.scss']
})
export class RegistrationStepGdprComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
