import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RegistrationStepCanteenComponent} from "./registration-step-canteen/registration-step-canteen.component";
import {RegistrationStepGdprComponent} from "./registration-step-gdpr/registration-step-gdpr.component";
import {RegistrationStepLogoComponent} from "./registration-step-logo/registration-step-logo.component";
import {RegistrationStepReferentComponent} from "./registration-step-referent/registration-step-referent.component";
import {RegistrationStepAccountComponent} from "./registration-step-account/registration-step-account.component";
import {RegistrationStepSocietyComponent} from "./registration-step-society/registration-step-society.component";
import {RegisterComponent} from "./register.component";
import {SharedModule} from "../../shared/shared.module";

@NgModule({
    declarations: [
        RegisterComponent,
        RegistrationStepCanteenComponent,
        RegistrationStepGdprComponent,
        RegistrationStepLogoComponent,
        RegistrationStepReferentComponent,
        RegistrationStepAccountComponent,
        RegistrationStepSocietyComponent
    ],
    imports: [
        CommonModule,
        SharedModule
    ],
    exports: [
        RegisterComponent,
        RegistrationStepCanteenComponent,
        RegistrationStepGdprComponent,
        RegistrationStepLogoComponent,
        RegistrationStepReferentComponent,
        RegistrationStepAccountComponent,
        RegistrationStepSocietyComponent
    ]
})
export class RegisterModule {
}
