import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
  SimpleChanges,
  OnChanges
} from '@angular/core';
import { StoreService } from '../../core/store.service';
import { ModalService } from '../../core/modal.service';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { ProductService } from '../../core/product.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { CountryService } from 'src/app/core/country.service';

declare var swal: any;

@Component({
  selector: 'app-add-product-modal',
  templateUrl: './add-product-modal.component.html',
  styleUrls: ['./add-product-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AddProductModalComponent implements OnInit, OnDestroy, OnChanges {
  userType: string;
  currentStep = 0;
  steps = [0];
  districts = [];

  microzonesToShow = [];
  denominationsToShow = [];
  registration = false;
  event: string;

  @Input() isModal = false;
  @Input() showFullForm = false;
  @Input() productDetailMode = false;
  @Input() readonly = false;
  @Input() product: any = {
    wineTechnical: {},
    beerTechnical: {},
    spiritTechnical: {},
    foodTechnical: {}
  };

  @Input() countries: any[];
  @Input() microzones: any[];
  @Input() denominations: any[];
  @Input() varieties: any[];
  @Input() specifications: any[];

  @Output() productSaved: EventEmitter<any> = new EventEmitter();

  @ViewChild('step1') step1;
  @ViewChild('step2') step2;
  @ViewChild('step3') step3;
  @ViewChild('step4') step4;
  @ViewChild('step5') step5;
  @ViewChild('step6') step6;
  @ViewChild('step7') step7;

  private sub: Subscription = new Subscription();

  constructor(
    private storeService: StoreService,
    private modalService: ModalService,
    private productService: ProductService,
    private router: Router,
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private countryService: CountryService
  ) {}

  ngOnInit() {
    this.userType = this.storeService.get('userType');

    this.sub.add(
      this.route.queryParams.subscribe(params => {
        this.registration = !!params.registration;
        this.event = params.event;
      })
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    const region = this.product.region;
    const microzone = this.product.wineTechnical?.microzone;
    const denomination = this.product.wineTechnical?.denomination;
    const areaDenomination = this.product.wineTechnical?.areaDenomination;

    if (this.microzones && region && microzone) {
      this.onSelectDistrict();
      const filter = this.microzonesToShow.filter(d => d.name === microzone);

      if (filter.length === 0) {
        this.product.wineTechnical.manualMicrozone = microzone;
        this.product.wineTechnical.microzone = null;
      }
    }

    if (this.denominations && region && denomination && areaDenomination) {
      this.onSelectDenomination();

      const filter = this.denominationsToShow.filter(d => d.name === areaDenomination);

      if (filter.length === 0) {
        this.product.wineTechnical.manualAreaDenomination = areaDenomination;
        this.product.wineTechnical.areaDenomination = null;
      }
    }

    if (this.product.wineTechnical?.variety && this.varieties) {
      for (const v of this.product.wineTechnical.variety) {
        if (v.name) {
          let found = false;
          for (const variety of this.varieties) {
            found = variety.name === v.name;
            if (found) {
              break;
            }
          }
          if (!found) {
            v.manualName = v.name;
            v.name = null;
          } else {
            v.manualName = null;
          }
        }
      }
    }
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  changeProductCategory(newCategory: string) {
    const cat = newCategory;

    if (cat === 'wine') {
      this.steps = [0, 1, 2, 3, 4, 5, 6];
      this.product.wineTechnical.variety = this.product.wineTechnical.variety
        ? this.product.wineTechnical.variety
        : [{}];
    } else if (cat === 'beer') {
      this.steps = [0, 1, 2];
    } else if (cat === 'spirit') {
      this.steps = [0, 1, 2];
    } else if (cat === 'food') {
      this.steps = [0, 1, 2];
    } else {
      this.steps = [0];
    }

    this.product.category = newCategory;
  }

  previousStep() {
    if (this.registration) {
      this.saveProductCallback();
    } else if (this.currentStep > 0) {
      if (this.product.category === 'wine' && this.currentStep === 2) {
        this.currentStep = 0;
      } else {
        this.currentStep--;
      }
    } else {
      if (this.isModal === true) {
        this.closeModal();
      }
    }
  }

  nextStep() {
    const cat = this.product.category;

    // TODO: trasformare tutti i form in sottoform con reactive forms
    if (this.showFullForm) {
      // Valida tutti i form insieme
      if (cat === 'wine') {
        if (
          !this.step1.validate() ||
          !this.step2.validate() ||
          !this.step3.validate() ||
          !this.step4.validate() ||
          !this.step5.validate() ||
          !this.step6.validate() ||
          (this.step7 && !this.step7.validate())
        ) {
          return;
        }
        this.product = this.step6.getData();
      }
      if (cat === 'beer') {
        if (!this.step1.validate() || !this.step2.validate() || !this.step3.validate()) {
          return;
        }
        this.product = this.step3.getData();
      }
      if (cat === 'food') {
        if (!this.step1.validate() || !this.step2.validate() || !this.step3.validate()) {
          return;
        }
        this.product = this.step3.getData();
      }
      if (cat === 'spirit') {
        if (!this.step1.validate() || !this.step2.validate() || !this.step3.validate()) {
          return;
        }
        this.product = this.step3.getData();
      }
    } else {
      if (this.currentStep === 0) {
        if (!this.step1.validate()) {
          return;
        } else {
          this.product = this.step1.getData();
        }
      }
      if (this.currentStep === 1) {
        if (!this.step2.validate()) {
          return;
        } else {
          this.product = this.step2.getData();
        }
      }

      if (this.currentStep === 2) {
        if (!this.step3.validate()) {
          return;
        } else {
          this.product = this.step3.getData();
        }
      }

      if (this.currentStep === 3) {
        if (!this.step4.validate()) {
          return;
        } else {
          this.product = this.step4.getData();
        }
      }

      if (this.currentStep === 4) {
        if (!this.step5.validate()) {
          return;
        } else {
          this.product = this.step5.getData();
        }
      }

      if (this.currentStep === 5) {
        if (!this.step6.validate()) {
          return;
        } else {
          this.product = this.step6.getData();
        }
      }

      if (this.currentStep === 6) {
        if (!this.step7.validate()) {
          return;
        } else {
          this.product = this.step7.getData();
        }
      }
    }

    if (this.showFullForm || this.currentStep === this.steps.length - 1) {
      this.saveProduct();
    } else {
      if (this.product.category === 'wine' && this.currentStep === 0) {
        this.currentStep = 2;
      } else {
        ++this.currentStep;
      }
    }
  }

  closeModal() {
    // this.bsModalRef.hide()
  }

  saveProduct() {
    const cat = this.product.category;
    let productToSave;

    if (cat === 'wine') {
      productToSave = this.saveWineProduct();
    } else if (cat === 'beer') {
      productToSave = this.saveBeerProduct();
    } else if (cat === 'spirit') {
      productToSave = this.saveSpiritProduct();
    } else if (cat === 'food') {
      productToSave = this.saveFoodProduct();
    }

    delete productToSave.commissionAssociationDate;
    delete productToSave.commission;
    console.log(this.product);
    if (productToSave._id) {
      if (productToSave.hasEvents && this.product.boardChanged) {
        swal
          .fire({
            title: this.translateService.instant('product.edit_score_confirmation'),
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: this.translateService.instant('general.yes'),
            cancelButtonText: this.translateService.instant('general.no')
          })
          .then((result: any) => {
            if (result.value) {
              this.pushChanges(productToSave);
            }
          });
      } else {
        this.pushChanges(productToSave);
      }
    } else {
      this.productService.add(productToSave).subscribe(
        res => {
          this.modalService
            .success(this.translateService.instant('dashboard.add.success'), 20000)
            .then(r => {
              this.saveProductCallback();
            });
        },
        err => {
          this.modalService.error(this.translateService.instant('general.error'), 20000);
        }
      );
    }
  }

  pushChanges(productToSave) {
    this.productService.modify(productToSave._id, productToSave).subscribe(
      (res: any) => {
        this.productSaved.emit(res);
      },
      err => {
        if (err.data && err.data.err === 'codeImportExists') {
          this.modalService.error(
            this.translateService.instant('product.modify.error.code'),
            20000
          );
        } else {
          this.modalService.error(this.translateService.instant('product.modify.error'), 20000);
        }
      }
    );
  }

  saveWineProduct() {
    let product = _.cloneDeep(this.product);
    product = _.omit(product, ['foodTechnical', 'beerTechnical', 'spiritTechnical']);

    return product;
  }

  saveSpiritProduct() {
    if (this.product.spiritTechnical.spiritCategory !== 'distillate') {
      delete this.product.spiritTechnical.spiritType;
    }

    delete this.product.beerTechnical;
    delete this.product.foodTechnical;
    delete this.product.wineTechnical;
    if (this.product.spiritTechnical.rawMaterials[0].name === '') {
      delete this.product.spiritTechnical.rawMaterials;
    }

    let product = _.cloneDeep(this.product);
    if (this.product.spiritTechnical.spiritCategory === 'liquor') {
      // product.spiritTechnical.liqueur = true;
      product.spiritTechnical.spiritType = null;
      product.spiritTechnical.otherType = null;
    } else if (this.product.spiritTechnical.spiritCategory === 'distillate') {
      // product.spiritTechnical.liqueur = false;
      product.spiritTechnical.otherType = null;
    } else if (this.product.spiritTechnical.spiritCategory === 'other') {
      // product.spiritTechnical.liqueur = false;
      product.spiritTechnical.spiritType = null;
    }
    product = _.omit(product, ['foodTechnical', 'beerTechnical', 'wineTechnical']);

    return product;
  }

  saveFoodProduct() {
    delete this.product.beerTechnical;
    delete this.product.spiritTechnical;
    delete this.product.beerTechnical;
    if (
      this.product.foodTechnical.rawMaterials &&
      this.product.foodTechnical.rawMaterials.length > 0 &&
      this.product.foodTechnical.rawMaterials[0].name === ''
    ) {
      delete this.product.foodTechnical.rawMaterials;
    }
    let product = _.cloneDeep(this.product);
    product = _.omit(product, ['beerTechnical', 'wineTechnical', 'spiritTechnical']);

    return product;
  }

  saveBeerProduct() {
    if (this.product.foodTechnical) {
      delete this.product.foodTechnical.rawMaterials;
      delete this.product.foodTechnical.rawMaterials;
    }

    if (this.product.spiritTechnical) {
      delete this.product.spiritTechnical.rawMaterials;
    }

    if (
      this.product.beerTechnical.rawMaterials &&
      this.product.beerTechnical.rawMaterials.length &&
      this.product.beerTechnical.rawMaterials[0].name === ''
    ) {
      delete this.product.beerTechnical.rawMaterials;
    }
    let product = _.cloneDeep(this.product);
    product = _.omit(product, ['foodTechnical', 'wineTechnical', 'spiritTechnical']);

    return product;
  }

  saveProductCallback() {
    if (this.isModal === true) {
      this.closeModal();
    }

    if (this.registration) {
      this.router.navigate(['dashboard', 'event-registration', 'new', 'registration'], {
        queryParams: { event: this.event, step: 5 }
      });
    } else {
      this.router.navigate(['/dashboard/product']);
    }
  }

  onSelectCountry() {
    this.sub.add(
      this.countryService.getDistrictsByCountry(this.product.country).subscribe((r: any[]) => {
        this.districts = r;
      })
    );

    if (this.product.country !== 'IT') {
      this.product.region = null;
      this.product.wineTechnical.microzone = null;
      this.product.wineTechnical.denomination = null;
      this.product.wineTechnical.areaDenomination = null;
      this.product.wineTechnical.wineType = null;
      this.product.wineTechnical.specification = null;
    }
  }

  onSelectDistrict() {
    const region = this.product.region;
    this.microzonesToShow = this.microzones.filter(zone => zone.regione === region);

    if (this.denominations) {
      if (this.product.wineTechnical.denomination) {
        this.denominationsToShow = this.denominations.filter(ad => {
          const denomination = this.product.wineTechnical.denomination;
          const isRightDenomination = ad.denomination === denomination;
          const isDOPDenomination =
            denomination === 'DOP' && (ad.denomination === 'DOC' || ad.denomination === 'DOCG');
          const isIGPDenomination = denomination === 'IGP' && ad.denomination === 'IGT';
          return (
            ad.regions.includes(region) &&
            (isRightDenomination || isDOPDenomination || isIGPDenomination)
          );
        });
      } else {
        this.denominationsToShow = this.denominations.filter(denomination =>
          denomination.regions.includes(region)
        );
      }
    }
  }

  onSelectDenomination() {
    const region = this.product.region;
    const denomination = this.product.wineTechnical.denomination;

    // DOP = DOC + DOCG
    // IGP = IGT

    this.denominationsToShow = this.denominations.filter(ad => {
      const isRightDenomination = ad.denomination === denomination;
      const isDOPDenomination =
        denomination === 'DOP' && (ad.denomination === 'DOC' || ad.denomination === 'DOCG');
      const isIGPDenomination = denomination === 'IGP' && ad.denomination === 'IGT';
      return (
        ad.regions.includes(region) &&
        (isRightDenomination || isDOPDenomination || isIGPDenomination)
      );
    });
  }
}
