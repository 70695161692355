import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EventGroupService {
  constructor(private http: HttpClient) {}

  getAll(page, limit) {
    page = page || 1;
    limit = limit || 10;
    return this.http.get(`${environment.server}/event-group?page=${page}&limit=${limit}`);
  }

  search(page, limit, searchObject) {
    page = page || 1;
    limit = limit || 10;
    return this.http.post(
      `${environment.server}/event-group/search?page=${page}&limit=${limit}`,
      searchObject
    );
  }

  add(data: any) {
    return this.http.post(`${environment.server}/event-group`, data);
  }

  get(id: string) {
    return this.http.get(`${environment.server}/event-group/${id}`);
  }

  modify(id: string, data: any) {
    return this.http.put(`${environment.server}/event-group/${id}`, data);
  }

  remove(id: string) {
    return this.http.delete(`${environment.server}/event-group/${id}`);
  }

  getAutocomplete(token: string) {
    return this.http.get(`${environment.server}/event-group/autocomplete/${token}`);
  }
}
