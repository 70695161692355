import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NumbersOnlyDirective} from "./numbers-only.directive";
import {InputDecimalDirective} from "./input-decimal.directive";
import {InputIntegerDirective} from "./input-integer.directive";

@NgModule({
    declarations: [NumbersOnlyDirective, InputDecimalDirective, InputIntegerDirective],
    imports: [
        CommonModule
    ],
    exports: [NumbersOnlyDirective, InputDecimalDirective, InputIntegerDirective]
})
export class DirectivesModule {
}
