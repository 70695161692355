<div class="langNavbar">
  <div class="langSelector">
    <ul class="uk-list">
      <li *ngFor="let lang of languages"
          [ngClass]="{active: currentLang == lang.code}">
        <a (click)="changeLanguage(lang.code)">{{ lang.name }}</a>
      </li>
    </ul>
  </div>
</div>
