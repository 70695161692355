import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-registration-payment-info-modal',
  templateUrl: './registration-payment-info-modal.component.html',
  styleUrls: ['./registration-payment-info-modal.component.scss']
})
export class RegistrationPaymentInfoModalComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
