import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class EventReferentService {

    constructor(private http: HttpClient) {
    }

    autocomplete(token: string, userId?: string) {
        let query = "";
        if (userId) {
            query = "?userId=" + userId
        }
        return this.http.get(`${environment.server}/event-referent/autocomplete/` + token + query)
    }

    add(data: any) {
        return this.http.post(`${environment.server}/event-referent`, data)
    }

    search(data: any) {
        return this.http.post(`${environment.server}/event-referent/search`, data)
    }

    modify(id: string, data: any) {
        return this.http.put(`${environment.server}/event-referent/${id}`, data)
    }
}
