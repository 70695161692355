import {
  Component,
  Input,
  OnInit,
  SimpleChanges,
  ViewChild,
  Output,
  EventEmitter,
  OnChanges
} from '@angular/core';
import { has } from 'lodash';
import { EnumService } from '../../../core/enum.service';
import { ModalService } from '../../../core/modal.service';

@Component({
  selector: 'app-step2',
  templateUrl: './step2.component.html'
})
export class Step2Component implements OnInit, OnChanges {
  @Input() product: any = {};
  @Input() productDetailMode: boolean;
  @Input() readonly = false;
  @Input() countries: any[] = [];
  @Input() districts: any[] = [];
  @ViewChild('form') form;
  provenance: any[] = [];
  fermentation: any[] = [];
  column = 'uk-width-1-1';
  booleanColumn = 'uk-width-1-2';

  @Output() selectCountry = new EventEmitter<any>();

  constructor(private enumService: EnumService, private modalService: ModalService) {}

  ngOnInit() {
    this.provenance = this.enumService.getProvenance();
    this.fermentation = this.enumService.getFermentationTypes();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.product.category === 'food' && !has(this.product, 'foodTechnical.rawMaterials')) {
      this.product.foodTechnical.rawMaterials = [{}];
    }
    if (this.product.category === 'beer' && !has(this.product, 'beerTechnical.rawMaterials')) {
      this.product.beerTechnical.rawMaterials = [{}];
    }
    if (this.product.category === 'spirit' && !has(this.product, 'spiritTechnical.rawMaterials')) {
      this.product.spiritTechnical.rawMaterials = [{}];
    }

    if (this.productDetailMode) {
      this.column = 'uk-width-small-1-2';
      this.booleanColumn = 'uk-width-small-1-1';
    }
  }

  isBoolean(value) {
    return value === true || value === false;
  }

  removeRawMaterial(index, type) {
    if (type === 'spirit') {
      this.product.spiritTechnical.rawMaterials.splice(index, 1);
    } else if (type === 'food') {
      this.product.foodTechnical.rawMaterials.splice(index, 1);
    } else if (type === 'beer') {
      this.product.beerTechnical.rawMaterials.splice(index, 1);
    }
  }

  addRawMaterial(type) {
    const newRawMaterial = {
      name: '',
      perc: 0,
      provenance: ''
    };

    if (type === 'food') {
      if (!has(this.product, 'foodTechnical.rawMaterials')) {
        this.product.foodTechnical.rawMaterials = [];
      }
      this.product.foodTechnical.rawMaterials.push(newRawMaterial);
    } else if (type === 'beer') {
      if (!has(this.product, 'beerTechnical.rawMaterials')) {
        this.product.beerTechnical.rawMaterials = [];
      }
      this.product.beerTechnical.rawMaterials.push(newRawMaterial);
    } else if (type === 'spirit') {
      if (!has(this.product, 'spiritTechnical.rawMaterials')) {
        this.product.spiritTechnical.rawMaterials = [];
      }
      this.product.spiritTechnical.rawMaterials.push(newRawMaterial);
    }
  }

  onSelectCountry() {
    if (!this.product.country) {
      return;
    }

    this.selectCountry.emit(this.product.country);
  }

  isValid() {
    return this.form.valid;
  }

  validate() {
    this.form.submitted = true;
    if (!this.isValid()) {
      this.modalService.showFormError();
      return false;
    }
    return true;
  }

  getData() {
    return this.product;
  }
}
