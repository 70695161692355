import { Component, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ModalService } from '../../core/modal.service';
import { UserService } from '../../core/user.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html'
})
export class ChangePasswordComponent implements OnInit {
  password = '';
  password2 = '';
  @ViewChild('passwordForm') passwordForm;
  key: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private modalService: ModalService,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.key = this.route.snapshot.queryParamMap.get('q');

    if (!this.key) {
      this.router.navigate(['/login']);
    }
  }

  changePassword() {
    this.passwordForm.submitted = this.passwordForm.invalid;
    if (this.passwordForm.invalid) {
      this.modalService.error(this.translateService.instant('general.form.error'));
      return;
    }

    if (this.password !== this.password2) {
      this.modalService.error(this.translateService.instant('changepassword.mismatch'));
      return;
    }

    this.userService.resetPassword(this.password, this.key).subscribe(
      _ => {
        this.modalService
          .success(this.translateService.instant('changepassword.success'), 30000)
          .then(() => {
            this.router.navigate(['/login']);
          });
      },
      _ => this.modalService.error(this.translateService.instant('general.error'))
    );
  }
}
