import { Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { EnumService } from '../../../core/enum.service';
import { ModalService } from '../../../core/modal.service';

@Component({
  selector: 'app-step4',
  templateUrl: './step4.component.html'
})
export class Step4Component implements OnInit, OnChanges {
  @Input() product: any = {};
  @Input() productDetailMode: boolean;
  @Input() readonly = false;
  @Input() countries: any[];
  @ViewChild('form') form;
  longevity: any[] = [];
  markets: boolean;
  column = 'uk-width-1-1';

  constructor(private enumService: EnumService, private modalService: ModalService) { }

  ngOnInit() {
    this.longevity = this.enumService.getLongevityTypes();
  }

  ngOnChanges() {
    this.markets = this.product.category === 'wine' &&
      this.product.wineTechnical?.markets &&
      this.product.wineTechnical.markets.length > 0;


    if (this.productDetailMode) {
      this.column = 'uk-width-small-1-2';
    }
  }

  isValid() {
    return this.form.valid;
  }

  validate() {
    this.form.submitted = true;
    if (!this.isValid()) {
      this.modalService.showFormError();
      return false;
    }
    return true;
  }

  getData() {
    return this.product;
  }
}
