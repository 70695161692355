import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-table-sortable-header',
  templateUrl: './table-sortable-header.component.html',
  styleUrls: ['./table-sortable-header.component.scss']
})
export class TableSortableHeaderComponent implements OnInit {
  @Input() reverse: boolean;
  @Output() reverseChange: EventEmitter<boolean> = new EventEmitter();

  @Input() sortBy: string;
  @Output() sortByChange: EventEmitter<string> = new EventEmitter();

  @Input() columnName: string;
  @Input() title: string;

  @Output() refetch: EventEmitter<boolean> = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  headerClick() {
    if (this.sortBy != this.columnName) {
      this.reverse = false;
    } else {
      this.reverse = !this.reverse;
    }
    this.sortBy = this.columnName;

    this.reverseChange.emit(this.reverse);
    this.sortByChange.emit(this.sortBy);
    this.refetch.emit(true);
  }
}
