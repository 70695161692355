import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { CommonService } from 'core/common.service';
import { EnumService, Language } from 'core/enum.service';

@Component({
    selector: 'app-lang-navbar',
    templateUrl: './lang-navbar.component.html',
    styleUrls: ['./lang-navbar.component.scss']
})
export class LangNavbarComponent implements OnInit {

    currentLang: string;

    languages: Language[];

    constructor(private translateService: TranslateService,
        private cookieService: CookieService,
        private enumService: EnumService) {

    }

    ngOnInit() {
        this.languages = this.enumService.languages;

        if (this.cookieService.check('locale') &&
            this.languages.some(lang => lang.code === this.cookieService.get('locale'))) {
            this.currentLang = this.cookieService.get('locale');
        } else {
            this.currentLang = this.translateService.currentLang;
        }
        this.translateService.use(this.currentLang);
    }

    changeLanguage(lang: string) {
        this.translateService.use(lang);
        this.currentLang = lang;
        this.cookieService.set('locale', this.currentLang);
    }

}
