import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CountryService {
  constructor(private http: HttpClient) {}

  getCountries(): Observable<any> {
    return this.http.get(`${environment.server}/country`);
  }

  getCountryByName(name: string) {
    return this.http.get(`${environment.server}/country/${name}`);
  }

  getDistrictsByCountry(countryCode: string) {
    return this.http.get(`${environment.server}/district/${countryCode}`);
  }

  getProvincesByDistrict(districtCode: string, countryCode: string) {
    return this.http.get(`${environment.server}/province/${districtCode}/${countryCode}`);
  }

  getCitiesByProvince(provinceCode: string, districtCode: string, countryCode: string) {
    return this.http.get(
      `${environment.server}/city/${provinceCode}/${districtCode}/${countryCode}`
    );
  }
}
