import { Injectable } from '@angular/core';
import { StoreService } from './store.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  public previousUrl: string;

  constructor(
    private store: StoreService,
    private http: HttpClient,
    private router: Router,
    private userService: UserService
  ) { }

  login(username: string = '', password: string = '') {
    return this.http.post(`${environment.server}/login`, {
      username: username.toLowerCase(),
      password
    });
  }

  setToken(token: string) {
    this.store.set('token', token);
  }

  getToken() {
    return this.store.get('token');
  }

  logout() {
    this.store.delete('token');
    this.store.delete('user');
    this.store.delete('category');
    this.store.delete('userType');
  }

  setAllowedRouting(): Promise<any> {
    let userInfo: any = {};
    return new Promise((resolve, reject) => {
      if (location.href.match(/verify\?q/i)) {
        resolve();
      } else {
        this.userService.me().subscribe(
          (res: any) => {
            userInfo = res;
            this.userService.setUserInfo(
              userInfo._id,
              userInfo.userType,
              userInfo.username,
              userInfo.category,
              this.userService.getIncompleteInfoArray(userInfo).length > 0,
              userInfo.society
            );

            if (userInfo.userType === 'board') {
              this.router.navigate(['/dashboard/evaluation']);
              reject();
            } else if (userInfo.userType === 'admin') {
              resolve();
              // this.router.navigate(['/dashboard/summary']);
            } else if (userInfo.userType === 'supplier') {
              // Controlla che l'utente abbia accettato la privacy policy.
              if (!userInfo.privacy || !userInfo.privacyDate) {
                this.router.navigate(['/privacy-check']);
                reject();
                return;
              }

              if (this.userService.getIncompleteInfoArray(userInfo).length > 0) {
                this.router.navigate(['/dashboard/user/me']);
                reject();
              } else {
                resolve();
                // this.router.navigate(['/dashboard/summary']);
              }
            }
          },
          _ => {
            this.router.navigate(['/login']);
            reject();
          }
        );
      }
    });
  }
}
