<form #form="ngForm" [ngClass]="{ submitted: form.submitted }" novalidate>
  <div class="uk-grid">
    <div class="uk-width-1-1" *ngIf="product.category !== 'wine'">
      <div class="uk-grid">
        <div [ngClass]="column">
          <label class="m-label">{{ 'stato' | translate }} *</label>
        </div>
        <div [ngClass]="column">
          <div class="m-select" [ngClass]="{ 'ng-invalid': form.submitted && !product.country }">
            <select
              name="country"
              [(ngModel)]="product.country"
              (change)="onSelectCountry()"
              required
              [disabled]="readonly"
            >
              <option *ngFor="let country of countries" [value]="country.code">
                {{ country.name }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="uk-width-1-1" *ngIf="product.category !== 'wine'">
      <div class="uk-grid">
        <div [ngClass]="column">
          <label class="m-label">{{ 'regione' | translate }} <span *ngIf="districts.length > 0">*</span></label>
        </div>
        <div [ngClass]="column">
          <div
            class="m-select"
            *ngIf="districts.length > 0"
            [ngClass]="{ 'ng-invalid': form.submitted && !product.region }"
          >
            <select name="district" [(ngModel)]="product.region" required [disabled]="readonly">
              <option *ngFor="let district of districts" [value]="district.name">
                {{ district.name }}
              </option>
            </select>
          </div>
          <!--Per paesi non mappati sul database con le proprie regioni-->
          <input
            type="text"
            class="m-input"
            name="district"
            [readonly]="readonly"
            [(ngModel)]="product.region"
            *ngIf="districts.length == 0"
          />
        </div>
      </div>
    </div>
  </div>
  <!--alcolici-->
  <div *ngIf="product.category == 'spirit'">
    <div class="uk-grid">
      <div [ngClass]="column">
        <label class="m-label" tooltip="{{ 'general.ingredients' | translate }}">
          {{ 'Ingredienti' | translate }} (?)
        </label>
      </div>
      <div [ngClass]="column">
        <div
          class="uk-grid"
          *ngFor="let material of product.spiritTechnical?.rawMaterials; let $index = index"
        >
          <div
            [ngClass]="{
              'uk-width-small-5-10': !productDetailMode,
              'uk-width-1-1': productDetailMode
            }"
          >
            <label class="m-label">{{ 'product.material' | translate }}</label>
            <input
              type="text"
              class="m-input"
              [readonly]="readonly"
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="material.name"
            />
          </div>
          <div
            [ngClass]="{
              'uk-width-small-5-10': !productDetailMode,
              'uk-width-1-1': productDetailMode
            }"
          >
            <label class="m-label">{{ 'product.provenance' | translate }}</label>
            <div class="m-select">
              <!--[ngClass]="{'ng-invalid': form.submitted && !material.provenance}"-->
              <select
                [(ngModel)]="material.provenance"
                [ngModelOptions]="{ standalone: true }"
                [disabled]="readonly"
              >
                <option value=""></option>
                <option *ngFor="let cat of provenance" value="{{ cat.code }}">
                  {{ cat.name }}
                </option>
              </select>
            </div>
          </div>
          <div
            [ngClass]="{
              'uk-width-small-1-10': !productDetailMode,
              'uk-width-1-1 text-right': productDetailMode
            }"
            [hidden]="readonly"
          >
            <span
              class="remove-product-icon ti-trash ko"
              title="{{ 'general.remove' | translate }}"
              (click)="removeRawMaterial($index, 'spirit')"
              *ngIf="
                (material.name || $index !== 0) && product.spiritTechnical.rawMaterials.length > 1
              "
            ></span>
          </div>
        </div>
      </div>
    </div>
    <div class="uk-grid" [hidden]="readonly">
      <div class="uk-width-1-1 text-right">
        <div class="add-product-icon fa fa-plus-circle" (click)="addRawMaterial('spirit')"></div>
      </div>
    </div>
  </div>
  <!--cibo-->
  <div *ngIf="product.category == 'food'">
    <div class="uk-grid">
      <div [ngClass]="column">
        <label class="m-label" tooltip="{{ 'general.ingredients' | translate }}">
          {{ 'Ingredienti' | translate }} (?)
        </label>
      </div>
      <div [ngClass]="column">
        <div
          class="uk-grid"
          *ngFor="let material of product.foodTechnical?.rawMaterials; let $index = index"
        >
          <div
            [ngClass]="{
              'uk-width-small-5-10': !productDetailMode,
              'uk-width-1-1': productDetailMode
            }"
          >
            <label class="m-label">{{ 'product.material' | translate }}</label>
            <input
              type="text"
              class="m-input"
              [readonly]="readonly"
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="material.name"
            />
          </div>
          <div
            [ngClass]="{
              'uk-width-small-5-10': !productDetailMode,
              'uk-width-1-1': productDetailMode
            }"
          >
            <label class="m-label">{{ 'product.provenance' | translate }}</label>
            <div class="m-select">
              <!--[ngClass]="{'ng-invalid': form.submitted && !material.provenance}"-->
              <select
                [(ngModel)]="material.provenance"
                [ngModelOptions]="{ standalone: true }"
                [disabled]="readonly"
              >
                <option value=""></option>
                <option *ngFor="let cat of provenance" value="{{ cat.code }}">
                  {{ cat.name }}
                </option>
              </select>
            </div>
          </div>
          <div
            [ngClass]="{
              'uk-width-small-1-10': !productDetailMode,
              'uk-width-1-1 text-right': productDetailMode
            }"
            [hidden]="readonly"
          >
            <span
              class="remove-product-icon ti-trash ko"
              title="{{ 'general.remove' | translate }}"
              (click)="removeRawMaterial($index, 'food')"
              *ngIf="
                (material.name || $index !== 0) && product.foodTechnical.rawMaterials.length > 1
              "
            ></span>
          </div>
        </div>
      </div>
      <!--<div class="uk-width-small-5-10">
                <label class="m-label">{{"product.provenance" | translate}}</label>
            </div>-->
    </div>
    <div class="uk-grid" [hidden]="readonly">
      <div class="uk-width-1-1 text-right">
        <div class="add-product-icon fa fa-plus-circle" (click)="addRawMaterial('food')"></div>
      </div>
    </div>
  </div>
  <!--birra-->
  <div *ngIf="product.category == 'beer'" class="uk-grid">
    <div class="uk-width-1-1">
      <div class="uk-grid">
        <div [ngClass]="column">
          <label class="m-label">{{ 'product.alcohol.perc' | translate }} *</label>
        </div>
        <div [ngClass]="column">
          <input
            type="text"
            inputDecimal
            name="degree"
            [readonly]="readonly"
            (decimalChanged)="product.beerTechnical.degree = $event"
            class="m-input"
            [(ngModel)]="product.beerTechnical.degree"
            required
          />
        </div>
      </div>
    </div>

    <div class="uk-width-1-1">
      <div class="uk-grid">
        <div [ngClass]="column">
          <label class="m-label">{{ 'product.fermentation' | translate }} *</label>
        </div>
        <div [ngClass]="column">
          <div
            class="m-select"
            [ngClass]="{ 'ng-invalid': form.submitted && !product.beerTechnical.fermentation }"
          >
            <!--[ngClass]="{'ng-invalid': form.submitted && !product.beerTechnical.fermentation}"-->
            <select
              [(ngModel)]="product.beerTechnical.fermentation"
              name="fermentation"
              required
              [disabled]="readonly"
            >
              <option value=""></option>
              <option *ngFor="let cat of fermentation" value="{{ cat.code }}">
                {{ cat.name }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>

    <div
      class="uk-width-medium-1-2"
      style="margin-top: 10px; padding-top: 5px;"
      [ngStyle]="
        !isBoolean(product.beerTechnical.pasteurization) && form.submitted
          ? { color: 'red' }
          : { color: 'black' }
      "
    >
      <div class="uk-grid">
        <div class="uk-width-1-1">
          <label class="m-label">{{ 'product.pasteurization' | translate }} *</label>
        </div>
        <div class="uk-width-1-3">
          <label>
            <input
              type="radio"
              group="pasteurization"
              name="pasteurization"
              [disabled]="readonly"
              [value]="true"
              [(ngModel)]="product.beerTechnical.pasteurization"
              required
            />
            {{ 'general.yes' | translate }}
          </label>
        </div>
        <div class="uk-width-1-3">
          <label>
            <input
              type="radio"
              group="pasteurization"
              name="pasteurization"
              [disabled]="readonly"
              [value]="false"
              [(ngModel)]="product.beerTechnical.pasteurization"
            />
            {{ 'general.no' | translate }}
          </label>
        </div>
      </div>
    </div>
    <div
      class="uk-width-medium-1-2"
      style="margin-top: 10px; padding-top: 5px;"
      [ngStyle]="
        !isBoolean(product.beerTechnical.filtered) && form.submitted
          ? { color: 'red' }
          : { color: 'black' }
      "
    >
      <div class="uk-grid">
        <div class="uk-width-1-1">
          <label class="m-label">{{ 'product.filtered' | translate }} *</label>
        </div>
        <div class="uk-width-1-3">
          <label>
            <input
              type="radio"
              group="filtered"
              name="filtered"
              [disabled]="readonly"
              [value]="true"
              [(ngModel)]="product.beerTechnical.filtered"
              required
            />
            {{ 'general.yes' | translate }}
          </label>
        </div>
        <div class="uk-width-1-3">
          <label>
            <input
              type="radio"
              group="filtered"
              name="filtered"
              [disabled]="readonly"
              [value]="false"
              [(ngModel)]="product.beerTechnical.filtered"
            />
            {{ 'general.no' | translate }}
          </label>
        </div>
      </div>
    </div>

    <div class="uk-width-1-1">
      <div class="uk-grid">
        <div [ngClass]="column">
          <label class="m-label" tooltip="{{ 'general.ingredients' | translate }}">
            {{ 'Ingredienti' | translate }} (?)
          </label>
        </div>
        <div [ngClass]="column">
          <div
            class="uk-grid"
            *ngFor="let material of product.beerTechnical?.rawMaterials; let $index = index"
          >
            <div
              [ngClass]="{
                'uk-width-small-5-10': !productDetailMode,
                'uk-width-1-1': productDetailMode
              }"
            >
              <label class="m-label">{{ 'product.material' | translate }}</label>
              <input
                type="text"
                class="m-input"
                [readonly]="readonly"
                [ngModelOptions]="{ standalone: true }"
                [(ngModel)]="material.name"
              />
            </div>
            <div
              [ngClass]="{
                'uk-width-small-5-10': !productDetailMode,
                'uk-width-1-1': productDetailMode
              }"
            >
              <label class="m-label">{{ 'product.provenance' | translate }}</label>
              <div class="m-select">
                <!--[ngClass]="{'ng-invalid': form.submitted && !material.provenance}"-->
                <select
                  [(ngModel)]="material.provenance"
                  [ngModelOptions]="{ standalone: true }"
                  [disabled]="readonly"
                >
                  <option value=""></option>
                  <option *ngFor="let cat of provenance" value="{{ cat.code }}">
                    {{ cat.name }}
                  </option>
                </select>
              </div>
            </div>
            <div
              [ngClass]="{
                'uk-width-small-1-10': !productDetailMode,
                'uk-width-1-1 text-right': productDetailMode
              }"
              [hidden]="readonly"
            >
              <span
                class="remove-product-icon ti-trash ko"
                title="{{ 'general.remove' | translate }}"
                (click)="removeRawMaterial($index, 'beer')"
                *ngIf="
                  (material.name || $index !== 0) && product.beerTechnical.rawMaterials.length > 1
                "
              ></span>
            </div>
          </div>
        </div>
      </div>
      <div class="uk-grid" [hidden]="readonly">
        <div class="uk-width-1-1 text-right">
          <div class="add-product-icon fa fa-plus-circle" (click)="addRawMaterial('beer')"></div>
        </div>
      </div>
    </div>
  </div>
</form>
