<div class="products" [ngClass]="{ 'product-detail-mode': productDetailMode }">
  <h2 class="uk-text-right" *ngIf="isModal">
    <span class="icon ti-close m-close" (click)="closeModal()"></span>
    <br />
  </h2>

  <app-form-progress
    [steps]="steps"
    [currentStep]="currentStep"
    [hideLabels]="true"
    *ngIf="!showFullForm"
    size="small"
  >
  </app-form-progress>

  <div class="uk-grid" *ngIf="product?.category === 'wine'">
    <div class="uk-width-1-1" style="margin-bottom: 20px;">
      <img
        src="./assets/img/{{ 'product.wine.tutorial' | translate }}"
        alt="Esempio inserimento prodotto"
      />
    </div>
  </div>

  <app-step1
    #step1
    *ngIf="currentStep === 0 || showFullForm"
    [product]="product"
    [readonly]="readonly"
    [productDetailMode]="productDetailMode"
    [userType]="userType"
    [varieties]="varieties"
    [denominations]="denominationsToShow"
    [microzones]="microzonesToShow"
    [specifications]="specifications"
    [countries]="countries"
    [districts]="districts"
    (selectCountry)="onSelectCountry()"
    (selectDistrict)="onSelectDistrict()"
    (selectDenomination)="onSelectDenomination()"
    (productCategoryChange)="changeProductCategory($event)"
  ></app-step1>

  <app-step2
    #step2
    *ngIf="currentStep === 1 || showFullForm"
    [product]="product"
    [readonly]="readonly"
    [countries]="countries"
    [districts]="districts"
    (selectCountry)="onSelectCountry()"
    [productDetailMode]="productDetailMode"
  ></app-step2>

  <app-step3
    #step3
    *ngIf="currentStep === 2 || showFullForm"
    [product]="product"
    [readonly]="readonly"
    [productDetailMode]="productDetailMode"
  ></app-step3>

  <app-step4
    #step4
    *ngIf="currentStep === 3 || showFullForm"
    [product]="product"
    [readonly]="readonly"
    [productDetailMode]="productDetailMode"
    [countries]="countries"
  ></app-step4>

  <app-step5
    #step5
    *ngIf="currentStep === 4 || showFullForm"
    [product]="product"
    [readonly]="readonly"
    [productDetailMode]="productDetailMode"
  ></app-step5>

  <app-step6
    #step6
    *ngIf="currentStep === 5 || showFullForm"
    [product]="product"
    [readonly]="readonly"
    [varieties]="varieties"
    [productDetailMode]="productDetailMode"
  ></app-step6>

  <!--Nascondi in fase di dettaglio prodotto-->
  <app-step7
    #step7
    *ngIf="(currentStep === 6 || showFullForm) && !productDetailMode"
    [product]="product"
    [productDetailMode]="productDetailMode"
  ></app-step7>

  <div class="bottom-buttons" *ngIf="!productDetailMode">
    <div class="uk-grid uk-grid-small">
      <div class="uk-width-1-1">
        {{ 'general.obbligatorio' | translate }}
      </div>
    </div>
    <div class="uk-grid uk-grid-small">
      <div class="uk-width-1-2">
        <button
          class="m-button m-button--disabled"
          (click)="previousStep()"
          [disabled]="currentStep == 0 && !registration"
          [hidden]="isModal"
        >
          {{ 'general.indietro' | translate }}
        </button>
      </div>
      <div class="uk-width-1-2">
        <button class="m-button" (click)="nextStep()">{{ 'general.avanti' | translate }}</button>
      </div>
    </div>
  </div>
</div>
