import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { EnumService } from '../../../core/enum.service';
import { ModalService } from '../../../core/modal.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-step3',
  templateUrl: './step3.component.html',
  styleUrls: ['./step3.component.scss']
})
export class Step3Component implements OnInit, OnChanges {
  @Input() product: any = {};
  @Input() productDetailMode: boolean;
  @Input() readonly = false;
  @ViewChild('form') form;
  provenance: any[] = [];
  countries: any[] = [];
  agricultureTypes: any[] = [];
  aging: any[] = [];
  column = 'uk-width-1-1';
  booleanColumn = 'uk-width-medium-1-4';

  constructor(
    private enumService: EnumService,
    private modalService: ModalService,
    private translateService: TranslateService
  ) { }

  ngOnInit() {
    this.aging = this.enumService.getAgingTypes();
    this.agricultureTypes = this.enumService.getAgricultureTypes();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      this.product.category === 'wine' &&
      (!this.product.wineTechnical.aging || this.product.wineTechnical.aging === 0)
    ) {
      this.addAgingType();
    }
    if (this.productDetailMode) {
      this.column = 'uk-width-small-1-2';
      this.booleanColumn = 'uk-width-small-1-1';
    }
  }

  addAgingType() {
    if (!this.product.wineTechnical.aging) {
      this.product.wineTechnical.aging = [];
    }
    this.product.wineTechnical.aging.push({});
  }

  isBoolean(value) {
    return value === true || value === false;
  }

  removeAgingType(index) {
    this.product.wineTechnical.aging.splice(index, 1);
  }

  isValid() {
    return this.form.valid;
  }

  validate() {
    this.form.submitted = true;
    if (this.product.category === 'wine') {
      if (!this.product.wineTechnical.aging) {
        this.modalService.error(this.translateService.instant('general.form.error.wineAging'));
        return false;
      }
      if (
        this.product.wineTechnical.sugarResidue < 0 ||
        this.product.wineTechnical.alcoholPerc < 0 ||
        this.product.wineTechnical.acidity < 0
      ) {
        this.modalService.error(this.translateService.instant('general.form.error.lessThanZero'));
        return false;
      }

      const missingAging =
        this.aging.filter(a => a.code === this.product.wineTechnical.aging[0])?.length === 0;

      if (missingAging) {
        this.modalService.error(this.translateService.instant('general.form.error.wineAging'));
        return false;
      }
    }

    if (!this.isValid()) {
      this.modalService.showFormError();
      return false;
    }

    return true;
  }

  getData() {
    return this.product;
  }
}
