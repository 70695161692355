import {Directive, ElementRef, EventEmitter, HostListener, Input, Output} from '@angular/core';
import {FormControl, NG_VALIDATORS, ValidationErrors, Validator} from "@angular/forms";

@Directive({
    selector: '[inputInteger]',
    providers: [
        {provide: NG_VALIDATORS, useExisting: InputIntegerDirective, multi: true}
    ]
})
export class InputIntegerDirective implements Validator {

    @Input() ngModel: string;
    @Output() integerChanged = new EventEmitter();

    constructor(private _el: ElementRef) {
    }

    @HostListener('input', ['$event'])
    onInputChange(event) {
        const initialValue = this._el.nativeElement.value;
        ///^[0-9]+([,.][0-9]+)?$/
        // Ammetti solo numeri interi
        this._el.nativeElement.value = initialValue.replace(/[^0-9]*/g, '');
        if (initialValue !== this._el.nativeElement.value) {
            event.stopPropagation();
        }
    }

    @HostListener('blur', ['$event'])
    onBlur(event) {
        if (this._el.nativeElement.value) {
            this._el.nativeElement.value = this._el.nativeElement.value.replace(/[^0-9]*/g, '');
            this.integerChanged.emit(this._el.nativeElement.value)
        }
    }

    @HostListener('keyup', ['$event'])
    onKeyup(event) {
        if (this._el.nativeElement.value) {
            this._el.nativeElement.value = this._el.nativeElement.value.replace(/[^0-9]*/g, '');
            this.integerChanged.emit(this._el.nativeElement.value)
        }
    }

    // This method is the one required by the Validator interface
    validate(c: FormControl): ValidationErrors | null {
        // Here we call our static validator function
        return InputIntegerDirective.validateNumber(c);
    }

    static validateNumber(control: FormControl): ValidationErrors {
        if (control.errors && control.errors.required === true) {
            if (/^[0-9]+?$/.test(control.value) == false) {
                return {inputInteger: 'Numero non valido'};
            }
        }
        if (!!control.value) {
            if (/^[0-9]+?$/.test(control.value) == false) {
                return {inputInteger: 'Numero non valido'};
            }
        }
        // If no error, return null
        return null;
    }
}
