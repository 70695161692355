<div class="register uk-height-viewport">
  <app-lang-navbar></app-lang-navbar>

  <div class="m-modal">
    <div class="logo">
      <img style="background-size:cover" src="/assets/img/logo.png" />
    </div>

    <div>
      <div class="title">{{ "login.title" | translate }}</div>

      <form
        #formLogin="ngForm"
        [ngClass]="{ submitted: formLogin.submitted }"
        novalidate
      >
        <div class="uk-grid uk-grid-small">
          <div class="uk-width-medium-1-2">
            <label class="m-label"> {{ "email" | translate }}</label>
            <input
              type="email"
              name="username"
              [(ngModel)]="user.username"
              class="m-input"
              tabindex="1"
              required
            />
          </div>
          <div class="uk-width-medium-1-2">
            <div class="uk-grid uk-grid-small">
              <div class="uk-width-1-2">
                <label class="m-label">{{ "password" | translate }}</label>
              </div>
              <div class="uk-width-1-2 text-right">
                <a class="m-form-link" (click)="showPassword()" tabindex="3">
                  <i class="fa fa-eye"></i> {{ "show.password" | translate }}
                </a>
              </div>
            </div>
            <input
              [type]="passwordType"
              name="password"
              [(ngModel)]="user.password"
              class="m-input"
              tabindex="2"
              required
            />
          </div>
        </div>

        <div class="bottom-buttons">
          <div class="uk-grid uk-grid-small">
            <div class="uk-width-medium-1-2">
              <label>
                <input
                  type="checkbox"
                  name="remember"
                  [(ngModel)]="rememberMe"
                  tabindex="4"
                />
                {{ "rememberme" | translate }}
              </label>
              <div>
                <a routerLink="/recovery" tabindex="5">
                  {{ "forgot.password" | translate }}
                </a>
              </div>
            </div>
            <div class="uk-width-medium-1-2">
              <button class="m-button" (click)="login()">
                {{ "login" | translate }}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>

    <div class="bottom-section">
      {{ "register.text" | translate }}
      <strong><a routerLink="/register">{{ "register" | translate }}</a></strong>
    </div>
  </div>

  <app-copyright></app-copyright>
</div>
