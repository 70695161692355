import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormProgressComponent} from "./form-progress.component";

@NgModule({
    declarations: [FormProgressComponent],
    imports: [
        CommonModule
    ],
    exports: [
        FormProgressComponent
    ]
})
export class FormProgressModule {
}
